import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useLoginLogic from "./login.logic";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Container,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  InputAdornment,
  IconButton,
} from "@mui/material";
import logo from "../../assets/images/logo/mxweb-logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import LoadingButton from "@mui/lab/LoadingButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useUserInfoLogic from "../../shared/auth/user-info/user-info.logic";
import Loader from "../../shared/util/loader";
import { useEncryption } from "../../shared/util/useEncryption";
import getAhawidget from "../../ahaWidget";

const Login: React.FC = () => {
  const [rememberme, setRemember] = useState<boolean>(false);
  const navigate = useNavigate();
  let { error, login, empLogin, asureLogin } = useLoginLogic();
  const [open, setOpen] = React.useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [loadingLoader, setLoading] = useState(false);
  const [isEmpLoading, setEmpLoading] = useState(false);
  const [trackOrderErrorAlert, setTrackOrderErrorAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { userInfo } = useUserInfoLogic();
  const [userInfoData, setUserInfoData] = useState<any>();

  const { generateKeysAndEncrypt } = useEncryption();

  // const urlParams = new URLSearchParams(window.location.search);
  // const microsoftLoginCode = urlParams.get("code");
  const isInitialMount = useRef(true);
  const handleClose = () => {
    setOpen(false);
  };

  interface paramInterface {
    LogonName: string | null;
    Password: string | null;
  }

  const [param, setParam] = useState<paramInterface>({
    LogonName: null,
    Password: null,
  });

  const [formErrors, setFormErrors] = useState({
    LogonName: "",
    Password: "",
    loginAttempts: "",
  });
  // const [loginAttempts, setLoginAttempts] = useState(0);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormErrors({ ...formErrors, [e.target.name]: "" });
    setParam({ ...param, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (param.LogonName || param.Password) {
      setErrorAlert(false);
    }
  }, [param]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // });

  const validate = () => {
    let errors = {
      LogonName: "",
      Password: "",
      loginAttempts: "",
    };
    let isValid = true;

    if (!param.LogonName) {
      errors.LogonName = "Please enter in your User ID and/or Password";
      error = "Please enter in your User ID and/or Password";
      isValid = false;
    }
    if (!param.Password) {
      errors.Password = "Please enter in your User ID and/or Password";
      isValid = false;
    }
    setFormErrors(errors);
    return isValid;
  };

  const getUserInfo = async () => {
    try {
      const response = await userInfo();
      if (response.data && response.data.data) {
        const res = response.data.data;

        if (res.userGroupGUID) {
          localStorage.setItem("userGroupGUID", res.userGroupGUID);
        }
        if (typeof res.isAdministrator !== "undefined") {
          localStorage.setItem(
            "isAdministrator",
            JSON.stringify(res.isAdministrator)
          );
        }
        localStorage.setItem("officeID", res.officeID);
        localStorage.setItem("serviceAvailType", res.serviceAvailType);
        localStorage.setItem("maxSearchDays", res.maxSearchDays);
        localStorage.setItem(
          "operationsAlertEmail",
          res.operationsAlertEmail == null ? "" : res.operationsAlertEmail
        );
        localStorage.setItem("sendNewOrderEmail", res.sendNewOrderEmail);
        localStorage.setItem(
          "operationsAlertEmail",
          res.operationsAlertEmail == null ? "" : res.operationsAlertEmail
        );
        localStorage.setItem("sendNewOrderEmail", res.sendNewOrderEmail);

        localStorage.setItem("accountNumber", res.accountNumber);
        let roles = [];
        localStorage.setItem("userEmail", res.emailAddress);
        localStorage.setItem(
          "UseOrderNumAsTrackNum",
          res.shpUseOrderNumAsTrackNum
        );
        if (res.roles && Array.isArray(res.roles)) {
          roles = res.roles.map((role: { roleKey: string }) => role.roleKey);
          roles = res.roles.map((role: { roleKey: string }) => role.roleKey);
        }
        if (res.isAdministrator) {
          roles.push("admin");
        }
        localStorage.setItem("roles", JSON.stringify(roles));
        localStorage.setItem("isUPSPremierAgent", res.isUPSPremierAgent);
        setUserInfoData(res);
        if (roles.length > 0) {
          navigate("/active-orders");
        } else {
          navigate("/unAuthorized");
        }
      }
    } catch {
      setUserInfoData(null);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      setErrorAlert(false);
      e.preventDefault();
      if (!validate()) {
        setErrorAlert(true);
        return;
      }
      setLoading(true);
      setFormErrors({ ...formErrors, LogonName: "", Password: "" });
      const encryptedPassword: any = await generateKeysAndEncrypt(
        param.Password ? param.Password : ""
      );
      const _param = {
        LogonName: param.LogonName ? param.LogonName : "",
        //encryptedPassword: encryptedPassword,
        Password: encryptedPassword,
      };
      const response = await login(_param);
      setLoading(false);

      if (response) {
        //const dscPAss = await generateKeysAndDecrypt(encryptedPassword as any);
        getUserInfo();
        //navigate("/active-orders");
      } else {
        // setLoginAttempts((prevAttempts) => prevAttempts + 1);
        setFormErrors({
          ...formErrors,
        });
        setErrorAlert(true);
        // setTimeout(() => {
        //   setErrorAlert(false);
        // }, 5000);
      }
    } catch (err: any) {
      setErrorAlert(true);
      // setTimeout(() => {
      //   setErrorAlert(false);
      // }, 5000);
    }
  };

  const userRole = JSON.parse(localStorage.getItem("roles") || "[]");

  useEffect(() => {
    getAhawidget();
    if (userInfoData) {
      if (userRole.length > 0) {
        if (userInfoData.termsAgreeDateTime) {
          navigate("/active-orders");
        } else {
          navigate("/terms-and-conditions");
        }
      } else {
        navigate("/unAuthorized");
      }
    }
  }, [userInfoData]);

  const [track, setTrack] = useState({
    TrackOROrde: "",
  });

  const [trackFormErrors, setTrackFormErrors] = useState({
    TrackOROrde: "",
  });
  // const [loginAttempts, setLoginAttempts] = useState(0);

  useEffect(() => {
    if (track.TrackOROrde) {
      setTrackOrderErrorAlert(false);
    }
  }, [track]);

  const handleChangeTrack = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTrackFormErrors({ ...trackFormErrors, [e.target.name]: "" });
    setTrack({ ...track, [e.target.name]: e.target.value });
  };

  const validateTrack = () => {
    let errors = {
      TrackOROrde: "",
      Password: "",
      loginAttempts: "",
    };
    let isValid = true;

    if (!track.TrackOROrde) {
      errors.TrackOROrde = "Airbill # or Order # not found.";
      isValid = false;
    }

    setTrackFormErrors(errors);
    return isValid;
  };

  const handleSubmitTrack = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateTrack()) {
      setTrackOrderErrorAlert(true);
      return;
    } else {
      setTrackOrderErrorAlert(false);
      navigate(`/public/track-order?TrackingNumber=${track.TrackOROrde}`);
    }

    // const response = await login(param);

    // if (response) {
    //   navigate("/active-orders");
    // } else {
    // setLoginAttempts((prevAttempts) => prevAttempts + 1);
    //   setFormErrors({
    //     ...formErrors,
    //   });
    //   setErrorAlert(true);
    // setTimeout(() => {
    //   setTrackOrderErrorAlert(false);
    // }, 5000);
    // }
  };

  const handleMoveToForgetPassword = () => {
    navigate("/forgot-password");
  };

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleWMSLoginClick = () => {
    window.location.href = "https://wms.mnx.com/";
  };

  const handlePrivacyStatementClick = () => {
    navigate("/privacy-statement");
  };

  const handleSupportSignIn = () => {
    navigate("/support-sign-in");
  };

  const handleEmpLogin = async () => {
    try {
      setErrorAlert(false);
      setEmpLoading(true);
      const response = await empLogin();

      if (response?.data?.success) {
        setEmpLoading(false);
        window.location.href = response?.data?.data;
      }
    } catch (error: any) {
      setEmpLoading(false);
    }
  };

  const handleAsureLogin = async (microsoftLoginCode: any) => {
    try {
      setEmpLoading(true);
      const param = {
        Code: microsoftLoginCode,
      };
      const response = await asureLogin(param);

      if (response) {
        setEmpLoading(false);
        getUserInfo();
        getAhawidget();
      } else {
        setErrorAlert(true);
        setEmpLoading(false);
      }
    } catch (error: any) {}
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      const urlParams = new URLSearchParams(window.location.search);
      const microsoftLoginCode = urlParams.get("code");
      if (microsoftLoginCode) {
        handleAsureLogin(microsoftLoginCode);
      }
    }
  }, []);

  return (
    <div>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Container className="login-section" maxWidth="sm" sx={{ mt: 4 }}>
          {isEmpLoading && (
            <>
              <Box className="global-loader-container">
                <Loader />
              </Box>
            </>
          )}
          {/* Logo */}
          <Box className="lognin-image">
            <img src={logo} alt="Company Logo" style={{ maxWidth: "100%" }} />
          </Box>
          <Box my={1}>
            {/* Sign in title */}
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              textAlign="start"
              className="sign-in-title"
            >
              Sign in
            </Typography>
            <div className="form-border">
              {errorAlert && (
                <Alert variant="filled" severity="error" id="txtLogNameError">
                  {formErrors.LogonName
                    ? formErrors.LogonName
                    : formErrors.Password
                    ? formErrors.Password
                    : error}
                </Alert>
              )}

              <form onSubmit={handleSubmit}>
                {/* User ID input */}
                <TextField
                  id="txtLogonName"
                  name="LogonName"
                  label="User ID"
                  variant="outlined"
                  className="Txtbx"
                  fullWidth
                  margin="normal"
                  value={param.LogonName}
                  onChange={handleChange}
                  error={!!formErrors.LogonName}
                  // helperText={formErrors.LogonName}
                  inputProps={{ maxLength: 50 }}
                />
                {/* Password input */}
                <TextField
                  id="txtPassword"
                  name="Password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={param.Password}
                  onChange={handleChange}
                  className="mt-10 Txtbx"
                  error={!!formErrors.Password}
                  // helperText={formErrors.Password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          id="btnpasswordtoggle"
                          aria-label="toggle password visibility"
                          onClick={toggleShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box
                  className="form_info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={2}
                >
                  {/* Remember me checkbox */}
                  <FormControlLabel
                    className="loginchkbx_lbl"
                    control={
                      <Checkbox
                        className="loginchkbx"
                        checked={rememberme}
                        onChange={(e) => setRemember(e.target.checked)}
                        name="rememberMe"
                        id="cbRememberMe"
                        color="primary"
                      />
                    }
                    label="Remember Me"
                    sx={{ marginRight: "auto" }}
                  />
                  {/* Forgot password button */}
                  <Button
                    variant="text"
                    className="Forgot_txt"
                    id="btnForgotTxt"
                    onClick={handleMoveToForgetPassword}
                  >
                    {" "}
                    Forgot your password?{" "}
                  </Button>

                  {/* Forgot Password Dialog */}
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      component: "form",
                      onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        // const formData = new FormData(event.currentTarget);
                        // const formJson = Object.fromEntries(
                        //   (formData as any).entries()
                        // );
                        //const email = formJson.email;
                        handleClose();
                      },
                    }}
                  >
                    <DialogTitle>Forgot Password</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Enter your email address below and we will send you
                        instructions to reset your password.
                      </DialogContentText>
                      <TextField
                        id="txtEmail"
                        name="email"
                        label="Email Address"
                        variant="outlined"
                        className="Txtbx"
                        fullWidth
                        margin="normal"
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} id="btnCancel">
                        Cancel
                      </Button>
                      <Button type="submit" id="btnSubmit">
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
                {/* Sign in button */}
                <LoadingButton
                  className="signin_btn"
                  type="submit"
                  variant="contained"
                  color="primary"
                  id="btnSignIn"
                  fullWidth
                  loading={loadingLoader}
                >
                  SIGN IN
                </LoadingButton>
              </form>
              {/* Divider */}

              {/* Sign up section */}
              <Box
                className="signup_detail"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ color: "black" }}
                  className="info_lbl"
                >
                  Don’t have an account?
                </Typography>
                <Button
                  onClick={() => navigate("/sign-up")}
                  variant="text"
                  className="info_txt"
                  id="btnSignUp"
                >
                  {" "}
                  Sign up here
                </Button>
              </Box>
            </div>
          </Box>
          {/* Other login options */}
          <Box my={1}>
            <Box className="container_login">
              <Button
                className="text"
                onClick={handleWMSLoginClick}
                id="btnWmsLogin"
              >
                wms login
              </Button>
              <Button
                className="text-custom"
                onClick={handleEmpLogin}
                id="btnEmployeeSignIn"
              >
                Employee Sign in
              </Button>
            </Box>
          </Box>
          {/* Track & Trace section */}

          <Box className="Trace-section" my={2}>
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              textAlign="start"
              className="sign-in-title"
            >
              Track & Trace
            </Typography>
            <div className="form-border">
              {trackOrderErrorAlert && (
                <Alert
                  variant="filled"
                  severity="error"
                  className="mb-2"
                  id="txtLogTrackError"
                >
                  {trackFormErrors.TrackOROrde}
                </Alert>
              )}
              <form onSubmit={handleSubmitTrack}>
                {/* Airbill number input */}

                <TextField
                  id="txtTrackOROrde"
                  name="TrackOROrde"
                  label="Airbill #/Order #"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  className="Txtbx mt-0"
                  value={track.TrackOROrde}
                  onChange={handleChangeTrack}
                  error={!!trackFormErrors.TrackOROrde}
                  // helperText={trackFormErrors.TrackOROrde}
                />
                {/* Track button */}
                <Button
                  className="pt-7 Track_btn mt-8"
                  id="btnTrack"
                  type="submit"
                  variant="outlined"
                  color="primary"
                  fullWidth
                >
                  Track
                </Button>
              </form>
            </div>
          </Box>
          {/* Other links */}
          <Box>
            <Box
              className="Privacy_info privacy-footer"
              maxWidth="sm"
              sx={{ display: "flex", gap: "64px", width: "100%" }}
            >
              <Button
                className="text"
                onClick={handlePrivacyStatementClick}
                id="btnPrivacyStatement"
              >
                Privacy Statement
              </Button>
              <Button
                className="text-custom"
                onClick={handleSupportSignIn}
                id="btnSupportLogin"
              >
                Support login
              </Button>
            </Box>
          </Box>
          {/* <p> This is Person Component:- {getCall()}</p> */}
        </Container>
      </Box>
    </div>
  );
};

export default Login;
