import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import cancelicon from "../../assets/images/logo/cancel-icon.svg";
import { useNavigate } from "react-router-dom";
import Tutorialicon from "../../assets/images/logo/Tutorial_icon.svg";

import getAhawidget from "../../ahaWidget";

type Anchor = "right";

interface Props {
  topOffset?: number;
  isOpen: boolean;
  onClose: () => void;
  closeSidebar: boolean;
}

export default function AnchorTemporaryDrawer(props: Props) {
  const { closeSidebar } = props;
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const navigate = useNavigate();

  const toPascalCase = (str: any) => {
    return str
      .replace(
        /\w\S*/g,
        (word: any) =>
          word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
      )
      .replace(/\s+/g, "");
  };

  const [toggle, setToggle] = React.useState(false);
  const drawerRef = React.useRef<HTMLDivElement>(null);

  const userRole = JSON.parse(localStorage.getItem("roles") || "[]");
  const isStandardUser = userRole.includes("standard");
  const iscityUser = userRole.includes("citi");
  const isAdminUser = userRole.includes("admin");
  const isRateUser = userRole.includes("rate");
  const isLimitedUser = userRole.includes("limited");

  const shouldDisplayAdminContent = isAdminUser || userRole.length > 0;
  const handleLogout = () => {
    localStorage.clear();
    getAhawidget();
    navigate("/");
  };

  React.useEffect(() => {
    setToggle(false);
    setState({ ...state, right: false });
  }, [closeSidebar]);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target as Node)
      ) {
        setState({ ...state, right: false });
        setToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [state]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setToggle(open);
      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      className="sidebar"
      role="presentation"
      ref={drawerRef}
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {shouldDisplayAdminContent && (
        <List>
          {shouldDisplayAdminContent && <ListSubheader>ORDERS</ListSubheader>}
          {[
            ...(userRole.length > 0
              ? [{ text: "New Order", url: "/longform-order" }]
              : []),
            ...(userRole.length > 0 && !isLimitedUser
              ? [{ text: "Multiple Orders", url: "/multiple-order" }]
              : []),
            ...(userRole.length > 0
              ? [{ text: "Edit Orders", url: "/edit-active-order" }]
              : []),
            ...(userRole.length > 0
              ? [{ text: "Order Import", url: "/import-order" }]
              : []),
            ...(userRole.length > 0
              ? [{ text: "Healthcare Form", url: "/healthcare-form" }]
              : []),
            ...(isAdminUser || isRateUser || !isStandardUser
              ? [{ text: "Rate Estimate", url: "/rate-estimate" }]
              : []),
            ...(!isLimitedUser && (isAdminUser || isRateUser || !isStandardUser)
              ? [{ text: "Invoices", url: "/invoices" }]
              : []),
          ].map((item, index) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => navigate(item.url)}
                id={`btn${toPascalCase(item.text)}`}
              >
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}

      {shouldDisplayAdminContent && (
        <List>
          {shouldDisplayAdminContent && (
            <ListSubheader>TRACK ORDERS</ListSubheader>
          )}
          {[
            ...(shouldDisplayAdminContent
              ? [{ text: "Active Orders", url: "/active-orders" }]
              : []),
            ...(shouldDisplayAdminContent
              ? [{ text: "Will Call Orders", url: "/will-call-orders" }]
              : []),
            ...(shouldDisplayAdminContent
              ? [{ text: "Recent Deliveries", url: "/recent-deliveries" }]
              : []),
            ...(shouldDisplayAdminContent
              ? [{ text: "Track Order", url: "/track-order" }]
              : []),
          ].map((item, index) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => navigate(item.url)}
                id={`btn${toPascalCase(item.text)}`}
              >
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}

      {shouldDisplayAdminContent && !isLimitedUser && (
        <List>
          {shouldDisplayAdminContent && <ListSubheader>PRINT</ListSubheader>}
          {[
            ...(shouldDisplayAdminContent
              ? [{ text: "Batch Print - Ship Label", url: "/batch-prints" }]
              : []),
            ...(shouldDisplayAdminContent
              ? [
                  {
                    text: "Batch Print - Dangerous Goods",
                    url: "/batch-prints-dgd",
                  },
                ]
              : []),
          ].map((item, index) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => navigate(item.url)}
                id={`btn${toPascalCase(item.text)}`}
              >
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}

      {shouldDisplayAdminContent && (
        <List>
          {shouldDisplayAdminContent && <ListSubheader>ADDRESS</ListSubheader>}
          {[
            ...(isAdminUser || userRole.length > 0
              ? [{ text: "Manage Address Book", url: "/address-book" }]
              : []),
          ].map((item, index) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => navigate(item.url)}
                id={`btn${toPascalCase(item.text)}`}
              >
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}

      {!isLimitedUser && (
        <List>
          {shouldDisplayAdminContent && <ListSubheader>REPORT</ListSubheader>}
          {[
            ...(shouldDisplayAdminContent && !isLimitedUser
              ? [{ text: "Search Orders", url: "/search-orders" }]
              : []),
            ...(shouldDisplayAdminContent && !isLimitedUser
              ? [{ text: "Export Data", url: "/export-data" }]
              : []),
            ...(shouldDisplayAdminContent && !isLimitedUser
              ? [{ text: "On Time Performance", url: "ontime-performance" }]
              : []),
            // Conditionally include "Shipment Verification Report"
            ...(!iscityUser
              ? []
              : [
                  {
                    text: "Verification at Pickup",
                    url: "shipment-verification-report",
                  },
                ]),
          ].map((item, index) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => navigate(item.url)}
                id={`btn${toPascalCase(item.text)}`}
              >
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}

      {shouldDisplayAdminContent && (
        <List>
          {shouldDisplayAdminContent && <ListSubheader>HELP</ListSubheader>}
          {[
            ...(shouldDisplayAdminContent && !isLimitedUser
              ? [{ text: "Tutorials", url: "https://www.mnx.com/mxweb/" }]
              : []),
            ...(shouldDisplayAdminContent
              ? [{ text: "Privacy Statement", url: "/privacy-statement" }]
              : []),
          ].map((item, index) => (
            <ListItem key={item.text} disablePadding>
              {/* Check if the link is "Tutorials" */}
              {item.text === "Tutorials" ? (
                <a
                  className="d-flex align-center"
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItemText primary={item.text} />
                  <ListItemIcon>
                    <img
                      className="ml-8"
                      width={16}
                      height={16}
                      src={Tutorialicon}
                      alt="Tutorial"
                    />
                  </ListItemIcon>
                </a>
              ) : (
                <ListItemButton
                  onClick={() => navigate(item.url)}
                  id={`btn${toPascalCase(item.text)}`}
                >
                  <ListItemText primary={item.text} />
                </ListItemButton>
              )}
            </ListItem>
          ))}
        </List>
      )}

      {shouldDisplayAdminContent && (
        <List>
          {shouldDisplayAdminContent && <ListSubheader>SETUP</ListSubheader>}
          {[
            ...(shouldDisplayAdminContent
              ? [{ text: "My Settings", url: "/my-settings" }]
              : []),
            ...(shouldDisplayAdminContent && !isLimitedUser
              ? [{ text: "Reference Group", url: "/reference-group" }]
              : []),
          ].map((item, index) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => navigate(item.url)}
                id={`btn${toPascalCase(item.text)}`}
              >
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}

      <List>
        <ListSubheader>ACCOUNT</ListSubheader>
        {[
          { text: "Sign Out", url: "/" }, // Redirect to the home page after logout
        ].map((item, index) => (
          <ListItem key={item.text} disablePadding onClick={handleLogout}>
            <ListItemButton id="btnSignOut">
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <div className="Top-nav-btn">
        {!toggle ? (
          <IconButton
            onClick={toggleDrawer("right", true)}
            size="large"
            aria-label="Menu Icon"
            color="inherit"
            id="btnSideBarMenu"
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={toggleDrawer("right", false)}
            size="large"
            aria-label="Menu Icon"
            color="inherit"
            id="ibSideBarCancel"
          >
            <img src={cancelicon} alt="Company Logo" />
          </IconButton>
        )}
      </div>
      <div className="Side-drawer">
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </div>
    </div>
  );
}
