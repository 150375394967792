import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import React, { useEffect, useState } from "react";
import CodeDialog from "../code-dialog/code-dialog";
import useAddressLogic from "../../lookup/address/address.logic";
import { AddressItemDto } from "../../../models/address-item-dto";
import { CountryItemDto } from "../../../models/country-item-dto";
import useCountryLogic from "../../lookup/country/country.logic";
import { ZipCodeItemDto } from "../../../models/zip-code-item-dto";
import useZipCodeLogic from "../../lookup/zip-code/zip-code.logic";
import { useForm } from "react-hook-form";

import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { red } from "@mui/material/colors";
import { handleNumericValue } from "../../util/numeric-value";
import { EditWillCallRequestDTO } from "../../../models/edit-will-call-order-request-item-dto";

interface PickupAddressProps {
  onPickUpDataChange: (updatedData: any) => void;
  formErrors: any;
  onZipCodeDataChange: (errors: any) => void;
  editWillCallOrderData: EditWillCallRequestDTO;
  handleInputChange: (updatedData: any) => void;
  setFormErrors: (errors: any) => void;
  onRequestTimeChange: any;
  pickupZipCodeError: any;
}

const EditPickupAddress: React.FC<PickupAddressProps> = ({
  onPickUpDataChange,
  formErrors,
  onZipCodeDataChange,
  setFormErrors,
  handleInputChange,
  editWillCallOrderData,
  onRequestTimeChange,
  pickupZipCodeError,
}) => {
  const [open, setOpen] = React.useState(false);
  const { address } = useAddressLogic();
  const { country } = useCountryLogic();
  const { zipCode } = useZipCodeLogic();
  const [addressData, setAddressData] = useState<AddressItemDto[]>([]);
  const [isShowAddress3, setIsShowAddress3] = useState<boolean>(false);
  const [countryData, setCountryData] = useState<CountryItemDto[]>([]);
  const [zipCodeData, setZipCodeData] = useState<ZipCodeItemDto[]>([]);

  // const [pickupCity, setPickupCity] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const [selectedAddress, setSelectedAddress] = useState<AddressItemDto | null>(
    null
  );
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedZipCode, setSelectedZipCode] = useState<string>("");
  const { register } = useForm();

  const userGroupGUID: any = localStorage.getItem("userGroupGUID");
  const errorControls = [
    {
      name: "pickupCompanyName",
      value: "puCompanyName",
    },
    {
      name: "pickupCityName",
      value: "puCityName",
    },
    {
      name: "pickupPostalCode",
      value: "puPostalCode",
    },
    {
      name: "pickupAirportID",
      value: "puAirportID",
    },
  ];
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getAddress();
    getCountry();
  }, []);

  useEffect(() => {
    getZipCode();
    getDefaultZipCode();
  }, []);

  // useEffect(() => {
  //   if (DefaultData) {
  //     setSelectedCountry(DefaultData.pickupCountryID);
  //     setSelectedZipCode(DefaultData.pickupPostalCode);
  //     setPickupTimezoneID(DefaultData?.pickupTimezoneID ?? DefaultData?.timezoneID);
  //     setPickupCity(DefaultData?.pickupCityName || "");
  //     // onPickUpDataChange(DefaultData);
  //     setFormData({
  //       pickupAddress1: DefaultData.pickupAddress1 || "",
  //       pickupAddress2: DefaultData.pickupAddress2 || "",
  //       pickupAddress3: DefaultData.pickupAddress3 || "",
  //       pickupCityName: DefaultData.pickupCityName || "",
  //       pickupCountryID: DefaultData.pickupCountryID || "",
  //       pickupStateProvID: DefaultData.pickupStateProvID || "",
  //       pickupCompanyName: DefaultData.pickupCompanyName || "",
  //       pickupInstructions: DefaultData.pickupInstructions || "",
  //       pickupAirportID: DefaultData.pickupAirportID || "",
  //       pickupPhoneNumber: DefaultData.pickupPhoneNumber || "",
  //       pickupPersonToSee: DefaultData?.pickupPersonToSee || "",
  //       puKeep: false,
  //       pickupPostalCode: DefaultData?.pickupPostalCode || "",
  //       pickupEmailAddress: DefaultData?.pickupEmailAddress,
  //       addressCode: "",
  //       pickupTimezoneID: pickupTimezoneID,
  //       puAddressGUID:""
  //     });
  //   }
  // }, [DefaultData]);

  // useEffect(() => {
  //   onPickUpDataChange({
  //     puTimezoneID: pickupTimezoneID,
  //   });
  // }, [pickupTimezoneID]);

  useEffect(() => {
    if (selectedAddress) {
      setSelectedCountry(selectedAddress.countryID);
      setSelectedZipCode(selectedAddress.postalCode);

      onPickUpDataChange({
        puAddress1: selectedAddress.address1 || "",
        puAddress2: selectedAddress.address2 || "",
        puAddress3: selectedAddress.address3 || "",
        puCityName: selectedAddress.cityName || "",
        puPostalCode: selectedAddress.postalCode || "",
        puCountryID: selectedAddress.countryID || "",
        puStateProvID: selectedAddress.stateProvID || "",
        puCompanyName: selectedAddress.companyName || "",
        puInstructions: selectedAddress.pickupInstructions || "",
        puAirportID: selectedAddress.airportID || "",
        puEmailAddress: selectedAddress.emailAddress || "",
        puAddressCode: selectedAddress.addressCode || "",
        puPhoneNumber: selectedAddress.phoneNumber || "",
        puTimezoneID: selectedAddress.timezoneID || "",
        puAddressGUID: selectedAddress.addressGUID,
      });
      getZipCode(selectedAddress.countryID, selectedAddress.postalCode);
    }
  }, [selectedAddress]);

  const handleAddressChange = (e: any) => {
    const selectedAddressValue = e.target.value;
    if (selectedAddressValue.postalCode) {
      let errorChange: any = errorControls.find(
        (ec) => ec.name === "pickupPostalCode"
      );
      if (errorChange) {
        handleError("pickupPostalCode");
      }
    }
    setSelectedAddress(selectedAddressValue);
    setSelectedCountry(selectedAddressValue.countryID);
    setSelectedZipCode(selectedAddressValue.postalCode);
    onRequestTimeChange(selectedAddressValue);
    setZipCodeData([]);
  };

  // interface MenuStyles {
  //   maxHeight: string;
  // }

  // const [menuStyles, setMenuStyles] = useState<MenuStyles>({
  //   maxHeight: "230px", // Default max height for desktop
  // });

  const menuStyles = {
    maxHeight: "230px", // Default max height for desktop
  };
  const handleError = (name: string) => {
    let updatedName = name;
    let errorChange: any = errorControls.find((ec) => ec.name === name);
    if (errorChange.name === updatedName) {
      updatedName = errorChange.value;
      if (formErrors[updatedName]) {
        setFormErrors((prevErrors: any) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[updatedName];
          return updatedErrors;
        });
      }
    }
  };

  const getAddress = async () => {
    try {
      setIsLoading(true);
      const param = {
        addressCode: (editWillCallOrderData.puAddressCode as any) || "",
        companyName: "",
        cityName: "",
        stateProvID: "",
        countryID: "",
        postalCode: "",
        userGroupGUID: userGroupGUID,
      };
      const response: any = await address(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        if (!param.addressCode) {
          setAddressData(res);
        }

        if (param.addressCode && res.length > 0) {
          onSelectAddress(res[0]);
        }
      }
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      setAddressData([]);
    }
  };

  const getCountry = async () => {
    try {
      setIsLoading(true);
      const param = {};
      const response = await country(param);
      if (response.data && response.data.data) {
        setCountryData(response.data.data);
      }
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      setCountryData([]);
    }
  };

  const getZipCode = async (addressCountry = "", addressZipCode = "") => {
    try {
      const param: any = {
        postalCode: addressZipCode
          ? addressZipCode
          : editWillCallOrderData.puPostalCode,
        countryID: addressCountry
          ? addressCountry
          : editWillCallOrderData.puCountryID,
      };
      const response = await zipCode(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        onZipCodeDataChange(res);
        const timezone = res.find(
          (x: any) => x.cityName === editWillCallOrderData?.puCityName
        );
        handleInputChange({
          target: { name: "puStandardAbbr", value: timezone?.standardAbbr },
        } as any);
        if (res.length > 0) {
          setZipCodeData(res);
          return res;
        } else {
          setZipCodeData([]);
          return [];
        }
      }
    } catch (err) {
      setZipCodeData([]);
    }
  };

  const handleZipCodeKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Tab") {
      const res = await getZipCode();

      // Wait for getZipCode to complete
      if (res.length > 0) {
        handleInputChange({
          target: { name: "puCityName", value: res[0].cityName },
        } as any);
        handleInputChange({
          target: { name: "puAirportID", value: res[0].airportID },
        } as any);
        handleInputChange({
          target: { name: "puStateProvID", value: res[0].stateProvID },
        } as any);
        handleInputChange({
          target: { name: "puCountryID", value: res[0].countryID },
        } as any);
        handleInputChange({
          target: { name: "puPersonToSee", value: res[0].stateProvID },
        } as any);
        handleInputChange({
          target: { name: "putimezoneID", value: res[0].timezoneID },
        } as any);
        handleInputChange({
          target: { name: "puStandardAbbr", value: res[0].standardAbbr },
        } as any);
      } else {
        handleInputChange({
          target: { name: "puCityName", value: "" },
        } as any);
        handleInputChange({
          target: { name: "puAirportID", value: "" },
        } as any);
        handleInputChange({
          target: { name: "puStateProvID", value: "" },
        } as any);
        handleInputChange({
          target: { name: "puCountryID", value: "" },
        } as any);
        handleInputChange({
          target: { name: "puPersonToSee", value: "" },
        } as any);
        handleInputChange({
          target: { name: "putimezoneID", value: "" },
        } as any);
        handleInputChange({
          target: { name: "puStandardAbbr", value: "" },
        } as any);
      }
    }
  };

  const handleZipCodeFocusOut = async (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const res = await getZipCode(); // Wait for getZipCode to complete
    if (res) {
      if (res.length > 0) {
        onZipCodeDataChange(res);
        handleInputChange({
          target: { name: "puCityName", value: res[0].cityName },
        } as any);
        handleInputChange({
          target: { name: "puAirportID", value: res[0].airportID },
        } as any);
        handleInputChange({
          target: { name: "puStateProvID", value: res[0].stateProvID },
        } as any);
        handleInputChange({
          target: { name: "puCountryID", value: res[0].countryID },
        } as any);
        handleInputChange({
          target: { name: "puPersonToSee", value: res[0].stateProvID },
        } as any);
        handleInputChange({
          target: { name: "putimezoneID", value: res[0].timezoneID },
        } as any);
        handleInputChange({
          target: { name: "puStandardAbbr", value: res[0].standardAbbr },
        } as any);
      } else {
        handleInputChange({
          target: { name: "puCityName", value: "" },
        } as any);
        handleInputChange({
          target: { name: "puAirportID", value: "" },
        } as any);
        handleInputChange({
          target: { name: "puStateProvID", value: "" },
        } as any);
        handleInputChange({
          target: { name: "puCountryID", value: "" },
        } as any);
        handleInputChange({
          target: { name: "puPersonToSee", value: "" },
        } as any);
        handleInputChange({
          target: { name: "putimezoneID", value: "" },
        } as any);
        handleInputChange({
          target: { name: "puStandardAbbr", value: "" },
        } as any);
      }
    }
  };

  const onSelectAddress = (address: any) => {
    setSelectedAddress(address);
    setSelectedCountry(address.countryID);
    setSelectedZipCode(address.postalCode);
    onRequestTimeChange(address);
    if (address.companyName) {
      let errorChange: any = errorControls.find(
        (ec) => ec.name === "pickupCompanyName"
      );
      if (errorChange) {
        handleError("pickupCompanyName");
      }
    }
    if (address.postalCode) {
      let errorChange: any = errorControls.find(
        (ec) => ec.name === "pickupPostalCode"
      );
      if (errorChange) {
        handleError("pickupPostalCode");
      }
    }
    setZipCodeData([]);
  };

  const getDefaultZipCode = async () => {
    try {
      if (selectedZipCode) {
        const param = {
          postalCode: selectedZipCode,
          countryID: selectedCountry,
        };
        const response = await zipCode(param);
        if (response.data && response.data.data) {
          const res = response.data.data;
          onZipCodeDataChange(res);
          if (res.length > 0) {
            setZipCodeData(res);
          }
        }
      }
    } catch {
      setZipCodeData([]);
    }
  };

  const handleAddressCodeKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" || event.key === "Tab") {
      const { puAddressCode } = editWillCallOrderData;
      if (puAddressCode && puAddressCode?.trim().length > 0) {
        await getAddress(); // Assuming getAddress is an async function
      }
    }
  };

  return (
    <>
      <Box className="d-flex flex-column w-100 border rounded detail-info-section">
        <Typography variant="h6" className="Sub-header">
          Pickup Details
        </Typography>
        <Box className="d-flex flex-column gap-12">
          <FormControl size="medium">
            <InputLabel className="input-label" id="demo-select-small-label">
              Address Book
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="txtPickupAddressCode"
              // value={selectAccount}
              {...register("puAddressGUID")}
              label="Address Book"
              name="puAddressGUID"
              fullWidth
              onChange={handleAddressChange}
              sx={{ width: isMobile ? "100%" : undefined }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },

                PaperProps: {
                  style: menuStyles,
                },
              }}
            >
              {addressData.length > 0 &&
                addressData.map((address: any) => (
                  <MenuItem key={address.addressGUID} value={address}>
                    {address.companyName +
                      (address.address1 != "" ? ", " : "") +
                      address.address1 +
                      (address.cityName != "" ? ", " : "") +
                      address.cityName +
                      (address.cityName != "" ? ", " : "") +
                      address.countryID}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Box className="d-flex gap-2">
            <TextField
              variant="outlined"
              label="Code"
              id="txtAddressCode"
              className="w-100"
              {...register("puAddressCode")}
              name="puAddressCode"
              inputProps={{ maxLength: 52 }}
              onChange={handleInputChange}
              value={editWillCallOrderData.puAddressCode}
              InputLabelProps={{
                shrink: !!editWillCallOrderData.puAddressCode,
              }}
              onKeyDown={handleAddressCodeKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickOpen}
                      id="btnPickupCodeDialog"
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <CodeDialog
              open={open}
              handleClose={handleClose}
              addressType="pickupAddress"
              onSelectAddress={onSelectAddress}
            />

            <TextField
              id="txtPickupCompanyName"
              variant="outlined"
              label="Company Name"
              {...register("puCompanyName")}
              name="puCompanyName"
              onChange={handleInputChange}
              className="w-100"
              value={editWillCallOrderData.puCompanyName}
              error={!!formErrors.puCompanyName}
              InputLabelProps={{
                shrink: !!editWillCallOrderData.puCompanyName,
              }}
              inputProps={{ maxLength: 50 }}
              InputProps={{
                endAdornment: !!formErrors.puCompanyName && (
                  <InputAdornment position="end" disablePointerEvents={true}>
                    <InfoRoundedIcon
                      style={{ color: red[500] }}
                      fontSize="small"
                    ></InfoRoundedIcon>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <TextField
            variant="outlined"
            label="Address Line 1"
            id="txtPickupAddress1"
            {...register("puAddress1")}
            value={editWillCallOrderData.puAddress1}
            InputLabelProps={{ shrink: !!editWillCallOrderData.puAddress1 }}
            name="puAddress1"
            onChange={handleInputChange}
            inputProps={{ maxLength: 50 }}
          />
          <Box className="d-flex align-center gap-8">
            <TextField
              className={isShowAddress3 ? "w-100" : "width-90"}
              variant="outlined"
              id="txtPickupAddress2"
              label="Address Line 2  (Optional)"
              {...register("puAddress2")}
              name="puAddress2"
              InputLabelProps={{ shrink: !!editWillCallOrderData.puAddress2 }}
              value={editWillCallOrderData.puAddress2}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
            />
            {!isShowAddress3 && (
              <IconButton id="btnLookupAdd">
                <AddIcon
                  className="Add-icon"
                  onClick={() => setIsShowAddress3(true)}
                />
              </IconButton>
            )}
          </Box>
          {isShowAddress3 && (
            <TextField
              variant="outlined"
              label="Address Line 3 (Optional)"
              name="puAddress3"
              id="txtPickupAddress3"
              InputLabelProps={{ shrink: !!editWillCallOrderData.puAddress3 }}
              value={editWillCallOrderData.puAddress3}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
            />
          )}
          <Box className="d-flex gap-2">
            <FormControl size="medium" className="w-100">
              <InputLabel id="demo-select-small-label">City</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="ddPickupCityName"
                defaultValue={editWillCallOrderData.puCityName}
                value={editWillCallOrderData.puCityName} // Ensure this matches your state variable
                label="City"
                {...register("puCityName")}
                name="puCityName"
                fullWidth
                onChange={handleInputChange}
                sx={{ width: isMobile ? "100%" : undefined }}
                error={!!formErrors.puCityName}
              >
                {zipCodeData.length > 0 &&
                  zipCodeData.map((city: any) => (
                    <MenuItem key={city.cityName} value={city.cityName}>
                      {city.cityName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <TextField
              variant="outlined"
              label="State"
              {...register("puStateProvID")}
              name="puStateProvID"
              id="txtPickupState"
              className="w-100"
              value={editWillCallOrderData.puStateProvID}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: !!editWillCallOrderData.puStateProvID,
              }}
              inputProps={{ maxLength: 3 }}
            />
          </Box>
          <Box className="d-flex gap-2">
            <TextField
              variant="outlined"
              {...register("puPostalCode")}
              name="puPostalCode"
              id="txtPickupPostalCode"
              label="ZIP"
              className="w-100"
              inputProps={{ maxLength: 10 }}
              onChange={handleInputChange}
              onKeyDown={handleZipCodeKeyDown}
              onBlur={handleZipCodeFocusOut}
              value={editWillCallOrderData.puPostalCode}
              error={!!formErrors.puPostalCode || pickupZipCodeError}
              InputLabelProps={{ shrink: !!editWillCallOrderData.puPostalCode }}
              InputProps={{
                endAdornment: !!formErrors.puPostalCode && (
                  <InputAdornment position="end" disablePointerEvents={true}>
                    <InfoRoundedIcon
                      style={{ color: red[500] }}
                      fontSize="small"
                    />
                  </InputAdornment>
                ),
              }}
            />

            {/* <Select variant="outlined" label="Country" className="w-100"></Select> */}
            <FormControl size="medium" className="w-100">
              <InputLabel id="demo-select-small-label">Country</InputLabel>
              <Select
                labelId="demo-select-small-label"
                value={editWillCallOrderData.puCountryID} // Ensure this matches your state variable
                label="Country"
                id="ddPickupCountry"
                {...register("puCountryID")}
                name="puCountryID"
                fullWidth
                onChange={handleInputChange}
                sx={{ width: isMobile ? "100%" : undefined }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },

                  PaperProps: {
                    style: menuStyles,
                  },
                }}
              >
                {countryData.length > 0 &&
                  countryData.map((country: any) => (
                    <MenuItem key={country.countryID} value={country.countryID}>
                      {country.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box className="d-flex gap-2">
            <TextField
              variant="outlined"
              label="SEE"
              className="w-100"
              name="puPersonToSee"
              id="txtPickupPersonToSee"
              InputLabelProps={{
                shrink: !!editWillCallOrderData.puPersonToSee,
              }}
              value={editWillCallOrderData.puPersonToSee}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              variant="outlined"
              id="txtPickupAirport"
              label="Airport"
              {...register("puAirportID")}
              name="puAirportID"
              value={editWillCallOrderData.puAirportID}
              onChange={(e) => {
                const re = /^[a-zA-Z\s]*$/; // Regex to allow only letters and spaces
                const value = e.target.value;
                if (re.test(value)) {
                  handleInputChange(e); // Call the original handler if valid
                }
              }}
              className="w-100"
              InputLabelProps={{ shrink: !!editWillCallOrderData.puAirportID }}
              inputProps={{ maxLength: 3 }}
              error={!!formErrors.puAirportID}
              InputProps={{
                endAdornment: !!formErrors.puAirportID && (
                  <InputAdornment position="end" disablePointerEvents={true}>
                    <InfoRoundedIcon
                      style={{ color: red[500] }}
                      fontSize="small"
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <TextField
            variant="outlined"
            label="Instructions"
            id="txtPickupInstructions"
            {...register("puInstructions")}
            name="puInstructions"
            inputProps={{ maxLength: 2000 }}
            placeholder="Enter any additional instructions"
            value={editWillCallOrderData.puInstructions}
            InputLabelProps={{ shrink: !!editWillCallOrderData.puInstructions }}
            onChange={handleInputChange}
            multiline
            rows={4}
          />
        </Box>
        <Box className="d-flex flex-column mt-3">
          <Typography variant="h6" className="Sub-header">
            Pickup Contact
          </Typography>
          <Box className="d-flex gap-12">
            <TextField
              variant="outlined"
              label="Phone"
              id="txtPickupPhoneNumber"
              {...register("puPhoneNumber")}
              value={editWillCallOrderData.puPhoneNumber}
              InputLabelProps={{
                shrink: !!editWillCallOrderData.puPhoneNumber,
              }}
              onChange={handleInputChange}
              onKeyDown={handleNumericValue}
              name="puPhoneNumber"
              className="w-100"
              inputProps={{ maxLength: 25 }}
            />
            <TextField
              variant="outlined"
              label="Email Address"
              id="txtPickupEmail"
              className="w-100"
              {...register("puEmailAddress")}
              name="puEmailAddress"
              value={editWillCallOrderData.puEmailAddress}
              InputLabelProps={{
                shrink: !!editWillCallOrderData.puEmailAddress,
              }}
              onChange={handleInputChange}
              inputProps={{ maxLength: 100 }}
            />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                name="puKeep"
                id="cbPuKeep"
                checked={editWillCallOrderData.puKeep}
                onChange={handleInputChange}
              />
            }
            label="Save to address book"
            className="pt-8"
          />
        </Box>
      </Box>
    </>
  );
};

export default EditPickupAddress;
