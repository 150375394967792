import React, {
  useState,
  FC,
  useEffect,
  useCallback,
  ChangeEvent,
} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableBody,
  TableCell,
  TableHead,
  Box,
  Typography,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TableRow,
  SelectChangeEvent,
  TextField,
  ButtonGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Grid,
  useMediaQuery,
  Autocomplete,
} from "@mui/material";
import useCurrencyLogic from "../../lookup/currency/currency.logic";
import usePackageTypeLogic from "../../lookup/package-type/package-type.logic";
import useContentLogic from "../../lookup/contents/contents.logic";
import {
  ContentItemDto,
  PackageTypeItemDto,
} from "../../../models/long-form-item-dto";

import {
  handleDecimalValue,
  handleNumericValue,
} from "../../util/numeric-value";
import { ShipmentPieceDto } from "../../../models/shipment-piece-dto";
import { MAX_COMPANY_ID } from "../../../config/constants";
import useInsertShipmentPieceLogic from "../../lookup/shipment-piece/insert-shipment-piece/insert-shipment-piece.logic";
import useUpdateShipmentPieceLogic from "../../lookup/shipment-piece/update-shipment-piece/update-shipment-piece.logic";
import moment from "moment";

// Define the prop types for the MyDialog component
interface MyDialogProps {
  open: boolean;
  shipmentPiece: ShipmentPieceDto;
  handleClose: () => void;
  onAddNewPiece: (newPiece: any) => void;
  currentPieceIndex: any;
  accountNumber?: any;
  packageTypeDependancyCustID?: any;
  updateEditOrderData?: any;
}

const EditNewPiecesDialog: FC<MyDialogProps> = ({
  open,
  shipmentPiece,
  handleClose,
  onAddNewPiece,
  currentPieceIndex,
  accountNumber,
  packageTypeDependancyCustID,
  updateEditOrderData,
}) => {
  const [currencies, setCurrencies] = useState<
    {
      currencyID: string;
      shortName: string;
      name: string;
      description: string;
      symbolText: string;
      symbolImage: string;
    }[]
  >([]);
  const [packageTypes, setPackageTypes] = useState<PackageTypeItemDto[]>([]);
  const [contents, setContents] = useState<ContentItemDto[]>([]);
  const { currency } = useCurrencyLogic();
  const { packageType, calculateDimWtAPI } = usePackageTypeLogic();
  const { content } = useContentLogic();
  const [isDangerousGoods, setIsDangerousGoods] = useState(false);
  const [isRedioActive, setIsRedioActive] = useState(false);
  const [isDryIceRequire, setIsDryIceRequire] = useState(false);
  const [isGelPacksRequire, setIsGelPacksRequire] = useState(false);
  const [numberOfCopies, setNumberOfCopies] = useState<number>(1);
  const { insertShipmentPiece } = useInsertShipmentPieceLogic();
  const { updateShipmentPiece } = useUpdateShipmentPieceLogic();

  const [pieceData, setPieceData] = useState<ShipmentPieceDto>(shipmentPiece);
  const contentsReferenceGroupGUID = localStorage.getItem(
    "contentsReferenceGroupGUID"
  );

  useEffect(() => {
    setPieceData(shipmentPiece);
    setIsDangerousGoods(shipmentPiece.isDangerousGoods);
    setIsRedioActive(shipmentPiece.isRadioactive);
    setIsDryIceRequire(shipmentPiece.carrierToProvideDryIce);
    setIsGelPacksRequire(shipmentPiece.carrierToProvideGelPack);
  }, [open]);

  const getCurrency = useCallback(async () => {
    try {
      const response = await currency();
      if (response.data && response.data.data) {
        setCurrencies(response.data.data);
      }
    } catch (err) {}
  }, []);

  useEffect(() => {
    getPackageType(packageTypeDependancyCustID?.customerID || 0);
  }, [packageTypeDependancyCustID]);

  const getPackageType = useCallback(async (customerID: number) => {
    try {
      const param = {
        companyID: MAX_COMPANY_ID,
        showDeleted: false,
        customerID: customerID,
        includeBlank: true,
      };

      const response = await packageType(param);
      if (response.data && response.data.data) {
        setPackageTypes(response.data.data);
      }
    } catch (err) {}
  }, []);

  const getContents = useCallback(async () => {
    try {
      const credentials = {
        companyID: MAX_COMPANY_ID,
        contentsReferenceGroupGUID: contentsReferenceGroupGUID || "",
      };

      const response = await content(credentials);
      if (response.data && response.data.data) {
        setContents(response.data.data);
      }
    } catch (err) {}
  }, []);

  useEffect(() => {
    getCurrency();

    getContents();
  }, [getCurrency, getContents]);

  useEffect(() => {
    const handler = setTimeout(() => {
      handleDimWtCalculation();
    }, 500);

    // Cleanup function to clear timeout
    return () => {
      clearTimeout(handler);
    };
  }, [
    pieceData?.quantity,
    pieceData?.height,
    pieceData?.width,
    pieceData?.length,
    pieceData?.weightUOM,
    pieceData?.weight,
  ]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPieceData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setPieceData((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    // Creating an HTMLInputElement-like event object
    const { name, value } = event.target;
    const syntheticEvent = {
      target: {
        name: name,
        value: value,
      },
    } as ChangeEvent<HTMLInputElement>;
    handleInputChange(syntheticEvent);
  };

  const removeDuplicates = (array: ContentItemDto[]) => {
    // Create a Set to track already seen properNames and filter the duplicates
    const seen = new Set();
    return array.filter((item) => {
      if (seen.has(item.properName)) {
        return false;
      } else {
        seen.add(item.properName);
        return true;
      }
    });
  };
  const handleSelectContentChange = (
    event: React.ChangeEvent<{}>,
    newValue: ContentItemDto | null
  ) => {
    const selectedContent = newValue;

    setPieceData((prev) => ({
      ...prev,
      contents: selectedContent ? selectedContent.properName : "",
      dgUNNumber: selectedContent?.unNumber || "",
      dgCommodityClass: selectedContent?.commodityClass || "",
      dgProperName: selectedContent?.properName || "",
      isDangerousGoods: selectedContent?.isDangerousGoods || false,
      isRadioactive: selectedContent?.isRadioactive || false,
    }));
    setIsDangerousGoods(selectedContent?.isDangerousGoods || false);
    setIsRedioActive(selectedContent?.isRadioactive || false);
  };

  const handleSelectOuterPackageChange = (event: SelectChangeEvent<string>) => {
    const selectedPackageItem = packageTypes.find(
      (item) => item.packagingTypeGUID === event.target.value
    );
    if (selectedPackageItem) {
      setPieceData((prev) => ({
        ...prev,
        packagingType: selectedPackageItem.name,
        packagingTypeGUID_Outer: event.target.value,
        weight: selectedPackageItem.weight ? selectedPackageItem.weight : 0.0,
        weightUOM: selectedPackageItem.weightUOM,
        length: selectedPackageItem.length ? selectedPackageItem.length : "",
        width: selectedPackageItem?.width ? selectedPackageItem?.width : "",
        height: selectedPackageItem?.height ? selectedPackageItem?.height : "",
        sizeUOM: selectedPackageItem.sizeUOM,
      }));
    }
  };
  interface MenuStyles {
    maxHeight: string;
    maxWidth: string;
  }

  const [menuStyles, setMenuStyles] = useState<MenuStyles>({
    maxHeight: "200px", // Default max height for desktop
    maxWidth: "48%",
  });

  const isXs = useMediaQuery("(max-width:600px)"); // Adjust the breakpoint as needed

  useEffect(() => {
    if (isXs) {
      setMenuStyles((prevStyles) => ({
        ...prevStyles,
        maxWidth: "80%",
      }));
    } else {
      setMenuStyles((prevStyles) => ({
        ...prevStyles,
        maxWidth: "48%",
      }));
    }
  }, [isXs]);
  const getPackagingName = (guid: string) => {
    const selectedPackage = packageTypes.find(
      (pt) => pt.packagingTypeGUID === guid
    );
    return selectedPackage ? selectedPackage.name : "";
  };

  const getCurrencyName = (id: string) => {
    const selectedCurrency = currencies.find(
      (currency: { currencyID: string }) => currency.currencyID === id
    );
    return selectedCurrency ? selectedCurrency.shortName : "";
  };

  const handleNumberOfCopiesChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(event.target.value);

    // Check if the value is within the range 1 to 10
    if (value >= 1 && value <= 10) {
      setNumberOfCopies(value);
    } else {
      // If the value is less than 1, set it to 1
      if (value < 1) {
        setNumberOfCopies(1);
      }
      // If the value is greater than 10, set it to 10
      if (value > 10) {
        setNumberOfCopies(10);
      }
    }
  };

  const getDimWt = async (
    paramsData: any,
    updateItem: any,
    updateItemIndex: number,
    updateDirectState: boolean = false
  ) => {
    try {
      const response = await calculateDimWtAPI(paramsData);

      if (response.data && response.data) {
        setPieceData((prev) => ({ ...prev, dimWeight: response.data.data }));
      }
    } catch (err) {
    } finally {
    }
  };
  const handleDimWtCalculation = () => {
    if (
      pieceData.quantity &&
      pieceData.length &&
      pieceData.width &&
      pieceData.height &&
      pieceData.weightUOM
    ) {
      // const result = calculateDimWeight(
      //   "US",
      //   "US",
      //   pieceData.quantity,
      //   pieceData.length,
      //   pieceData.width,
      //   pieceData.height,
      //   "IN",
      //   pieceData.weightUOM
      // );
      // setPieceData((prev) => ({ ...prev, dimWeight: result }));

      const puReadtDate = updateEditOrderData?.puReadyNow
        ? moment().format("YYYY-MM-DDTHH:mm:ss.SSS")
        : updateEditOrderData?.puReadyTime
        ? moment(updateEditOrderData.puReadyTime).format(
            "YYYY-MM-DDTHH:mm:ss.SSS"
          )
        : moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
      const params = {
        puReadyDateTime: puReadtDate,
        serviceID: updateEditOrderData?.serviceID
          ? updateEditOrderData?.serviceID
          : 0,
        origCountryID: updateEditOrderData?.puCountryID
          ? updateEditOrderData?.puCountryID
          : "",
        destCountryID: updateEditOrderData?.delCountryID
          ? updateEditOrderData?.delCountryID
          : "",
        length: pieceData.length || 0,
        width: pieceData.width || 0,
        height: pieceData.height || 0,
        sizeUOM: updateEditOrderData?.sizeUOM || "IN",
        weightUOM: pieceData.weightUOM,
        qunatity: pieceData.quantity || 1,
        customerID: packageTypeDependancyCustID?.apiCustomerID || 0,
      };
      if (pieceData.length > 0 && pieceData.width > 0 && pieceData.height > 0) {
        getDimWt(params, [], 0, true);
      }
    }
  };

  const handleSave = async () => {
    try {
      const promises = [];

      for (let i = 0; i < numberOfCopies; i++) {
        const apiCall = shipmentPiece.shipmentPieceID
          ? updateShipmentPieceData(pieceData)
          : insertShipmentPieceData(pieceData);

        promises.push(apiCall);
      }

      await Promise.all(promises); // Wait for all API calls to finish

      handleClose(); // Only close the modal after all API calls are complete
      setNumberOfCopies(1);
    } catch (err) {}
  };

  const insertShipmentPieceData = useCallback(
    async (pieceData: ShipmentPieceDto) => {
      try {
        const param = pieceData;
        await insertShipmentPiece(param);
        // Handle response if needed
      } catch (err) {}
    },
    []
  );

  const updateShipmentPieceData = useCallback(
    async (pieceData: ShipmentPieceDto) => {
      try {
        const param = pieceData;
        await updateShipmentPiece(param);
        // Handle response if needed
      } catch (err) {}
    },
    []
  );

  const handleDialogClose = () => {
    handleClose();
    setPieceData(shipmentPiece);
    setNumberOfCopies(1);
  };

  const [searchInput, setSearchInput] = useState("");

  return (
    <Dialog
      maxWidth={"xl"}
      open={open}
      onClose={handleClose}
      style={{ height: "90%" }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className="Popup-header p16" id="responsive-dialog-title">
        Add/Edit Piece Details
      </DialogTitle>
      <DialogContent className="p16">
        <>
          <Box className="container padding0">
            <Typography variant="h6" className="package-header">
              Packaging
            </Typography>
            <Box className="Divider pb-24">
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <FormControl className="w-100">
                    <Autocomplete
                      id="ddNewPieceContent"
                      className="w-100"
                      options={removeDuplicates(contents).sort((a, b) => {
                        const isAlpha = (val: string) => /^[A-Za-z]/.test(val);
                        const isSpecial = (val: string) =>
                          /^[^A-Za-z0-9]/.test(val);
                        //const isNumber = (val: string) => /^[0-9]/.test(val);

                        const aName = a.properName || "";
                        const bName = b.properName || "";

                        if (isAlpha(aName) && isAlpha(bName)) {
                          return aName.localeCompare(bName);
                        } else if (isAlpha(aName)) {
                          return -1; // a is alphabetic, comes first
                        } else if (isAlpha(bName)) {
                          return 1; // b is alphabetic, comes first
                        } else if (isSpecial(aName) && isSpecial(bName)) {
                          return aName.localeCompare(bName);
                        } else if (isSpecial(aName)) {
                          return -1; // a is special character, comes before numbers
                        } else if (isSpecial(bName)) {
                          return 1; // b is special character, comes before numbers
                        } else {
                          return aName.localeCompare(bName); // fallback for numbers
                        }
                      })} // Remove duplicates and sort
                      getOptionLabel={(option) => option.properName || ""}
                      value={
                        contents.find(
                          (content) => content.properName === pieceData.contents
                        ) || null
                      } // Ensure null when no selection
                      onChange={(event, newValue) =>
                        handleSelectContentChange(event, newValue)
                      } // Handle change
                      inputValue={searchInput} // Controlled inputValue
                      onInputChange={(event, newInputValue) => {
                        // Clear the inputValue when empty
                        if (newInputValue === "") {
                          handleSelectContentChange(event, null); // Clear selection
                        }
                        setSearchInput(newInputValue); // Update inputValue
                      }}
                      filterOptions={(options, state) => {
                        const inputValue = state.inputValue.toLowerCase();
                        return options.filter((option) => {
                          const properName = option.properName
                            ? option.properName.toLowerCase()
                            : "";
                          return properName.includes(inputValue);
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Contents"
                        />
                      )}
                      renderOption={(props, option, state) => {
                        const isSelected =
                          option.properName === pieceData.contents; // Check if the option is selected

                        return (
                          <>
                            {state.index === 0 && (
                              <MenuItem
                                disabled
                                className="dropdown-grid Table-head"
                              >
                                <TableHead>
                                  <TableRow className="table-fixed">
                                    <TableCell className="weight-500 font14 width70 thead-cell">
                                      UN #
                                    </TableCell>
                                    <TableCell className="weight-500 font14 width-name pr-10 thead-cell">
                                      Proper Name
                                    </TableCell>
                                    <TableCell className="weight-500 font14 width70 thead-cell">
                                      Class
                                    </TableCell>
                                    <TableCell className="weight-500 font14 width70 thead-cell">
                                      DG
                                    </TableCell>
                                    <TableCell className="weight-500 font14 width70 thead-cell">
                                      RA
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                              </MenuItem>
                            )}
                            <li
                              {...props}
                              style={{
                                padding: "0px 16px",
                                lineHeight: "1.2",
                                backgroundColor: isSelected
                                  ? "#04638614"
                                  : "inherit", // Highlight selected
                                fontWeight: isSelected ? "bold" : "normal", // Optional: bold text for selected
                              }}
                              className="custom-dropdown-item"
                            >
                              <TableRow className="table-fixed">
                                <TableCell className="width70 Info-wrap">
                                  {option.unNumber}
                                </TableCell>
                                <TableCell className="width-name pr-10 Info-wrap">
                                  {option.properName}
                                </TableCell>
                                <TableCell className="width70 Info-wrap">
                                  {option.commodityClass}
                                </TableCell>
                                <TableCell className="width70 Info-wrap">
                                  {option.isDangerousGoods ? "True" : "False"}
                                </TableCell>
                                <TableCell className="width70 Info-wrap">
                                  {option.isRadioactive ? "True" : "False"}
                                </TableCell>
                              </TableRow>
                            </li>
                          </>
                        );
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    label="Reference"
                    name="reference"
                    id="txtNewPieceReference"
                    value={pieceData.reference}
                    onChange={handleInputChange}
                    variant="outlined"
                    className="w-100"
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <FormControl className="w-100">
                    <InputLabel>Outer</InputLabel>
                    <Select
                      name="packagingTypeGUID_Outer"
                      id="ddNewPiecePackageOuter"
                      variant="outlined"
                      className="w-100"
                      label="Packaging"
                      value={pieceData.packagingTypeGUID_Outer}
                      onChange={handleSelectOuterPackageChange}
                      renderValue={(selected) => getPackagingName(selected)}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },

                        PaperProps: {
                          style: menuStyles,
                        },
                      }}
                    >
                      <MenuItem disabled className="dropdown-grid Table-head">
                        <TableHead>
                          <TableRow className="table-fixed">
                            <TableCell className="weight-500 font14 width104 thead-cell">
                              Name
                            </TableCell>
                            <TableCell className="weight-500 font14 width200 thead-cell">
                              Description
                            </TableCell>
                            <TableCell className="weight-500 font14 width180 thead-cell">
                              Dimensions
                            </TableCell>
                            <TableCell className="weight-500 font14 width94p thead-cell">
                              Wt
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      </MenuItem>
                      {packageTypes.map(
                        (row: PackageTypeItemDto, index: any) => (
                          <MenuItem
                            className="dropdown-grid"
                            key={row.packagingTypeGUID}
                            value={row.packagingTypeGUID}
                          >
                            <TableBody className="table-fixed ptb-8">
                              <TableCell className="width104 Info-wrap">
                                {row.name}
                              </TableCell>
                              <TableCell className="width200 Info-wrap">
                                {row.description}
                              </TableCell>
                              <TableCell className="width180 Info-wrap">
                                {row.length && row.width && row.height
                                  ? `${row.length} X ${row.width} X ${row.height} ${row.sizeUOM}`
                                  : ""}
                              </TableCell>
                              <TableCell className="width94p Info-wrap">
                                {row.weight && row.weightUOM
                                  ? `${row.weight} ${row.weightUOM}`
                                  : ""}
                              </TableCell>
                              {/* <TableCell className="width230 Info-wrap">{row.packagingTypeGUID}</TableCell> */}
                            </TableBody>
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <FormControl className="w-100">
                    <InputLabel>Inner</InputLabel>
                    <Select
                      name="packagingTypeGUID_Inner"
                      id="ddNewPiecePackageInner"
                      variant="outlined"
                      className="w-100"
                      label="Inner"
                      onChange={handleSelectChange}
                      value={pieceData.packagingTypeGUID_Inner}
                      renderValue={(selected) => getPackagingName(selected)}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },

                        PaperProps: {
                          style: menuStyles,
                        },
                      }}
                    >
                      <MenuItem disabled className="dropdown-grid Table-head">
                        <TableHead>
                          <TableRow className="table-fixed">
                            <TableCell className="weight-500 font14 width104 thead-cell">
                              Name
                            </TableCell>
                            <TableCell className="weight-500 font14 width200 thead-cell">
                              Description
                            </TableCell>
                            <TableCell className="weight-500 font14 width180 thead-cell">
                              Dimensions
                            </TableCell>
                            <TableCell className="weight-500 font14 width94p thead-cell">
                              Wt
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      </MenuItem>
                      {packageTypes.map(
                        (row: PackageTypeItemDto, index: any) => (
                          <MenuItem
                            className="dropdown-grid"
                            key={row.packagingTypeGUID}
                            value={row.packagingTypeGUID}
                          >
                            <TableBody className="table-fixed ptb-8">
                              <TableCell className="width104 Info-wrap">
                                {row.name}
                              </TableCell>
                              <TableCell className="width200 Info-wrap">
                                {row.description}
                              </TableCell>
                              <TableCell className="width180 Info-wrap">
                                {row.length && row.width && row.height
                                  ? `${row.length} X ${row.width} X ${row.height} ${row.sizeUOM}`
                                  : ""}
                              </TableCell>
                              <TableCell className="width94p Info-wrap">
                                {row.weight && row.weightUOM
                                  ? `${row.weight} ${row.weightUOM}`
                                  : ""}
                              </TableCell>
                              {/* <TableCell className="width230 Info-wrap">{row.packagingTypeGUID}</TableCell> */}
                            </TableBody>
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={3} md={3} sm={3} xs={6}>
                      <TextField
                        label="Qty"
                        name="quantity"
                        id="txtNewPieceQuantity"
                        value={pieceData.quantity || ""}
                        onChange={handleInputChange}
                        onKeyDown={handleNumericValue}
                        variant="outlined"
                        className="w-100"
                        inputProps={{ maxLength: 4 }}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={6}>
                      <TextField
                        label="Weight"
                        name="weight"
                        id="txtNewPieceWeight"
                        value={pieceData.weight || ""}
                        onChange={handleInputChange}
                        onKeyDown={handleDecimalValue}
                        variant="outlined"
                        className="w-100"
                        inputProps={{ min: 0, maxLength: 4 }}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={6}>
                      <TextField
                        label="Dim. Wt"
                        name="dimWeight"
                        value={pieceData.dimWeight || ""}
                        onChange={handleInputChange}
                        id="txtNewPieceDimWeight"
                        disabled
                        variant="outlined"
                        className="w-100"
                      />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={6}>
                      <Select
                        defaultValue="LB"
                        name="weightUOM"
                        id="ddNewPieceWeightUom"
                        value={pieceData.weightUOM || ""}
                        onChange={handleSelectChange}
                        variant="outlined"
                        className="w-100"
                      >
                        <MenuItem value="LB">LB</MenuItem>
                        <MenuItem value="KG">KG</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={3} md={3} sm={3} xs={4}>
                      <TextField
                        label="Length"
                        name="length"
                        id="txtNewPieceLength"
                        value={pieceData.length || ""}
                        onChange={handleInputChange}
                        onKeyDown={handleNumericValue}
                        variant="outlined"
                        className="w-100"
                        inputProps={{ min: 0, maxLength: 4 }}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={4}>
                      <TextField
                        label="Width"
                        name="width"
                        value={pieceData?.width || ""}
                        onChange={handleInputChange}
                        id="txtNewPieceWidth"
                        variant="outlined"
                        onKeyDown={handleNumericValue}
                        className="w-100"
                        inputProps={{ min: 0, maxLength: 4 }}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={4}>
                      <TextField
                        label="Height"
                        name="height"
                        value={pieceData?.height || ""}
                        onChange={handleInputChange}
                        id="txtNewPieceHeight"
                        onKeyDown={handleNumericValue}
                        variant="outlined"
                        className="w-100"
                        inputProps={{ min: 0, maxLength: 4 }}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={4}>
                      <Select
                        defaultValue="IN"
                        name="sizeUOM"
                        value={pieceData.sizeUOM}
                        id="ddNewPieceSizeUOM"
                        onChange={handleSelectChange}
                        variant="outlined"
                        className="w-100"
                      >
                        <MenuItem value="IN">IN</MenuItem>
                        <MenuItem value="CM">CM</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box className="Divider pb-24">
              <Typography variant="h6" className="package-header pt-24">
                Dangerous Goods
              </Typography>
              <Box className="d-flex gap-16">
                <FormControl component="fieldset" className="w-100">
                  <FormLabel component="legend" className="font14 weight-500">
                    Does the shipment contain dangerous goods?
                  </FormLabel>
                  <ButtonGroup>
                    <Button
                      id="btnNewPieceIsDangerousGoodsYes"
                      variant={isDangerousGoods ? "contained" : "outlined"}
                      className="w-100 rounded-left-only"
                      onClick={() => {
                        setIsDangerousGoods(true);
                        setPieceData((prev) => ({
                          ...prev,
                          isDangerousGoods: true,
                        }));
                      }}
                    >
                      YES
                    </Button>
                    <Button
                      id="btnNewPieceIsDangerousGoodsNo"
                      variant={!isDangerousGoods ? "contained" : "outlined"}
                      className="w-100 rounded-right-only"
                      onClick={() => {
                        setIsDangerousGoods(false);
                        setPieceData((prev) => ({
                          ...prev,
                          isDangerousGoods: false,
                        }));
                      }}
                    >
                      NO
                    </Button>
                  </ButtonGroup>
                </FormControl>
              </Box>
              <Box className="d-flex gap-16 w-100 pt-24 mb-24">
                <FormControl component="fieldset" className="w-100">
                  <FormLabel component="legend" className="font14 weight-500">
                    Does the shipment contain radioactive material?
                  </FormLabel>
                  <ButtonGroup>
                    <Button
                      id="btnNewPieceIsRedioActiveYes"
                      variant={isRedioActive ? "contained" : "outlined"}
                      className="w-100 rounded-left-only"
                      onClick={() => {
                        setIsRedioActive(true);
                        setPieceData((prev) => ({
                          ...prev,
                          isRadioactive: true,
                        }));
                      }}
                    >
                      YES
                    </Button>
                    <Button
                      id="btnNewPieceIsRedioActiveNo"
                      variant={!isRedioActive ? "contained" : "outlined"}
                      className="w-100 rounded-right-only"
                      onClick={() => {
                        setIsRedioActive(false);
                        setPieceData((prev) => ({
                          ...prev,
                          isRadioactive: false,
                        }));
                      }}
                    >
                      NO
                    </Button>
                  </ButtonGroup>
                </FormControl>
              </Box>

              <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={4} xs={6}>
                  {" "}
                  <TextField
                    label="UN #"
                    name="dgUNNumber"
                    id="txtdgUNNumber"
                    value={pieceData.dgUNNumber}
                    onChange={handleInputChange}
                    variant="outlined"
                    className="w-100"
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={6}>
                  <TextField
                    label="Class"
                    name="dgCommodityClass"
                    id="txtCommodityClass"
                    value={pieceData.dgCommodityClass}
                    onChange={handleInputChange}
                    variant="outlined"
                    className="w-100"
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <TextField
                    label="Proper Name"
                    name="dgProperName"
                    id="txtPropername"
                    value={pieceData.dgProperName}
                    onChange={handleInputChange}
                    variant="outlined"
                    className="w-100"
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <TextField
                    label="Quantity & Pack"
                    name="dgQuantityAndPackType"
                    id="txtDgQuantityAndPackType"
                    value={pieceData.dgQuantityAndPackType}
                    onChange={handleInputChange}
                    variant="outlined"
                    className="w-100"
                    inputProps={{ maxLength: 300 }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <TextField
                    label="Packing Group"
                    name="dgPackingGroup"
                    value={pieceData.dgPackingGroup}
                    id="txtDgPackingGroup"
                    onChange={handleInputChange}
                    variant="outlined"
                    className="w-100"
                    inputProps={{ maxLength: 300 }}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    multiline
                    rows={4}
                    name="dgPackingInstr"
                    id="txtDgPackingInstr"
                    label="Packing Instructions"
                    value={pieceData.dgPackingInstr}
                    onChange={handleInputChange}
                    variant="outlined"
                    placeholder="Enter any additional Packing instructions"
                    className="w-100"
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
              </Grid>

              <Box className="d-flex gap-16 w-100 pt-24">
                <FormControl component="fieldset" className="w-100">
                  <FormLabel component="legend" className="weight-500 font14">
                    Does the shipment require dry ice?
                  </FormLabel>
                  <ButtonGroup>
                    <Button
                      variant={isDryIceRequire ? "contained" : "outlined"}
                      className="w-100 rounded-left-only"
                      id="btnIsDryIceYes"
                      onClick={() => {
                        setIsDryIceRequire(true);
                        // setPieceData((prev) => ({
                        //   ...prev,
                        //   carrierToProvideDryIce: true,
                        // }));
                      }}
                    >
                      YES
                    </Button>
                    <Button
                      variant={!isDryIceRequire ? "contained" : "outlined"}
                      className="w-100 rounded-right-only"
                      id="btnIsDryIceNo"
                      onClick={() => {
                        setIsDryIceRequire(false);
                        // setPieceData((prev) => ({
                        //   ...prev,
                        //   carrierToProvideDryIce: false,
                        // }));
                      }}
                    >
                      NO
                    </Button>
                  </ButtonGroup>
                  {isDryIceRequire && (
                    <Box className="d-flex flex-column gap-2 w-100 mt-2">
                      <TextField
                        label="Amount of dry ice"
                        variant="outlined"
                        className="w-100"
                        name="dgDryIceWeight"
                        id="txtDgDryIceWeight"
                        value={pieceData.dgDryIceWeight || ""}
                        onChange={handleInputChange}
                        onKeyDown={handleDecimalValue}
                        inputProps={{ maxLength: 9 }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="cbCarrierToProvideDryIce"
                            name="carrierToProvideDryIce"
                            checked={pieceData.carrierToProvideDryIce}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="LFS/MNX to provide"
                        className="p-2"
                      />
                    </Box>
                  )}
                </FormControl>
              </Box>
              <Box className="d-flex gap-16 w-100 pt-24 pb-24">
                <FormControl component="fieldset" className="w-100">
                  <FormLabel component="legend" className="weight-500 font14">
                    Does the shipment require gel packs?
                  </FormLabel>
                  <ButtonGroup>
                    <Button
                      id="btnIsGelPacksYes"
                      variant={isGelPacksRequire ? "contained" : "outlined"}
                      className="w-100 rounded-left-only"
                      onClick={() => {
                        setIsGelPacksRequire(true);
                        // setPieceData((prev) => ({
                        //   ...prev,
                        //   carrierToProvideGelPack: true,
                        // }));
                      }}
                    >
                      YES
                    </Button>
                    <Button
                      id="btnIsGelPacksNo"
                      variant={!isGelPacksRequire ? "contained" : "outlined"}
                      className="w-100 rounded-right-only"
                      onClick={() => {
                        setIsGelPacksRequire(false);
                        // setPieceData((prev) => ({
                        //   ...prev,
                        //   carrierToProvideGelPack: false,
                        // }));
                      }}
                    >
                      NO
                    </Button>
                  </ButtonGroup>
                  {isGelPacksRequire && (
                    <Box className="d-flex flex-column gap-2 w-100 mt-2">
                      <TextField
                        label="Amount of gel packs"
                        id="txtDgGelPackCount"
                        variant="outlined"
                        className="w-100 mt-3"
                        name="dgGelPackCount"
                        value={pieceData.dgGelPackCount || ""}
                        onChange={handleInputChange}
                        onKeyDown={handleDecimalValue}
                        inputProps={{ maxLength: 4 }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="carrierToProvideGelPack"
                            checked={pieceData.carrierToProvideGelPack}
                            onChange={handleCheckboxChange}
                            id="cbCarrierToProvideGelPack"
                          />
                        }
                        label="LFS/MNX to provide"
                        className="p-2"
                      />
                    </Box>
                  )}
                </FormControl>
              </Box>

              <Grid item lg={3} md={3} sm={3} xs={12}>
                <TextField
                  fullWidth
                  name="dgAuthorization"
                  label="Authorization"
                  value={pieceData.dgAuthorization}
                  onChange={handleInputChange}
                  variant="outlined"
                  id="txtAuthorization"
                  inputProps={{ maxLength: 40 }}
                />
              </Grid>
            </Box>

            <Box className="pb-24 pt-24">
              <Typography variant="h6" className="package-header">
                Customs
              </Typography>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <TextField
                    label="Declared Value"
                    name="declaredValue"
                    value={pieceData.declaredValue || ""}
                    onChange={handleInputChange}
                    onKeyDown={handleDecimalValue}
                    variant="outlined"
                    className="w-100"
                    id="txtDeclaredValue"
                    inputProps={{ maxLength: 8 }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Select
                    variant="outlined"
                    name="declaredValueCurrencyID"
                    defaultValue="USD"
                    id="ddDeclaredcurrency"
                    value={pieceData.declaredValueCurrencyID}
                    onChange={handleSelectChange}
                    renderValue={(selected) => getCurrencyName(selected)}
                    className="w-100"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },

                      PaperProps: {
                        style: menuStyles,
                      },
                    }}
                  >
                    {currencies.length > 0 &&
                      currencies.map((item) => (
                        <MenuItem key={item.currencyID} value={item.currencyID}>
                          {item.shortName}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      </DialogContent>
      <DialogActions
        className={`d-flex w-100 ${
          shipmentPiece.shipmentPieceID === 0
            ? "justify-content-between"
            : "justify-content-end"
        }`}
      >
        {shipmentPiece.shipmentPieceID === 0 && (
          <TextField
            type="number"
            label="Copy"
            name="numberOfCopies"
            defaultValue="1"
            id="txtNumberOfCopies"
            onChange={handleNumberOfCopiesChange}
            value={numberOfCopies}
            variant="outlined"
            className="ms-2"
            size="small"
            InputProps={{
              inputProps: { min: 1, max: 10 },
            }}
          />
        )}
        <Box className="d-flex gap-4">
          <Button
            onClick={handleDialogClose}
            className="MuiButton Action-btn"
            variant="text"
            id="btnNewPieceCancel"
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            className="MuiButton Action-btn"
            id="btnNewPieceSave"
          >
            SAVE
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditNewPiecesDialog;
