import React, { useState, FC, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  SelectChangeEvent,
  TextField,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Grid,
  IconButton,
} from "@mui/material";

import { AddressBookDto } from "../../../models/address-book-dto";
import { CountryItemDto } from "../../../models/country-item-dto";
import useCountryLogic from "../../lookup/country/country.logic";
import useServiceLogic from "../../lookup/service/service.logic";
import CloseIcon from "@mui/icons-material/Close";
import { useDecodeJWT } from "../../hook/use-decodeJWT";
import { MAX_COMPANY_ID } from "../../../config/constants";
import useZipCodeLogic from "../../lookup/zip-code/zip-code.logic";
import useAddressBookLogic from "../../../modules/address-book/address-book.logic";
import Loader from "../../util/loader";
import { ZipCodeItemDto } from "../../../models/zip-code-item-dto";

import uploadicon from "../../../assets/images/logo/upload-icon.svg";
//import calendarIcon from "../../../assets/images/logo/calendaricon.svg";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { red } from "@mui/material/colors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { handleNumericValue } from "../../util/numeric-value";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
// Define the prop types for the MyDialog component
interface MyDialogProps {
  open: boolean;
  currentAddress: AddressBookDto | undefined;
  handleClose: () => void;
  onAddNewAddress: () => void;
  setIsClose: (value: boolean) => void;
  isClose: boolean;
}

const AddEditAddress: FC<MyDialogProps> = ({
  open,
  currentAddress,
  handleClose,
  onAddNewAddress,
  setIsClose,
  isClose,
}) => {
  const [countryData, setCountryData] = useState<CountryItemDto[]>([]);
  const [zipCodeData, setZipCodeData] = useState<ZipCodeItemDto[]>([]);
  const [serviceData, setServiceData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  // const isMobile = useMediaQuery("(max-width:600px)");
  const { addEditAddress } = useAddressBookLogic();
  const { country } = useCountryLogic();
  const { zipCode } = useZipCodeLogic();
  const { service } = useServiceLogic();
  const token = useDecodeJWT();
  const userGroupGUIDLocalStrg: any = localStorage.getItem("userGroupGUID");
  //const [selectPostalCode, setSelectPostalCode] = useState("");
  const serviceAvailType = localStorage.getItem("serviceAvailType");
  // const [isClose,setIsClose]=useState(false);
  const defaultState = {
    addressCode: "",
    companyID: MAX_COMPANY_ID,
    companyName: "",
    address1: "",
    address2: "",
    address3: "",
    cityName: "",
    stateProvID: "",
    postalCode: "",
    countryID: "",
    timezoneID: "",
    attention: "",
    phoneNumber: "",
    emailAddress: "",
    reference: "",
    pickupInstructions: "",
    deliveryInstructions: "",
    groupAvailable: false,
    notifyOrderAck: false,
    notifyOrderRouted: false,
    notifyDispatched: false,
    notifyPickedUp: false,
    notifyDepToAp: false,
    notifyObcChecking: false,
    notifyDropped: false,
    notifyConfirmed: false,
    notifyDeparted: false,
    notifyArrived: false,
    notifyRecovered: false,
    notifyDelivered: false,
    notifyOrigQdt: false,
    notifyChangeQdt: false,
    airportID: "",
    pickupReadyDay: null,
    pickupReadyTime: "",
    deliveryRequestDay: null,
    deliveryRequestTime: "",
    serviceID: "0",
    serviceGUID: "",
    holdForPickup: false,
    dropOff: false,
    userGroupGUID: userGroupGUIDLocalStrg,
    insertUserGUID: token?.UserGUID,
    companyNameError: false,
    emailValidateError: false,
    pickupReadyValue: null,
    deliveryRequestTimeValue: null,
    address1Error: false,
    address2Error: false,
    address3Error: false,
    pickupInstructionsError: false,
    deliveryInstructionsError: false,
    postalCodeError: false,
  };

  const [addressData, setAddressData] = useState(defaultState);
  const [postalCodeAlertCount, setPostalCodeAlertCount] = useState(0);
  const [selectCountryID, setSelectCountryID] = useState("");

  const handleSave = async () => {
    try {
      const {
        companyName,
        emailAddress,
        address1,
        address2,
        address3,
        pickupInstructions,
        deliveryInstructions,
        countryID,
      } = addressData;
      const a1Error = address1?.length > 50 ? true : false;
      const a2Error = address2?.length > 50 ? true : false;
      const a3Error = address3?.length > 50 ? true : false;

      const p1Error = pickupInstructions?.length > 2000 ? true : false;
      const d1Error = deliveryInstructions?.length > 2000 ? true : false;
      setAddressData((prev) => ({
        ...prev,
        address1Error: a1Error,
        address2Error: a2Error,
        address3Error: a3Error,
        pickupInstructionsError: p1Error,
        deliveryInstructionsError: d1Error,
      }));

      if (!companyName || (emailAddress && !validateEmail(emailAddress))) {
        let errorField: any = null;
        if (!companyName) {
          errorField = document.getElementById("companyName");
          setAddressData((prev) => ({ ...prev, companyNameError: true }));
          if (errorField) {
            errorField.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        } else {
          setAddressData((prev) => ({
            ...prev,

            companyNameError: false,
          }));
        }
        if (emailAddress && !validateEmail(emailAddress)) {
          errorField = document.getElementById("emailAddress");
          setAddressData((prev) => ({ ...prev, emailValidateError: true }));
          if (errorField) {
            errorField.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        } else {
          setAddressData((prev) => ({
            ...prev,
            emailValidateError: false,
          }));
        }
        if (
          countryID != "US" &&
          countryID != "AE" &&
          addressData.postalCodeError &&
          postalCodeAlertCount === 0
        ) {
          window.alert(
            "Please ensure you have entered the proper Zip Code. After you have confirmed the Zip Code's accuracy, please try again."
          );
          setPostalCodeAlertCount(1);
        }

        return;
      } else {
        if (
          a1Error ||
          a2Error ||
          a3Error ||
          p1Error ||
          d1Error ||
          (addressData?.postalCodeError && countryID === "US")
        ) {
          return;
        }
        if (
          countryID != "US" &&
          countryID != "AE" &&
          addressData.postalCodeError &&
          postalCodeAlertCount === 0
        ) {
          window.alert(
            "Please ensure you have entered the proper Zip Code. After you have confirmed the Zip Code's accuracy, please try again."
          );
          setPostalCodeAlertCount(1);
          return;
        }
        setAddressData((prev) => ({
          ...prev,
          emailValidateError: false,
          companyNameError: false,
        }));
      }
      const {
        companyNameError,
        emailValidateError,
        serviceID,
        serviceGUID,
        insertUserGUID,
        pickupReadyValue,
        deliveryRequestTimeValue,
        userGroupGUID,
        groupAvailable,
        address1Error,
        address2Error,
        address3Error,
        pickupInstructionsError,
        deliveryInstructionsError,
        postalCodeError,
        ...rest
      } = addressData;
      const findServiceGUID = serviceData.find(
        (x: any) => x.serviceID === serviceID
      );

      let params: AddressBookDto = {
        ...rest,
        serviceID: parseInt(serviceID),
        serviceGUID: findServiceGUID?.serviceGUID,
        // insertUserGUID: token?.UserGUID,
      };
      if (!currentAddress) {
        params.insertUserGUID = token?.UserGUID;
      }

      params.pickupReadyDay =
        addressData.pickupReadyDay !== "" ? addressData.pickupReadyDay : null;
      params.deliveryRequestDay =
        addressData.deliveryRequestDay !== ""
          ? addressData.deliveryRequestDay
          : null;

      if (groupAvailable) {
        params.groupAvailable = true;
        params.userGroupGUID = userGroupGUIDLocalStrg;
      } else {
        params.groupAvailable = false;
        params.userGroupGUID = null;
      }

      setIsLoading(true);

      const response = await addEditAddress(params);
      setPostalCodeAlertCount(0);
      if (response?.data?.success) {
        onAddNewAddress();
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error: any) {}
  };

  const handleDialogClose = () => {
    setSelectCountryID("");
    setIsClose(true);
    setPostalCodeAlertCount(0);
    handleClose();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddressData((prev) => ({ ...prev, [name]: value }));
    // setAddressData((prev) => ({
    //   ...prev,
    //   postalCodeError: false,
    // }));
  };

  const handleCityInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddressData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setAddressData({
      ...addressData,
      [name]: value,
    });
    if (name === "countryID") {
      setSelectCountryID(value);
      const findTimezone: any = countryData.find((x) => x.countryID == value);
      setAddressData((prevState) => ({
        ...prevState,
        timezoneID: findTimezone?.timezoneID,
      }));
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setAddressData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleReadyTimeChange = (time: any) => {
    const timeOnly = time ? format(time, "hh:mm a") : null;
    setAddressData((prevState) => ({
      ...prevState,
      pickupReadyValue: time,
      pickupReadyTime: timeOnly ? timeOnly : "",
    }));
  };

  interface MenuStyles {
    maxHeight: string;
  }

  // interface servicestyle {
  //   maxHeight: string;
  // }

  // eslint-disable-next-line
  const [menuStyles, setMenuStyles] = useState<MenuStyles>({
    maxHeight: "200px", // Default max height for desktop
  });

  const handleDelTimeChange = (time: any) => {
    const timeOnly = time ? format(time, "hh:mm a") : null;
    setAddressData((prevState) => ({
      ...prevState,
      deliveryRequestTimeValue: time,
      deliveryRequestTime: timeOnly ? timeOnly : "",
    }));
  };

  const getCountry = async () => {
    try {
      setIsLoading(true);
      const param = {};
      const response = await country(param);
      if (response.data && response.data.data) {
        setCountryData(response.data.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setCountryData([]);
    }
  };

  const getService = async () => {
    try {
      const param: any = {
        companyID: MAX_COMPANY_ID,
        userGUID: token.UserGUID,
        // serviceAvailType: "All",
        serviceAvailType: serviceAvailType,
        baseDateTime: new Date(),
        origCountryID: "",
        origCityName: "",
        origStateProvID: "",
        origPostalCode: "",
        origAirportID: "",
        destCountryID: "",
        destCityName: "",
        destStateProvID: "",
        destPostalCode: "",
        destAirportID: "",
      };
      const response = await service(param);
      if (response.data && response.data.data) {
        setServiceData(response.data.data);
      } else {
        setServiceData([]);
      }
    } catch (err) {
      setServiceData([]);
    }
  };

  // const getZipCodeEdit = async (postalCodeDB: string = "",selectCountryID?:string) => {
  //   try {
  //     const { postalCode ,countryID} = addressData;
  //     // const { countryID: selectedCountry } = addressData;
  //     if ((!postalCode && !postalCodeDB)) {
  //       return;
  //     }
  //     setIsLoading(true);
  //     const param = {
  //       postalCode: postalCode ? postalCode :postalCodeDB,
  //       // countryID: selectedCountry.trim() ? selectedCountry.trim() : "",
  //       countryID: countryID ? countryID : selectCountryID || "",
  //     };
  //     const response = await zipCode(param);

  //     if (
  //       response.data &&
  //       response.data.data &&
  //       response.data.data.length > 0
  //     ) {
  //       const res = response.data.data;
  //       setZipCodeData(response.data.data);

  //       setAddressData((prev) => ({
  //         ...prev,
  //         postalCodeError: false,
  //         countryID: res[0].countryID,
  //         cityName: postalCodeDB
  //           ? currentAddress?.cityName
  //           : res[0].cityName || "",
  //         postalCode: res[0]?.postalCode || "",
  //         airportID: res[0]?.airportID || "",
  //         stateProvID: res[0]?.stateProvID || "",
  //         timezoneID: res[0].timezoneID,
  //       }));
  //     } else {
  //       setIsLoading(false);
  //       if(addressData.countryID === "US"){
  //         window.alert("You have entered an invalid Zip Code. Please ensure you have entered the proper Zip Code. If you continue to experience issues, please contact MNX.");
  //       }
  //       setAddressData((prev) => ({
  //         ...prev,
  //         postalCodeError: true,
  //         // countryID: res[0].countryID,
  //         // cityName: postalCodeDB
  //         //   ? currentAddress?.cityName
  //         //   : res[0].cityName || "",
  //         // postalCode: res[0]?.postalCode || "",
  //         // airportID: res[0]?.airportID || "",
  //         // stateProvID: res[0]?.stateProvID || "",
  //         // timezoneID: res[0].timezoneID,
  //       }));
  //     }
  //     setIsLoading(false);
  //   } catch (err) {
  //     setZipCodeData([]);
  //     setIsLoading(false);
  //   }
  // };

  const getZipCode = async (
    postalCodeDB: string = "",
    selectCountryID?: string
  ) => {
    try {
      const { postalCode, countryID } = addressData;
      // const { countryID: selectedCountry } = addressData;
      if (!postalCode && !postalCodeDB) {
        return;
      }
      setIsLoading(true);
      const param = {
        postalCode: postalCode ? postalCode : postalCodeDB,
        // countryID: selectedCountry.trim() ? selectedCountry.trim() : "",
        countryID: countryID ? countryID : selectCountryID || "",
      };
      const response = await zipCode(param);

      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        const res = response.data.data;
        setZipCodeData(response.data.data);

        var isPostalCodeError = false;
        var postalCodeWithoutCity = res.filter((x: any) => x.cityName === null);
        if (postalCodeWithoutCity.length > 0) {
          isPostalCodeError = true;
        }
        setAddressData((prev) => ({
          ...prev,
          postalCodeError: isPostalCodeError,
          countryID: res[0].countryID,
          cityName: postalCodeDB
            ? currentAddress?.cityName
            : res[0].cityName || "",
          postalCode: res[0]?.postalCode || "",
          airportID: res[0]?.airportID || "",
          stateProvID: res[0]?.stateProvID || "",
          timezoneID: res[0].timezoneID,
        }));
      } else {
        setIsLoading(false);
        if (addressData.countryID === "US") {
          window.alert(
            "You have entered an invalid Zip Code. Please ensure you have entered the proper Zip Code. If you continue to experience issues, please contact MNX."
          );
        }
        setAddressData((prev) => ({
          ...prev,
          postalCodeError: true,
          // countryID: res[0].countryID,
          // cityName: postalCodeDB
          //   ? currentAddress?.cityName
          //   : res[0].cityName || "",
          // postalCode: res[0]?.postalCode || "",
          // airportID: res[0]?.airportID || "",
          // stateProvID: res[0]?.stateProvID || "",
          // timezoneID: res[0].timezoneID,
        }));
      }
      setIsLoading(false);
    } catch (err) {
      setZipCodeData([]);
      setIsLoading(false);
    }
  };

  const handleZipCodeKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      getZipCode();
    }
  };

  const handleZipCodeBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    // const postalCode = zipCodeData[0]?.postalCode;
    // if (postalCode != event.target.value || postalCodeError) {
    //   getZipCode();
    // }
    getZipCode(); // to resolve mobile/tablet issue
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    getCountry();
    getService();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (currentAddress) {
      const {
        pickupDateTimeDisplay,
        deliveryDateTimeDisplay,
        pickupReadyTime,
        deliveryRequestTime,
        postalCode,
        countryID,
      } = currentAddress;
      const address = {
        ...currentAddress,
        pickupReadyValue: pickupReadyTime ? new Date(pickupReadyTime) : null,
        deliveryRequestTimeValue: deliveryRequestTime
          ? new Date(deliveryRequestTime)
          : null,
        pickupReadyTime: pickupDateTimeDisplay,
        deliveryRequestTime: deliveryDateTimeDisplay,
      };

      // setSelectPostalCode(postalCode || "");
      const currentCountryID = String(countryID);
      setSelectCountryID(currentCountryID);
      setAddressData(address as any);
      // setIsClose(false);
      // getZipCode(postalCode,currentCountryID);
    } else {
      setAddressData(defaultState);
      setZipCodeData([]);
    }

    if (zipCodeData.length === 0 && currentAddress?.cityName) {
      setZipCodeData([
        {
          cityName: currentAddress?.cityName,
          postalCode: "",
          stateProvID: "",
          timezoneID: "",
          countryID: "",
          countryName: "",
          airportID: "",
          airportPostalCode: "",
          address1: "",
          address2: "",
          address3: "",
        },
      ]);
    }
    setIsClose(false);
  }, [currentAddress, open]); // eslint-disable-line

  useEffect(() => {
    if (!isClose && currentAddress?.countryID !== selectCountryID) {
      getZipCode(currentAddress?.postalCode);
    }
  }, [selectCountryID, isClose]);

  const {
    companyNameError,
    emailValidateError,
    address1Error,
    address3Error,
    address2Error,
    pickupInstructionsError,
    deliveryInstructionsError,
    postalCodeError,
  } = addressData;

  return (
    <>
      {isLoading && open && (
        <>
          <Box className="global-loader-container">
            <Loader />
          </Box>
        </>
      )}

      <Dialog
        maxWidth={"xl"}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        style={{ height: "95%" }}
      >
        <DialogTitle className="Popup-header p16" id="responsive-dialog-title">
          {currentAddress ? "Edit" : "Add"} Address
          <IconButton
            id="btnAeadClose"
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className="p16">
          <>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} xs={12} sm={6} spacing={2}>
                <Grid item xs={12} sm={12} className="mb-8">
                  <TextField
                    label="Code"
                    size="small"
                    name="addressCode"
                    value={addressData.addressCode}
                    onChange={handleInputChange}
                    variant="outlined"
                    className="w-100"
                    inputProps={{ maxLength: 52 }}
                    id="txtAeadAddressCode"
                  />
                </Grid>
                <Grid item xs={12} sm={12} className="mb-8">
                  <TextField
                    size="small"
                    id="txtAeadCompanyName"
                    label="Company"
                    name="companyName"
                    value={addressData.companyName}
                    onChange={handleInputChange}
                    variant="outlined"
                    error={!!companyNameError}
                    className={`${
                      companyNameError ? "w-100 error-field" : "w-100"
                    }`}
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                      endAdornment: !!companyNameError && (
                        <InputAdornment
                          position="end"
                          disablePointerEvents={true}
                        >
                          <InfoRoundedIcon
                            style={{ color: red[500] }}
                            fontSize="small"
                          ></InfoRoundedIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} className="mb-8">
                  <TextField
                    size="small"
                    label="Address 1"
                    id="txtAeadAddress1"
                    name="address1"
                    value={addressData.address1}
                    onChange={handleInputChange}
                    variant="outlined"
                    error={!!address1Error}
                    className={`${
                      address1Error ? "w-100 error-field" : "w-100"
                    }`}
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                      endAdornment: !!address1Error && (
                        <InputAdornment
                          position="end"
                          disablePointerEvents={true}
                        >
                          <InfoRoundedIcon
                            style={{ color: red[500] }}
                            fontSize="small"
                          ></InfoRoundedIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} className="mb-8">
                  <TextField
                    label="Address 2"
                    name="address2"
                    id="txtAeadAddress2"
                    value={addressData.address2}
                    onChange={handleInputChange}
                    variant="outlined"
                    size="small"
                    error={!!address2Error}
                    className={`${
                      address2Error ? "w-100 error-field" : "w-100"
                    }`}
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                      endAdornment: !!address2Error && (
                        <InputAdornment
                          position="end"
                          disablePointerEvents={true}
                        >
                          <InfoRoundedIcon
                            style={{ color: red[500] }}
                            fontSize="small"
                          ></InfoRoundedIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12} className="mb-8">
                  <TextField
                    id="txtAeadAddress3"
                    label="Address 3"
                    name="address3"
                    value={addressData.address3}
                    onChange={handleInputChange}
                    variant="outlined"
                    size="small"
                    error={!!address3Error}
                    className={`${
                      address3Error ? "w-100 error-field" : "w-100"
                    }`}
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                      endAdornment: !!address3Error && (
                        <InputAdornment
                          position="end"
                          disablePointerEvents={true}
                        >
                          <InfoRoundedIcon
                            style={{ color: red[500] }}
                            fontSize="small"
                          ></InfoRoundedIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12} className="mb-8">
                  {postalCodeError && selectCountryID != "US" ? (
                    <TextField
                      label="City"
                      name="cityName"
                      id="txtAeadCityName"
                      value={addressData.cityName}
                      onChange={handleCityInputChange}
                      variant="outlined"
                      className="w-100"
                      size="small"
                    />
                  ) : (
                    <FormControl size="small" className="w-100">
                      <InputLabel id="demo-select-small-label">City</InputLabel>
                      <Select
                        className="w-100 wrap-txt"
                        labelId="demo-select-small-label"
                        id="ddAeadCityName"
                        value={addressData.cityName} // Ensure this matches your state variable
                        label="City"
                        name="cityName"
                        fullWidth
                        onChange={handleSelectChange}
                      >
                        {zipCodeData.length > 0 &&
                          zipCodeData.map((city: any) => (
                            <MenuItem key={city.cityName} value={city.cityName}>
                              {city.cityName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>

                {/* <Grid item xs={12} md={12} lg={12} spacing={2}>
                  <Grid container lg={12}>
                    <Grid item lg={6} md={6}>
                      <TextField
                        label="State/Province"
                        name="stateProvID"
                        value={addressData.stateProvID}
                        onChange={handleInputChange}
                        variant="outlined"
                        className="w-100"
                        size="small"
                      />
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <TextField
                        label="Postal Code"
                        name="postalCode"
                        value={addressData.postalCode}
                        onChange={handleInputChange}
                        onKeyDown={handleZipCodeKeyDown}
                        onBlur={handleZipCodeBlur}
                        variant="outlined"
                        className="w-100"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Grid> */}

                <Grid item xs={12} md={12} className="mb-8">
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                      <TextField
                        id="txtAeadState"
                        label="State/Province"
                        name="stateProvID"
                        value={addressData.stateProvID}
                        onChange={handleInputChange}
                        variant="outlined"
                        className="w-100"
                        size="small"
                        inputProps={{ maxLength: 3 }}
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        label="Postal Code"
                        name="postalCode"
                        id="txtAeadPostalCode"
                        value={addressData.postalCode}
                        onChange={handleInputChange}
                        onKeyDown={handleZipCodeKeyDown}
                        onBlur={handleZipCodeBlur}
                        variant="outlined"
                        inputProps={{ maxLength: 10 }}
                        size="small"
                        error={
                          !!postalCodeError && addressData.countryID === "US"
                        }
                        className={`${
                          postalCodeError ? "w-100 error-field" : "w-100"
                        }`}
                        InputProps={{
                          endAdornment: !!postalCodeError &&
                            addressData.countryID === "US" && (
                              <InputAdornment
                                position="end"
                                disablePointerEvents={true}
                              >
                                <InfoRoundedIcon
                                  style={{ color: red[500] }}
                                  fontSize="small"
                                ></InfoRoundedIcon>
                              </InputAdornment>
                            ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} className="mb-8">
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                      <FormControl size="small" className="w-100">
                        <InputLabel id="demo-select-small-label">
                          Country
                        </InputLabel>
                        <Select
                          className="w-100 wrap-txt"
                          labelId="demo-select-small-label"
                          id="ddAeadCountry"
                          value={addressData.countryID} // Ensure this matches your state variable
                          label="Country"
                          name="countryID"
                          fullWidth
                          onChange={handleSelectChange}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },

                            PaperProps: {
                              style: menuStyles,
                            },
                          }}
                        >
                          {countryData.length > 0 &&
                            countryData.map((country: any) => (
                              <MenuItem
                                key={country.countryID}
                                value={country.countryID}
                              >
                                {country.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        size="small"
                        label="IATA Airport Code"
                        id="txtAeadIataAirportCode"
                        name="airportID"
                        value={addressData.airportID}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value;
                          const re = /^[a-zA-Z]*$/;
                          if (re.test(value) && value.length <= 3) {
                            handleInputChange(e);
                          }
                        }}
                        variant="outlined"
                        className="w-100"
                        inputProps={{ maxLength: 3 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12} className="mb-8">
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                      <TextField
                        size="small"
                        label="ATTN/See"
                        id="txtAeadAttention"
                        name="attention"
                        value={addressData.attention}
                        onChange={handleInputChange}
                        variant="outlined"
                        className="w-100"
                        inputProps={{ maxLength: 50 }}
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        size="small"
                        label="Phone"
                        name="phoneNumber"
                        id="txtAeadPhoneNumber"
                        value={addressData.phoneNumber}
                        onChange={handleInputChange}
                        variant="outlined"
                        className="w-100"
                        inputProps={{ maxLength: 25 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12} lg={12} spacing={2}>
                  <Grid container lg={12}>
                    <Grid item lg={6} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleCheckboxChange}
                            checked={addressData.groupAvailable}
                            name="groupAvailable"
                            id="cbAeadGroupAvailable"
                          />
                        }
                        label="Available to group?"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Grid item xs={12} md={12} className="mb-8">
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                      <TextField
                        label="Email"
                        size="small"
                        id="txtAeadEmailAddress"
                        name="emailAddress"
                        value={addressData.emailAddress}
                        onChange={handleInputChange}
                        variant="outlined"
                        error={emailValidateError}
                        inputProps={{ maxLength: 100 }}
                        className={`${
                          emailValidateError ? "w-100 error-field" : "w-100"
                        }`}
                        InputProps={{
                          endAdornment: emailValidateError && (
                            <InputAdornment
                              position="end"
                              disablePointerEvents={true}
                            >
                              <InfoRoundedIcon
                                style={{ color: red[500] }}
                                fontSize="small"
                              ></InfoRoundedIcon>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        label="Reference"
                        name="reference"
                        value={addressData.reference}
                        onChange={handleInputChange}
                        variant="outlined"
                        className="w-100"
                        size="small"
                        id="txtAeadReference"
                        inputProps={{ maxLength: 50 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} className="mb-8">
                  <TextField
                    multiline
                    rows={2}
                    name="pickupInstructions"
                    label="Pickup Instructions"
                    value={addressData.pickupInstructions}
                    onChange={handleInputChange}
                    id="txtAeadPickupInstructions"
                    variant="outlined"
                    placeholder=""
                    error={!!pickupInstructionsError}
                    className={`${
                      pickupInstructionsError ? "w-100 error-field" : "w-100"
                    }`}
                    inputProps={{ maxLength: 2000 }}
                    InputProps={{
                      endAdornment: !!pickupInstructionsError && (
                        <InputAdornment
                          position="end"
                          disablePointerEvents={true}
                        >
                          <InfoRoundedIcon
                            style={{ color: red[500] }}
                            fontSize="small"
                          ></InfoRoundedIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} className="mb-8">
                  <TextField
                    multiline
                    rows={2}
                    name="deliveryInstructions"
                    label="Delivery Instructions"
                    value={addressData.deliveryInstructions}
                    onChange={handleInputChange}
                    variant="outlined"
                    placeholder=""
                    id="txtAeadDeliveryInstructions"
                    error={!!deliveryInstructionsError}
                    className={`${
                      deliveryInstructionsError ? "w-100 error-field" : "w-100"
                    }`}
                    inputProps={{ maxLength: 2000 }}
                    InputProps={{
                      endAdornment: !!deliveryInstructionsError && (
                        <InputAdornment
                          position="end"
                          disablePointerEvents={true}
                        >
                          <InfoRoundedIcon
                            style={{ color: red[500] }}
                            fontSize="small"
                          ></InfoRoundedIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} className="mb-8">
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                      <TextField
                        size="small"
                        fullWidth
                        id={`pickupReadyDay`}
                        name={`pickupReadyDay`}
                        label="Ready In (Days)"
                        onChange={handleInputChange}
                        value={addressData.pickupReadyDay}
                        InputProps={{
                          inputProps: { min: 0, maxLength: 4 },
                        }}
                        onKeyDown={handleNumericValue}
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <DatePicker
                        selected={
                          addressData?.pickupReadyValue
                            ? new Date(addressData?.pickupReadyValue)
                            : null
                        }
                        onChange={handleReadyTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        popperClassName="custom-datepicker-popper"
                        id="txtAeadReadyTime"
                        popperPlacement="bottom-start"
                        timeIntervals={15}
                        timeCaption="Ready In (Time)"
                        dateFormat="hh:mm aa"
                        placeholderText="hh:mm AM/PM"
                        className="w-100"
                        customInput={
                          <TextField
                            size="small"
                            label="Ready In (Time)"
                            variant="outlined"
                            className="w-100"
                            value={
                              addressData?.pickupReadyTime
                                ? new Date(addressData?.pickupReadyTime)
                                : null
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AccessTimeIcon
                                    style={{ width: "16px", height: "16px" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{
                              maxLength: 8,
                            }}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} lg={12} className="mb-8">
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                      <TextField
                        size="small"
                        fullWidth
                        id={`deliveryRequestDay`}
                        name={`deliveryRequestDay`}
                        label="Delivery (Days)"
                        onChange={handleInputChange}
                        value={addressData.deliveryRequestDay}
                        InputProps={{
                          inputProps: { min: 0, maxLength: 4 },
                        }}
                        onKeyDown={handleNumericValue}
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <DatePicker
                        selected={
                          addressData?.deliveryRequestTimeValue
                            ? new Date(addressData?.deliveryRequestTimeValue)
                            : null
                        }
                        onChange={handleDelTimeChange}
                        id="txtAeadDeliveryTime"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        popperClassName="custom-datepicker-popper"
                        popperPlacement="bottom-start"
                        timeCaption="Delivery (Time)"
                        dateFormat="hh:mm aa"
                        placeholderText="hh:mm AM/PM"
                        customInput={
                          <TextField
                            size="small"
                            label="Delivery (Time)"
                            variant="outlined"
                            className="w-100"
                            value={
                              addressData?.deliveryRequestTime
                                ? new Date(addressData?.deliveryRequestTime)
                                : null
                            }
                            InputProps={{
                              startAdornment: (
                                // <InputAdornment position="start">
                                //   <IconButton>
                                //     <img
                                //       src={calendarIcon}
                                //       alt="Calendar icon"
                                //       style={{ maxWidth: "100%" }}
                                //     />
                                //   </IconButton>
                                // </InputAdornment>
                                <InputAdornment position="start">
                                  <AccessTimeIcon
                                    style={{ width: "16px", height: "16px" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} className="mb-8">
                  <FormControl size="small" className="w-100">
                    <InputLabel id="demo-select-small-label">
                      Services
                    </InputLabel>

                    <Select
                      variant="outlined"
                      defaultValue=""
                      className="w-100"
                      id="ddAeadServices"
                      value={addressData?.serviceID}
                      name="serviceID"
                      label="Services"
                      onChange={handleSelectChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },

                        PaperProps: {
                          style: menuStyles,
                        },
                      }}
                    >
                      <MenuItem value="All">All</MenuItem>
                      {serviceData.length > 0 &&
                        serviceData.map((service: any) => (
                          <MenuItem
                            key={service.serviceID}
                            value={service.serviceID}
                          >
                            {service.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} className="mb-8">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleCheckboxChange}
                        checked={addressData.dropOff}
                        name="dropOff"
                        id="cbAeadDropOff"
                      />
                    }
                    label="Drop-off"
                  />
                </Grid>
                <Grid item xs={12} sm={12} className="mb-8">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleCheckboxChange}
                        checked={addressData.holdForPickup}
                        name="holdForPickup"
                        id="cbAeadHoldForPickup"
                      />
                    }
                    label="Hold for Pickup"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Box className="pl-16 pr-16 ptb-8 border rounded mt-16">
              <Typography variant="h6" className="info_lbl pb-8">
                Email Notifications
              </Typography>

              <Grid container>
                <Grid item lg={2} md={4} xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="mr-8"
                        onChange={handleCheckboxChange}
                        checked={addressData.notifyOrderAck}
                        name="notifyOrderAck"
                        id="cbAeadAcknowledged"
                      />
                    }
                    label="Acknowledged"
                  />
                </Grid>

                <Grid item lg={2} md={4} xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="mr-8"
                        onChange={handleCheckboxChange}
                        checked={addressData.notifyOrigQdt} //need to verify name
                        name="notifyOrigQdt"
                        id="cbAeadDelTimeSet"
                      />
                    }
                    label="Del Time Set"
                  />
                </Grid>

                <Grid item lg={3} md={4} xs={12} sm={4}>
                  <FormControlLabel
                    className="chkbx_lbl"
                    control={
                      <Checkbox
                        className="mr-8"
                        onChange={handleCheckboxChange}
                        checked={addressData.notifyChangeQdt} //need to verify name
                        name="notifyChangeQdt"
                        id="cbAeadDelTimeChange"
                      />
                    }
                    label="Del Time Change"
                  />
                </Grid>

                <Grid item lg={2} md={4} xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="mr-8"
                        onChange={handleCheckboxChange}
                        checked={addressData.notifyDispatched}
                        name="notifyDispatched"
                        id="cbAeadDispatched"
                      />
                    }
                    label="Dispatched"
                  />
                </Grid>

                <Grid item lg={2} md={4} xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="mr-8"
                        onChange={handleCheckboxChange}
                        checked={addressData.notifyDropped}
                        name="notifyDropped"
                        id="cbAeadDropOff"
                      />
                    }
                    label="Drop off"
                  />
                </Grid>
                <Grid item lg={2} md={4} xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="mr-8"
                        onChange={handleCheckboxChange}
                        checked={addressData.notifyDeparted}
                        name="notifyDeparted"
                        id="cbAeadDeparted"
                      />
                    }
                    label="Departed"
                  />
                </Grid>
                <Grid item lg={2} md={4} xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="mr-8"
                        onChange={handleCheckboxChange}
                        checked={addressData.notifyArrived}
                        name="notifyArrived"
                        id="cbAeadArrived"
                      />
                    }
                    label="Arrived"
                  />
                </Grid>

                <Grid item lg={2} md={4} xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="mr-8"
                        onChange={handleCheckboxChange}
                        checked={addressData.notifyRecovered}
                        name="notifyRecovered"
                        id="cbAeadNotifyRecovered"
                      />
                    }
                    label="Recovered"
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        </DialogContent>

        <DialogActions>
          <Box className="d-flex gap-4">
            <Button
              onClick={handleDialogClose}
              className="MuiButton Action-btn"
              variant="text"
              id="btnAeadCancel"
            >
              CANCEL
            </Button>
            <Button
              style={{ width: 108, height: 40 }}
              variant="contained"
              onClick={handleSave}
              className="MuiButton Action-btn"
              id="btnAeadSave"
            >
              <img className="mr-8" src={uploadicon} alt="Delete icon" />
              SAVE
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddEditAddress;
