import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import useAccounntsLogic from "../../lookup/accounts/accounts.logic";
import { useDecodeJWT } from "../../hook/use-decodeJWT";
import { MySettingItemDto } from "../../../models/long-form-item-dto";
import useReferenceLogic from "../../lookup/reference/reference.logic";
import React from "react";
import { DATETIME_REF_TYPE, DAY_ICE_REF_TYPE } from "../../../config/constants";
import CustomDatePicker from "../CustomDatePicker";
import moment from "moment";
import { formatRefValue, isDisplayDateTime } from "../../util/common";
interface BillingAndReferProps {
  DefaultData: MySettingItemDto;
  onDeliveryDataChange: (updatedData: any) => void;
  setFormErrors: (errors: any) => void;
  formErrors: any;
  setAccountData: (errors: any) => void;
}

interface ReferenceData {
  reference1: any[];
  reference2: any[];
  reference3: any[];
  reference4: any[];
  reference5: any[];
  reference6?: any[];
  reference7?: any[];
  reference8?: any[];
  reference9?: any[];
}

const BillingAndRefer: React.FC<BillingAndReferProps> = ({
  DefaultData,
  onDeliveryDataChange,
  setFormErrors,
  formErrors,
  setAccountData,
}) => {
  const goldPlatinumUserAccount = "6W1259";
  const isUPSPremierAgent =
    localStorage.getItem("isUPSPremierAgent") === "true";
  const [selectAccount, setSelectAccount] = useState(DefaultData.accountNumber);
  const [billToType, setBillToType] = useState(DefaultData.billToType);
  const [accounts, setAccounts] = useState<any>([]);
  const { account, accountForUPS } = useAccounntsLogic();
  const { reference } = useReferenceLogic();
  const token = useDecodeJWT();
  const [references, setReferences] = useState({
    reference1: DefaultData.reference,
    reference2: DefaultData.reference2,
    reference3: DefaultData.reference3,
    reference4: DefaultData.reference4,
    reference5: DefaultData.reference5,
    reference6: DefaultData?.reference6,
    reference7: DefaultData?.reference7,
    reference8: DefaultData?.reference8,
    reference9: DefaultData?.reference9,
    dateTimereference1: null,
    dateTimereference2: null,
    dateTimereference3: null,
    dateTimereference4: null,
    dateTimereference5: null,
    dateTimereference6: null,
    dateTimereference7: null,
    dateTimereference8: null,
    dateTimereference9: null,
  });
  const [referenceData, setReferenceData] = useState<ReferenceData>({
    reference1: [],
    reference2: [],
    reference3: [],
    reference4: [],
    reference5: [],
    reference6: [],
    reference7: [],
    reference8: [],
    reference9: [],
  });

  const servicestyle = {
    maxHeight: "250px", // Default max height for desktop
  };

  const hint = React.useRef("");
  const handleChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;

    if (name === "accountNumber") {
      setSelectAccount(value as any);
      setFormErrors((prevErrors: any) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }

    if (name === "billToType") {
      if (isUPSPremierAgent) {
        setFormErrors((prevErrors: any) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[name];
          return updatedErrors;
        });

        if (value == "A") {
          handleChange({
            target: {
              value: "",
              name: "accountNumber",
            },
          } as SelectChangeEvent<string>);
          onDeliveryDataChange({ ["accountNumber"]: "" });
        }
      }
      setBillToType(value);
    }
    onDeliveryDataChange({ [name]: value });
  };

  const handleInputChange =
    (name: string) => (event: React.ChangeEvent<{}>, newValue: any) => {
      const value = newValue ? newValue.replace("\r", "") : "";
      setReferences((prev) => ({ ...prev, [name]: value }));

      const dateField = `dateTime${name}`;
      const dateValue = references[dateField as keyof typeof references] || "";
      const combineValue = `${dateValue} ${
        dateValue ? `${formatRefValue(value)}` : value
      }`;

      onDeliveryDataChange({ [name]: combineValue });
    };

  useEffect(() => {
    if (!isUPSPremierAgent) {
      getAccounts();
    }
    fetchReferenceData();
    onDeliveryDataChange(DefaultData);
  }, [token.UserId, DefaultData]);

  useEffect(() => {
    if (isUPSPremierAgent) {
      getAccounts();
    }
  }, [billToType]);

  useEffect(() => {
    if (selectAccount && accounts.length > 0) {
      const checkAccount = accounts.find(
        (x: any) => x.accountNumber === selectAccount
      );
      setAccountData(checkAccount);
    }
  }, [selectAccount]);

  const getAccounts = async () => {
    try {
      let response: any;
      if (isUPSPremierAgent) {
        let groupName = "UPS Premier Silver Accounts";
        if (billToType === "G") {
          groupName = "UPS Premier Gold Accounts";
        } else if (billToType === "P") {
          groupName = "UPS Premier Platinum Accounts";
        }
        const param = {
          userId: token.UserId,
          groupName: groupName,
          isBillable: true,
          viewOrders: false,
        };
        response = await accountForUPS(param);
      } else {
        const param = {
          userId: token.UserId,
          companyID: 0,
          useIncludeAllItemrId: false,
        };
        response = await account(param);
      }

      if (response.data && response.data.data) {
        setAccounts(response.data.data);
        const data = response.data.data;
        let _selectedAccount = selectAccount;
        if (billToType == "G" || billToType == "P") {
          const _goldPlatinumAccount = data.find(
            (x: any) => x.accountNumber == goldPlatinumUserAccount
          );
          if (_goldPlatinumAccount) {
            _selectedAccount = _goldPlatinumAccount?.accountNumber;
          } else {
            _selectedAccount = data[0]?.accountNumber;
          }

          setSelectAccount(_selectedAccount);
        }
        const checkAccount = data.find(
          (x: any) => x.accountNumber === _selectedAccount
        );
        setAccountData(checkAccount);
      } else {
        setAccounts([]);
      }
    } catch (err) {
      setAccounts([]);
    }
  };

  const handleReferenceChange =
    (name: string) => (event: React.ChangeEvent<{}>, value: any | null) => {
      const selectedValue =
        value && typeof value === "object" ? value.value : value;
      const newValue = selectedValue ? selectedValue.replace("\r", "") : "";

      setReferences((prev) => ({ ...prev, [name]: newValue }));
      const dateField = `dateTime${name}`;
      const dateValue = references[dateField as keyof typeof references] || "";
      const combineValue = `${dateValue} ${
        dateValue ? `${formatRefValue(newValue)}` : newValue
      }`;

      onDeliveryDataChange({ [name]: combineValue });
    };

  const handleReferenceDateChange = (event: any) => {
    const dateValue = event?.target?.value
      ? moment(event?.target?.value).format("MM/DD/YYYY hh:mm A")
      : null;
    const dateTimeRefField = event?.target?.name;
    const refNumber = dateTimeRefField.slice(-1);
    const refField = `reference${refNumber}`;
    const refValue = references[refField as keyof typeof references] || "";
    const combineValue = `${dateValue} ${
      refValue ? `${formatRefValue(refValue)}` : ""
    }`;
    setReferences((prev) => ({ ...prev, [dateTimeRefField]: dateValue }));
    onDeliveryDataChange({ [refField]: combineValue });
  };

  const fetchReferenceData = () => {
    const referenceGuids = [
      { name: "reference1", guid: DefaultData.referenceGroupGUID },
      { name: "reference2", guid: DefaultData.referenceGroupGUID2 },
      { name: "reference3", guid: DefaultData.referenceGroupGUID3 },
      { name: "reference4", guid: DefaultData.referenceGroupGUID4 },
      { name: "reference5", guid: DefaultData.referenceGroupGUID5 },
      { name: "reference6", guid: DefaultData.referenceGroupGUID6 },
      { name: "reference7", guid: DefaultData.referenceGroupGUID7 },
      { name: "reference8", guid: DefaultData.referenceGroupGUID8 },
      { name: "reference9", guid: DefaultData.referenceGroupGUID9 },
    ];

    referenceGuids.forEach(({ name, guid }) => {
      if (guid) {
        getReference(guid, name);
      }
    });
  };

  const getReference = async (referenceGroupGUID: string, refName: string) => {
    try {
      const param = {
        referenceGroupGUID: referenceGroupGUID,
      };
      const response = await reference(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        setReferenceData((prev: any) => ({
          ...prev,
          [refName]: res,
        }));
      }
    } catch (err) {
      setReferenceData((prev: any) => ({
        ...prev,
        [refName]: [],
      }));
    }
  };

  const referenceLabels = [
    {
      label:
        DefaultData.referenceLabel || DefaultData.referencetypeName || "REFER",
      value: references.reference1,
      name: "reference1",
      data: referenceData.reference1,
      referenceType: DefaultData.referenceTypeID,
    },
    {
      label: DefaultData.referenceLabel2 || DefaultData.referencetypeName2,
      value: references.reference2,
      name: "reference2",
      data: referenceData.reference2,
      referenceType: DefaultData.referenceTypeID2,
    },
    {
      label: DefaultData.referenceLabel3 || DefaultData.referencetypeName3,
      value: references.reference3,
      name: "reference3",
      data: referenceData.reference3,
      referenceType: DefaultData.referenceTypeID3,
    },
    {
      label: DefaultData.referenceLabel4 || DefaultData.referencetypeName4,
      value: references.reference4,
      name: "reference4",
      data: referenceData.reference4,
      referenceType: DefaultData.referenceTypeID4,
    },
    {
      label: DefaultData.referenceLabel5 || DefaultData.referencetypeName5,
      value: references.reference5,
      name: "reference5",
      data: referenceData.reference5,
      referenceType: DefaultData.referenceTypeID5,
    },
    {
      label: DefaultData.referenceLabel6 || DefaultData.referencetypeName6,
      value: references.reference6,
      name: "reference6",
      data: referenceData.reference6,
      referenceType: DefaultData.referenceTypeID6,
    },
    {
      label: DefaultData.referenceLabel7 || DefaultData.referencetypeName7,
      value: references.reference7,
      name: "reference7",
      data: referenceData.reference7,
      referenceType: DefaultData.referenceTypeID7,
    },
    {
      label: DefaultData.referenceLabel8 || DefaultData.referencetypeName8,
      value: references.reference8,
      name: "reference8",
      data: referenceData.reference8,
      referenceType: DefaultData.referenceTypeID8,
    },
    {
      label: DefaultData.referenceLabel9 || DefaultData.referencetypeName9,
      value: references.reference9,
      name: "reference9",
      data: referenceData.reference9,
      referenceType: DefaultData.referenceTypeID9,
    },
  ];

  const validReferences = referenceLabels.filter((ref) => ref.label !== null);
  const renderLabel = (ref: any, index: number): string => {
    return ref.label ?? `Reference ${index + 1}`;
  };

  const getDefaultLable = (ref: any, index: number) => {
    if (ref?.referenceType === DATETIME_REF_TYPE) {
      return "Event Date & Time";
    } else if (DAY_ICE_REF_TYPE.includes(parseInt(ref?.referenceType))) {
      return "Last Tissue On Ice/Recovery End";
    }
    return `Reference ${index + 1}`;
  };
  const handleDateParsing = (
    dateTimeStr: any,
    valueType: any,
    referenceType: any
  ) => {
    if (dateTimeStr && isDisplayDateTime(referenceType, selectAccount)) {
      const [dateTime, restValue] = dateTimeStr?.split("(");

      if (dateTimeStr.includes("(") && valueType === "dateTime" && dateTime) {
        const parsedDate = dateTime ? new Date(dateTime.trim()) : null;
        return parsedDate;
      } else {
        if (valueType === "dateTime") {
          return null;
        }
        const endsWithParenthesis = restValue?.endsWith(")");

        if (endsWithParenthesis) {
          return restValue.slice(0, -1);
        } else {
          return valueType === "dateTime"
            ? restValue
            : restValue || dateTimeStr;
        }
      }
    } else {
      if (dateTimeStr) {
        const startsWithParenthesis = dateTimeStr.startsWith("(");
        const endsWithParenthesis = dateTimeStr?.endsWith(")");

        if (endsWithParenthesis && !startsWithParenthesis) {
          return dateTimeStr.slice(0, -1);
        }
      }

      return dateTimeStr;
    }
  };

  const accountsAutoComplete = useMemo(() => {
    // if (billToType === "G" || billToType === "P") {
    //   const _prefixAccount = {
    //     label: goldPlatinumUserAccount,
    //     id: goldPlatinumUserAccount,
    //   };
    //   return [_prefixAccount];
    // }
    return accounts.map((account: any) => ({
      label: account?.displayName,
      id: account?.accountNumber,
    }));
  }, [accounts, billToType]);

  const isDisbaleAccount = useMemo(() => {
    const isGoldPlatinum = billToType === "G" || billToType === "P";
    if (isGoldPlatinum) {
      let defaultAccount;
      const checkAccount = accounts.find(
        (x: any) => x.accountNumber === goldPlatinumUserAccount
      );
      if (checkAccount) {
        defaultAccount = checkAccount?.accountNumber;
      } else {
        defaultAccount = accounts[0]?.accountNumber;
      }

      handleChange({
        target: {
          value: defaultAccount,
          name: "accountNumber",
        },
      } as SelectChangeEvent<string>);
      onDeliveryDataChange({ ["accountNumber"]: defaultAccount });
    }

    return isGoldPlatinum;
  }, [billToType, accounts]);

  const BillingOptions = useMemo(() => {
    if (isUPSPremierAgent) {
      return [
        {
          value: "P",
          label: "UPS Premier Platinum Accounts",
        },
        {
          value: "G",
          label: "UPS Premier Gold Accounts",
        },
        {
          value: "A",
          label: "UPS Premier Silver Accounts",
        },
      ];
    } else {
      return [
        {
          value: "S",
          label: "SHIPPER",
        },
        {
          value: "C",
          label: "CONSIGNEE",
        },
        {
          value: "3",
          label: "3RD PARTY",
        },
      ];
    }
  }, [isUPSPremierAgent]);

  const selectedBillAcc = useMemo(() => {
    const checkAccount = accounts.find(
      (x: any) => x.accountNumber === selectAccount
    );
    if (checkAccount) {
      return {
        label: checkAccount?.displayName,
        id: checkAccount?.accountNumber,
      };
    }
    if (
      !isDisbaleAccount &&
      isUPSPremierAgent &&
      !checkAccount &&
      selectAccount
    ) {
    }
    return null;
  }, [selectAccount, accounts, isDisbaleAccount]);
  return (
    <Box className="d-flex flex-column border p16 rounded">
      <Typography variant="h6" className="Sub-header">
        Billing & Reference
      </Typography>
      <Typography variant="body1" className="pb-8">
        Billing
      </Typography>
      <Box className="d-flex gap-16 Divider pb-16">
        <Select
          variant="outlined"
          defaultValue={DefaultData.billToType}
          id="ddBillToType"
          className="w-100"
          onChange={handleChange}
          value={billToType}
          name="billToType"
          error={!!formErrors.billToType}
        >
          {BillingOptions.length > 0 &&
            BillingOptions.map((_opt: any) => (
              <MenuItem key={_opt.value} value={_opt.value}>
                {_opt.label}
              </MenuItem>
            ))}
        </Select>
        <FormControl size="medium" fullWidth>
          {!isUPSPremierAgent && (
            <>
              <InputLabel>Select Account</InputLabel>
              <Select
                labelId="demo-select-small-label"
                value={selectAccount}
                id="ddAccount"
                label="Select Account"
                onChange={handleChange}
                name="accountNumber"
                defaultValue={DefaultData.accountNumber}
                fullWidth
                className="w-100"
                error={!!formErrors.accountNumber}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },

                  PaperProps: {
                    style: servicestyle,
                  },
                }}
              >
                {accounts.length > 0 &&
                  accounts.map((account: any) => (
                    <MenuItem
                      key={account.userAccountRowGUID}
                      value={account.accountNumber}
                    >
                      {account.displayName}
                    </MenuItem>
                  ))}
              </Select>
            </>
          )}

          {isUPSPremierAgent && (
            <>
              <Autocomplete
                disabled={isDisbaleAccount && !formErrors.accountNumber}
                readOnly={
                  !!formErrors.accountNumber &&
                  (billToType == "G" || billToType == "P")
                    ? true
                    : false
                }
                disablePortal
                options={accountsAutoComplete}
                getOptionLabel={(option: any) => option.label}
                value={selectedBillAcc}
                id="ddAccount"
                onChange={(event, newValue: any) => {
                  handleChange({
                    target: {
                      name: "accountNumber",
                      value: newValue?.id || "",
                    },
                  } as SelectChangeEvent<string>);
                }}
                className="w-100"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Account"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: !!formErrors.accountNumber
                            ? "#d32f2f"
                            : undefined,
                        },
                        "&:hover fieldset": {
                          borderColor: !!formErrors.accountNumber
                            ? "#d32f2f"
                            : undefined,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: !!formErrors.accountNumber
                            ? "#d32f2f"
                            : undefined,
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: !!formErrors.accountNumber
                          ? "#d32f2f"
                          : undefined,
                      },
                      "& .MuiInputBase-input": {
                        color: !!formErrors.accountNumber
                          ? "#d32f2f"
                          : "inherit",
                      },
                    }}
                  />
                )}
              />
            </>
          )}
        </FormControl>
      </Box>

      <Typography variant="body1" className="mt-16 sub-infolbl">
        References
      </Typography>
      {validReferences.map((ref, index) => (
        <React.Fragment key={ref.name + index}>
          {isDisplayDateTime(ref?.referenceType, selectAccount) && (
            <>
              <Box key={index} className="d-flex mt-12">
                <div className="w-100 mt-16">
                  <CustomDatePicker
                    id={`txtCRYDateTime`}
                    handleDateChange={handleReferenceDateChange}
                    label={
                      renderLabel(ref, index)
                        ? renderLabel(ref, index).replace(/&amp;/g, "&")
                        : getDefaultLable(ref, index)
                    }
                    name={`dateTime${ref.name}`}
                    value={handleDateParsing(
                      references[`${ref.name}` as keyof typeof references] ||
                        null,
                      "dateTime",
                      ref?.referenceType
                    )}
                    // value={
                    //   references[
                    //     `dateTime${ref.name}` as keyof typeof references
                    //   ] || null
                    // } // Ensure non-null value
                    isClearable={true}
                    showTimeSelect={true}
                  />
                </div>
              </Box>
              <Box key={`inputRef${index}`} className="d-flex mt-12">
                <Autocomplete
                  disablePortal
                  id={`txtReferences`}
                  options={ref.data ?? []}
                  className="w-100 mt-16"
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.value
                  }
                  value={handleDateParsing(
                    references[ref.name as keyof typeof references] || "",
                    "",
                    ref?.referenceType
                  )}
                  onChange={handleReferenceChange(ref.name)}
                  onInputChange={handleInputChange(ref.name)}
                  filterOptions={(x) => x} // Disable filtering
                  freeSolo
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <Box sx={{ position: "relative" }}>
                      <Typography
                        sx={{
                          position: "absolute",
                          opacity: 0.5,
                          left: 14,
                          top: 16,
                        }}
                      >
                        {hint.current}
                      </Typography>
                      <TextField
                        {...params}
                        label={"Notes"}
                        inputProps={{
                          ...params.inputProps,
                          maxLength: 100,
                        }}
                      />
                    </Box>
                  )}
                />
              </Box>
            </>
          )}

          {!isDisplayDateTime(ref?.referenceType, selectAccount) && (
            <>
              <Box key={index} className="d-flex mt-12">
                <Autocomplete
                  disablePortal
                  id={`txtReferences`}
                  options={ref.data ?? []}
                  className="w-100 mt-16"
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.value
                  }
                  value={references[ref.name as keyof typeof references] || ""} // Ensure non-null value
                  onChange={handleReferenceChange(ref.name)}
                  onInputChange={handleInputChange(ref.name)}
                  filterOptions={(x) => x} // Disable filtering
                  freeSolo
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <Box sx={{ position: "relative" }}>
                      <Typography
                        sx={{
                          position: "absolute",
                          opacity: 0.5,
                          left: 14,
                          top: 16,
                        }}
                      >
                        {hint.current}
                      </Typography>
                      <TextField
                        {...params}
                        label={
                          renderLabel(ref, index)
                            ? renderLabel(ref, index).replace(/&amp;/g, "&")
                            : `Reference ${index + 1}`
                        }
                        inputProps={{
                          ...params.inputProps,
                          maxLength: 100,
                        }}
                      />
                    </Box>
                  )}
                />
              </Box>
            </>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default BillingAndRefer;
