import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Grid, MenuItem, Select, Typography } from "@mui/material";
import arrowicon from "../../assets/images/logo/img_arrowright_white_a700.svg";
import {
  ContentItemDto,
  MySettingItemDto,
} from "../../models/long-form-item-dto";
import { useDecodeJWT } from "../../shared/hook/use-decodeJWT";
import useServiceLogic from "../../shared/lookup/service/service.logic";
import { Piece } from "../../models/long-form-request-item-dto";

// import useLongFormOrderLogic from "./long-form-order-create.logic";
import Alert from "@mui/material/Alert";

import useMultipleFileUploadLogic from "../../shared/document-upload/multiple-document-upload.logic";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { API_BASE_URL, MAX_COMPANY_ID } from "../../config/constants";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EditPickupAddress from "../../shared/components/edit-will-call-order/EditPickupAddress";
import EditDeliveryAddress from "../../shared/components/edit-will-call-order/EditDeliveryAddress";
import BillingAndRefer from "../../shared/components/edit-will-call-order/BillingAndRefer";
import DeliveryTime from "../../shared/components/edit-will-call-order/DeliveryTime";
import Shipper from "../../shared/components/edit-will-call-order/Shipper";
import PackageInfo from "../../shared/components/edit-will-call-order/PackageInfo";
import DangerousGoods from "../../shared/components/edit-will-call-order/DangerousGoods";
import Notifications from "../../shared/components/edit-will-call-order/Notifications";
import Documents from "../../shared/components/edit-will-call-order/Documents";
import ReadyTime from "../../shared/components/edit-will-call-order/ReadyTime";
import { EditWillCallRequestDTO } from "../../models/edit-will-call-order-request-item-dto";
import ActiveOrderDialog from "../../shared/components/active-order-dialog/active-order-dialog";
import { ShipmentPieceDto } from "../../models/shipment-piece-dto";
import useUpdateShipmentPieceLogic from "../../shared/lookup/shipment-piece/update-shipment-piece/update-shipment-piece.logic";
import axiosInstance from "../../config/axios-interceptor";
import useWillCallLogic from "../will-call-orders/will-call-orders.logic";
import { formatDateToLocal } from "../../shared/util/date-utils";
import useDefaultData1Logic from "./edit-will-call-order-logic";
import useDefaultDataLogic from "../long-form-orders/long-form-order.logic";
import useInsertShipmentPieceLogic from "../../shared/lookup/shipment-piece/insert-shipment-piece/insert-shipment-piece.logic";
import useGetShipmentPieceLogic from "../../shared/lookup/shipment-piece/get-shipment-piece/get-shipment-piece.logic";
import Attributes from "../../shared/components/long-form-order/Attributes";
import { getCurrentDateTimeByAbbr } from "../../shared/util/timezones";
import moment from "moment";

const EditWillCallOrder: React.FC = () => {
  const isUPSPremierAgent =
    localStorage.getItem("isUPSPremierAgent") === "true";
  //const [isLoading, setIsLoading] = useState(false);
  //const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  //const [isSubmitted, setIsSubmitted] = useState(false);
  const [open, setOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [mySettingsData, setMySettingsData] = useState<MySettingItemDto>();
  //const [selectedServiceID, setSelectedServiceID] = useState<string>("");
  const serviceAvailType = localStorage.getItem("serviceAvailType");
  const [formErrors, setFormErrors] = useState<any>({});
  const [willCallSubmitError, setWillCallSubmitError] = useState<any>();
  const [fileUploadData, setFileUploadData] = useState<any[]>([]);
  const [pickupZipCodeError, SetPickUpZipCodeError] = useState<boolean>(false);
  const [deliveryZipCodeError, SetDeliveryZipCodeError] =
    useState<boolean>(false);
  const [unError, setUNError] = useState<boolean>(false);
  const errorRef: any = useRef(null);
  const [serviceData, setServiceData] = useState<any>([]);
  const [selectedContent, setSelectedContent] = useState<ContentItemDto>();
  const token = useDecodeJWT();
  const { service } = useServiceLogic();
  const [piecesData, setPiecesData] = useState<Piece[]>([]);
  const [dangerousGoodData, setDangerousGoodData] = useState<any>({});
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});
  const { defaultData1, editWillCallOrder, deleteDocument } =
    useDefaultData1Logic();
  const { defaultData } = useDefaultDataLogic();

  const { multipleFileUpload } = useMultipleFileUploadLogic();
  const { updateShipmentPiece } = useUpdateShipmentPieceLogic();
  const { insertShipmentPiece } = useInsertShipmentPieceLogic();
  const { getShipmentPiece } = useGetShipmentPieceLogic();
  const { willCallOrder } = useWillCallLogic();
  const [editWillCallOrderData, setEditWillCallOrderData] =
    useState<EditWillCallRequestDTO>({});
  const [pickupReadyTimeData, setPickupReadyTimeData] = useState<any>();
  const [readyTimeData, setReadyTimeData] = useState<any>();
  const [isDisplayAttributes, setIsDisplayAttributes] =
    useState<boolean>(false);
  const shpUseOrderNumAsTrackNum: any = localStorage.getItem(
    "UseOrderNumAsTrackNum"
  );

  const [servicesDependancyFinished, setServicesDependancyFinished] = useState({
    settingsAPI: false,
    pickUpPostalCode: true,
    deliveryPostalCode: true,
    willCallAPI: false,
  });

  const [editablePiecesData, setEditablePiecesData] = useState<
    ShipmentPieceDto[]
  >([]);
  const [allPiecesData, setAllPiecesData] = useState<ShipmentPieceDto[]>([]);
  const [filesToRemove, setAllFilesToRemove] = useState<[]>([]);
  const onContentChange = (content: any) => {
    const newData = {
      ...content,
    };

    setSelectedContent((prevData: any) => ({
      ...prevData,
      ...newData,
    }));

    // setDangerousGoodData((prevData: any) => ({
    //   ...prevData,
    //   dgProperName: newData.properName,
    //   dgUNNumber: newData.unNumber,
    //   dgIsRadioactive: newData.isRadioactive,
    //   isDangerousGoods: newData.isDangerousGoods,
    //   dgCommodityClass: newData.commodityClass,
    // }));
    setEditWillCallOrderData((prevData: any) => ({
      ...prevData,
      dgProperName: newData.properName,
      dgUNNumber: newData.unNumber,
      dgIsRadioactive: newData.isRadioactive,
      isDangerousGoods: newData.isDangerousGoods,
      dgCommodityClass: newData.commodityClass,
    }));
  };

  const handlePickUpDataChange = (updatedData: any) => {
    const newData = {
      ...updatedData,
    };
    setEditWillCallOrderData((prevData: any) => ({
      ...prevData,
      ...newData,
    }));
  };

  const handleDelDataChange = (updatedData: any) => {
    const newData = {
      ...updatedData,
    };
    if (serviceAvailType === "U") {
      if (newData.hasOwnProperty("serviceGUID")) {
        handleDeliveryServiceChange(newData.serviceGUID);
      }
    }
    if (serviceAvailType === "A") {
      if (newData.hasOwnProperty("companyServiceGUID")) {
        handleDeliveryServiceChange(newData.companyServiceGUID);
      }
    }
    setEditWillCallOrderData((prevData: any) => ({
      ...prevData,
      ...newData,
    }));
  };

  const handleDeliveryDataChange = (updatedData: any) => {
    // const newData = {
    //   ...updatedData,
    // };
    // setEditWillCallOrderData((prevData: any) => ({
    //   ...prevData,
    //   ...newData,
    // }));
  };

  const handleAttChange = (attList: any) => {
    const attributeList = attList?.attributeList;
    const attributeTypeID = attList?.attributeTypeID;
    setEditWillCallOrderData((prevData) => ({
      ...prevData,
      attributeTypeID:
        attributeTypeID && attributeTypeID.length > 0
          ? attributeTypeID.join(",")
          : "",
      attributeList:
        attributeList && attributeList.length > 0 ? attributeList : [],
    }));
  };

  const handleDocumentDataChange = (updatedData: any) => {
    const newData = {
      ...updatedData,
    };
    setFileUploadData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  const handleDangerousGoodDataChange = (updatedData: any) => {
    const newData = {
      ...updatedData,
    };
    setDangerousGoodData((prevData: any) => ({
      ...prevData,
      ...newData,
    }));

    setEditWillCallOrderData((prevData: any) => ({
      ...prevData,
      ...newData,
    }));
  };

  const handleServiceChange = (e: any) => {
    const serviceID = e.target.value;
    //setSelectedServiceID(serviceID);

    setFormErrors((prevErrors: any) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors["serviceID"];
      return updatedErrors;
    });
    setEditWillCallOrderData((prevData) => ({
      ...prevData,
      //serviceGUID: serviceID,
      serviceID: serviceID,
      //serviceGUIDAndDeadlineCode: serviceID,
    }));
  };

  const handleDeliveryServiceChange = (value: string) => {
    const serviceID = value;
    //setSelectedServiceID(serviceID || "");
    const findServiceCode = serviceData.find(
      (x: any) => x.serviceGUID === serviceID
    );
    setFormErrors((prevErrors: any) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors["serviceGUID"];
      return updatedErrors;
    });
    setEditWillCallOrderData((prevLongFormData) => ({
      ...prevLongFormData,
      serviceGUID: serviceID,
      serviceName: findServiceCode?.name || "",
      serviceID: findServiceCode?.serviceID || null,
    }));
  };

  const handleUNNumberDataChange = (data: any) => {
    if (data.length > 0) {
      setUNError(false);
    } else {
      setUNError(true);
    }
  };

  const handlePickupZipCodeDataChange = (data: any) => {
    SetPickUpZipCodeError(false);
    if (data.length > 0) {
      SetPickUpZipCodeError(false);
    }
    if (data.length === 0 && editWillCallOrderData.puPostalCode !== "") {
      SetPickUpZipCodeError(true);
    }
  };

  const handleDeliveryZipCodeDataChange = (data: any) => {
    SetDeliveryZipCodeError(false);
    if (data.length > 0) {
      SetDeliveryZipCodeError(false);
    }
    if (data.length === 0 && editWillCallOrderData.delPostalCode !== "") {
      SetDeliveryZipCodeError(true);
    }
  };

  useEffect(() => {
    getMysetting();
    // getData();
  }, []);

  useEffect(() => {
    if (mySettingsData) {
      // getService();
      getData();
    }
  }, [mySettingsData]);

  useEffect(() => {
    //areAllServicesDepFinished:: Needed for stop the multiple api call onLoad
    const areAllServicesDepFinished = Object.values(
      servicesDependancyFinished
    ).every((value) => value === true);

    if (areAllServicesDepFinished) {
      getService();
    }
  }, [
    servicesDependancyFinished,
    editWillCallOrderData.puCountryID,
    editWillCallOrderData.puCityName,
    editWillCallOrderData.puStateProvID,
    editWillCallOrderData.puPostalCode,
    editWillCallOrderData.puAirportID,
    editWillCallOrderData.delCountryID,
    editWillCallOrderData.delCityName,
    editWillCallOrderData.delStateProvID,
    editWillCallOrderData.delPostalCode,
    editWillCallOrderData.delAirportID,
  ]);

  const getService = async () => {
    try {
      const param: any = {
        companyID: MAX_COMPANY_ID,
        userGUID: token.UserGUID,
        serviceAvailType: serviceAvailType,
        baseDateTime: new Date(),
        origCountryID: editWillCallOrderData.puCountryID,
        origCityName: editWillCallOrderData.puCityName,
        origStateProvID: editWillCallOrderData.puStateProvID,
        origPostalCode: editWillCallOrderData.puPostalCode,
        origAirportID: editWillCallOrderData.puAirportID,
        destCountryID: editWillCallOrderData.delCountryID,
        destCityName: editWillCallOrderData.delCityName,
        destStateProvID: editWillCallOrderData.delStateProvID,
        destPostalCode: editWillCallOrderData.delPostalCode,
        destAirportID: editWillCallOrderData.delAirportID,
      };

      const response = await service(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        setServiceData(res);
        // getData();
      } else {
        setServiceData([]);
        // getData();
      }
    } catch {
      setServiceData([]);
    }
  };

  const getMysetting = async () => {
    try {
      const param: any = {
        companyID: MAX_COMPANY_ID,
      };
      const response = await defaultData(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        setMySettingsData(res);
        setServicesDependancyFinished((_prev: any) => ({
          ..._prev,
          settingsAPI: true,
        }));
      }
    } catch (err) {
      setMySettingsData(undefined);
    }
  };

  const getData = async () => {
    const urlParams = new URLSearchParams(window.location.search);

    const shipmentGUID = urlParams.get("ShipmentGUID");
    try {
      const param = {
        shipmentGUID: shipmentGUID,
      };
      const response = await defaultData1(param);

      if (response.data && response.data.data) {
        const res = response.data.data;
        const updatedData = {
          ...res,
          pickupReadyTime: res?.puReadyTime,
          deliveryRequestTime: res?.delRequestTime,
          referenceLabel: mySettingsData?.referenceLabel,
          referenceLabel2: mySettingsData?.referenceLabel2,
          referenceLabel3: mySettingsData?.referenceLabel3,
          referenceLabel4: mySettingsData?.referenceLabel4,
          referenceLabel5: mySettingsData?.referenceLabel5,
          referenceLabel6: mySettingsData?.referenceLabel6,
          referenceLabel7: mySettingsData?.referenceLabel7,
          referenceLabel8: mySettingsData?.referenceLabel8,
          referenceLabel9: mySettingsData?.referenceLabel9,
          referencetypeName: mySettingsData?.referencetypeName,
          referencetypeName2: mySettingsData?.referencetypeName2,
          referencetypeName3: mySettingsData?.referencetypeName3,
          referencetypeName4: mySettingsData?.referencetypeName4,
          referencetypeName5: mySettingsData?.referencetypeName5,
          referencetypeName6: mySettingsData?.referencetypeName6,
          referencetypeName7: mySettingsData?.referencetypeName7,
          referencetypeName8: mySettingsData?.referencetypeName8,
          referencetypeName9: mySettingsData?.referencetypeName9,
          referenceGroupGUID: mySettingsData?.referenceGroupGUID,
          referenceGroupGUID2: null,
          referenceGroupGUID3: null,
          referenceGroupGUID4: null,
          referenceGroupGUID5: null,
          referenceGroupGUID6: null,
          referenceGroupGUID7: null,
          referenceGroupGUID8: null,
          referenceGroupGUID9: null,
        };
        setEditWillCallOrderData(updatedData);
        setServicesDependancyFinished((_prev: any) => ({
          ..._prev,
          willCallAPI: true,
        }));
      }
    } catch (err) {
      setEditWillCallOrderData("");
    }
  };
  const scrollToTop = () => {
    errorRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      top: 50,
    });
  };

  const validate = () => {
    const newErrors: any = {};
    if (editWillCallOrderData.puReadyNow === false) {
      if (!editWillCallOrderData?.puReadyTime) {
        newErrors.pickupReadyTime = "Please specify a Shipment Ready Time.";
      } else {
        const selectedDateTime = moment(
          editWillCallOrderData?.puReadyTime
        ).format("YYYY-MM-DD HH:mm:ss");
        const currentDateTime = getCurrentDateTimeByAbbr(
          editWillCallOrderData?.puStandardAbbr
        );
        if (selectedDateTime < currentDateTime) {
          newErrors.pickupReadyTime = "The ready time cannot be in the past.";
        }
      }
    }

    if (editWillCallOrderData.delRequestAsap === false) {
      if (!editWillCallOrderData.deliveryRequestTime) {
        newErrors.delRequestDate = "Please specify a Requested Delivery Time.";
      } else {
        const selectedDateTime = new Date(
          editWillCallOrderData?.deliveryRequestTime
        );
        const currentDateTime = new Date();
        if (selectedDateTime < currentDateTime) {
          newErrors.deliveryRequestTime =
            "The delivery time cannot be in the past.";
        }
      }
    }

    if (
      (isUPSPremierAgent && !editWillCallOrderData.billToType) ||
      (isUPSPremierAgent &&
        !["P", "G", "A"].includes(editWillCallOrderData.billToType as any))
    ) {
      newErrors.billToType =
        "Please select the appropriate UPS Account Tier and Account Number.";
    }

    if (!editWillCallOrderData.delCompanyName) {
      newErrors.delCompanyName = "Please enter a Delivery Company Name.";
    }
    if (!editWillCallOrderData.puCompanyName) {
      newErrors.puCompanyName = "Please enter a Pickup Company Name.";
    }
    if (!editWillCallOrderData.puAirportID) {
      newErrors.puAirportID = "Please enter a Pickup Airport.";
    }
    if (!editWillCallOrderData.delAirportID) {
      newErrors.delAirportID = "Please enter a Delivery Airport.";
    }
    if (!editWillCallOrderData.puCityName) {
      newErrors.puCityName = "Please select a Pickup City.";
    }
    if (!editWillCallOrderData.puPostalCode) {
      newErrors.puPostalCode = "Please enter a Pickup ZIP Code.";
    }
    if (!editWillCallOrderData.delPostalCode) {
      newErrors.delPostalCode = "Please enter a Delivery ZIP Code.";
    }
    if (!editWillCallOrderData.delCityName) {
      newErrors.delCityName = "Please select a Delivery City.";
    }

    if (!editWillCallOrderData.serviceID) {
      newErrors.serviceID = "Please specify a Shipment Service Level.";
    }

    if (!editWillCallOrderData.weight) {
      newErrors.weight = "Please enter a package weight.";
    }

    if (!editWillCallOrderData.commodity) {
      newErrors.commodity = "Please select a Commodity.";
    }
    if (!editWillCallOrderData.accountNumber) {
      newErrors.accountNumber = "Please select a Account.";
    }

    if (
      (editWillCallOrderData.textNotifyPickup ||
        editWillCallOrderData.textNotifyDelivery) &&
      !editWillCallOrderData.textPhoneNumber
    ) {
      newErrors.textPhoneNumber =
        "Please enter a Phone Number for text notifications.";
    }
    if (!editWillCallOrderData.shipperName) {
      newErrors.shipperName = "Please enter a Shipper Name.";
    }
    if (!editWillCallOrderData.shipperEmail) {
      newErrors.shipperEmail = "Please enter a Shipper Email Address.";
    }

    if (allPiecesData && Array.isArray(allPiecesData)) {
      allPiecesData.forEach((piece: any, index: number) => {
        if (!piece.weight) {
          newErrors[
            `allPiecesData[${index}].weight`
          ] = `Please enter Weight for Piece ${index + 1}.`;
        }
      });
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      scrollToTop();
      return;
    }
    return (
      Object.keys(newErrors).length === 0 ||
      !pickupZipCodeError ||
      !deliveryZipCodeError ||
      unError
    );
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
  ) => {
    const target = e.target as HTMLInputElement;
    const { name, type, value, checked } = target;

    if (name === "delRequestAsap") {
      setEditWillCallOrderData((prevData: any) => ({
        ...prevData,
        deliveryRequestTime: "",
        [name]: type === "checkbox" ? checked : value,
      }));
    } else if (name === "pickupReadyTime") {
      setEditWillCallOrderData((prevData: any) => ({
        ...prevData,
        puReadyTime: value,
        [name]: type === "checkbox" ? checked : value,
      }));
    } else if (name === "deliveryRequestTime") {
      setEditWillCallOrderData((prevData: any) => ({
        ...prevData,
        delRequestTime: value,
        [name]: type === "checkbox" ? checked : value,
      }));
    } else {
      setEditWillCallOrderData((prevData: any) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }

    // Clear error related to the field being updated
    setErrors((prevErrors: any) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name];
      return newErrors;
    });
  };

  // interface MenuStyles {
  //   maxHeight: string;
  // }

  // const [menuStyles, setMenuStyles] = useState<MenuStyles>({
  //   maxHeight: "200px", // Default max height for desktop
  // });
  const menuStyles = {
    maxHeight: "200px", // Default max height for desktop
  };

  const handleRequestDeliveryTimeChange = (data: any) => {
    setReadyTimeData(data);
  };

  const handlePickupDeliveryTimeChange = (data: any) => {
    setPickupReadyTimeData(data);
  };

  const deleteAllReplacedFiles = async () => {
    const deletePromises = filesToRemove.map(async (shipmentDocumentID) => {
      const param = {
        shipmentDocumentId: shipmentDocumentID,
      };

      // Await the deletion of each document
      await deleteDocument(param);
    });

    // Wait for all deletion promises to complete
    await Promise.all(deletePromises);
    setAllFilesToRemove([]);
  };

  const handleFileUpload = async (data: any) => {
    try {
      const response = await multipleFileUpload(data);
      if (response) {
        // const res = response.data.data;
        return { success: true };
      }
    } catch (err) {
      return { success: false };
    }
  };

  const handleUploadAllFiles = async (
    filesToSend: any,
    type: any,
    shipmentGUID: any
  ) => {
    //const url = `/track-order?ShipmentGUID=${shipmentGUID}`;

    const results = await Promise.all(
      filesToSend.map(async (file: any) => {
        if (file) {
          const fileParam = {
            shipmentGUID: shipmentGUID,
            insertUserID: token.UserId,
            files: [file], // Send the current file
          };
          return handleFileUpload(fileParam); // Call upload for each file
        } else {
          return { success: false };
        }
      })
    );

    // Handle the results of all file uploads
    const successFiles = results
      ? results?.filter((result) => result?.success)
      : [];
    const failedFiles = results
      ? results?.filter((result) => !result.success)
      : [];

    if (successFiles?.length > 0 || failedFiles?.length > 0) {
      //const successMessage = `${successFiles.length} Documents uploaded successfully!`;
      // if (type === "newOrder") {
      //   window.location.reload();
      // } else {
      //   navigate(url);
      // }
    }

    // if (failedFiles.length > 0) {
    //   const errorMessage = `${failedFiles.length} Documents failed to upload.`;
    // }
  };

  const updateShipmentPieceData = async (pieceData: ShipmentPieceDto) => {
    try {
      const urlParams = new URLSearchParams(window.location.search);

      const shipmentGUID = urlParams.get("ShipmentGUID");
      let param = pieceData;
      if (pieceData?.shipmentPieceID) {
        await updateShipmentPiece(param);
      } else {
        param = {
          ...param,
          shipmentGUID: shipmentGUID,
        };
        await insertShipmentPiece(param);
      }

      // Handle response if needed
    } catch (err) {}
  };

  const handleSubmit = async (actionType: string) => {
    try {
      setSuccessMessage("");
      if (validate()) {
        try {
          let piecesList = allPiecesData;
          const totalQuantity = allPiecesData?.reduce(
            (sum: number, piece: any) => {
              const quantity =
                parseFloat(piece?.quantity as unknown as string) || 0;
              return sum + quantity;
            },
            0
          );
          const totalWeight = allPiecesData?.reduce(
            (sum: number, piece: any) => {
              const quantity =
                parseFloat(piece?.weight as unknown as string) || 0;
              return sum + quantity;
            },
            0
          );

          const updatedPiecesList = allPiecesData?.map((piece: any) => {
            // Ensure contents are trimmed to 50 characters
            // Convert length, width, and height to numbers if they're strings
            if (typeof piece.length === "string") {
              piece.length = Number(piece.length);
            }
            if (typeof piece.width === "string") {
              piece.width = Number(piece.width);
            }
            if (typeof piece.height === "string") {
              piece.height = Number(piece.height);
            }
            if (typeof piece.quantity === "string") {
              piece.quantity = Number(piece.quantity);
            }
            return piece;
          });

          const editablePiecesData = allPiecesData?.filter(
            (piece) => piece.isEdit
          );
          if (
            allPiecesData &&
            allPiecesData?.length > 1 &&
            editablePiecesData &&
            editablePiecesData.length > 0
          ) {
            await Promise.all(
              editablePiecesData.map(async (pieceData) => {
                try {
                  await updateShipmentPieceData(pieceData);
                } catch (pieceErr) {}
              })
            );

            const isInsertPieceData = allPiecesData.find(
              (_item: any) => !_item?.shipmentPieceID
            );
            if (isInsertPieceData) {
              //Get all the pieces data after updating/insert (Needed for insert case)
              const param = {
                shipmentGUID: editWillCallOrderData?.shipmentGUID,
              };
              const piecesResponse = await getShipmentPiece(param as any);
              if (
                piecesResponse.data &&
                piecesResponse.data.data &&
                piecesResponse.data.data.length > 0
              ) {
                const updatedPiecesData = piecesResponse.data.data.map(
                  (piece: any) => ({
                    ...piece,
                    height: piece.height === 0 ? null : piece.height, // Update height to null if it is 0
                    weight: piece.weight === 0 ? null : piece.weight, // Update weight to null if it is 0
                    width: piece.weight === 0 ? null : piece.width, // Update weight to null if it is 0
                    length: piece.weight === 0 ? null : piece.length, // Update weight to null if it is 0
                    declaredValue:
                      piece.weight === 0 ? null : piece.declaredValue, // Update weight to null if it is 0
                  })
                );
                setAllPiecesData(updatedPiecesData);
                piecesList = updatedPiecesData;
              }
            }
          }
          const { serviceGUID, serviceGUIDAndDeadlineCode, ...requestParms } =
            editWillCallOrderData;

          const pickupDate = formatDateToLocal(
            editWillCallOrderData?.pickupReadyTime as any
          );
          const deliveryDate = formatDateToLocal(
            editWillCallOrderData?.deliveryRequestTime
          );
          const params = {
            ...requestParms,
            tmsType: "PLTMS",
            puReadyTime: pickupDate,
            puReadyDateTime: pickupDate,
            puReadyDate: pickupDate,
            delRequestTime: deliveryDate,
            delRequestDate: deliveryDate,
            weight: totalWeight,
            pieces: allPiecesData?.length || 1,
            length:
              updatedPiecesList?.length > 0 ? updatedPiecesList[0].length : 0,
            quantity: totalQuantity,
            height:
              updatedPiecesList?.length > 0 ? updatedPiecesList[0].height : 0,
            width:
              updatedPiecesList?.length > 0 ? updatedPiecesList[0].width : 0,
            weightUOM:
              updatedPiecesList?.length > 0
                ? updatedPiecesList[0].weightUOM
                : "",
            dimWeight:
              updatedPiecesList?.length > 0
                ? updatedPiecesList[0].dimWeight || 0
                : 0,
            contents:
              updatedPiecesList?.length > 0
                ? updatedPiecesList[0].contents
                : "",
            reference: editWillCallOrderData?.reference1,
            commodity:
              updatedPiecesList?.length > 0
                ? updatedPiecesList[0].commodity
                : "",
            piecesList: piecesList && piecesList.length > 1 ? piecesList : [],
            useOrderNumAsTrackNum:
              shpUseOrderNumAsTrackNum == "true" ? true : false,
            dgDryIceWeight:
              editWillCallOrderData.dgDryIceWeight > 0
                ? editWillCallOrderData.dgDryIceWeight
                : null,
          };
          if (!isDisplayAttributes) {
            params.attributeTypeID = "";
          }
          const response = await editWillCallOrder(params);
          if (response.data) {
            //const res = response.data;
            // setOpen(true);

            const filesToSend = Object.values(fileUploadData).map(
              (item) => item.file
            );
            // let param = {
            //   files: filesToSend,
            //   shipmentGUID: res.data.shipmentGUID,
            //   insertUserID: token.UserId,
            // };
            // const url = `/track-order?ShipmentGUID=${res.data.shipmentGUID}`;
            if (filesToSend.length > 0) {
              handleUploadAllFiles(
                filesToSend,
                actionType,
                editWillCallOrderData.shipmentGUID
              );
              deleteAllReplacedFiles();
            } else {
              // if (type === "newOrder") {
              //   window.location.reload();
              // } else {
              //   navigate(url);
              // }
            }

            if (actionType === "activateOrder") {
              handleActivate();
            } else {
              setSuccessMessage("Will Call Order Saved Successfully.");
              errorRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
                top: 50,
              });
            }
          }
          if (!response.success) {
            setWillCallSubmitError(response.message);
          } else {
            setWillCallSubmitError("");
          }
        } catch {}
      } else {
        scrollToTop();
      }
    } catch {}
  };

  const formatDate = (date: any) => {
    if (!date) return null;
    return new Date(date).toISOString();
  };
  const handleActivate = async () => {
    if (validate()) {
      if (
        window.confirm(
          "Please check your pieces, dimensions, weight, service code, Commodity, ready time and delivery time before activation."
        )
      ) {
        try {
          const param = {
            shipmentguid: editWillCallOrderData.shipmentGUID,
          };
          const response = await willCallOrder(param as any);
          if (response.data) {
            const currentDateTime = response.data.currentDateTime;
            // Convert the date string to a Date object
            const dateObj = new Date(currentDateTime);
            // Format the date to YYYY-MM-DDTHH:MM
            const formattedDate = dateObj
              .toISOString()
              .replace(/T/, " ")
              .substring(0, 16);
            let deliveryRequestDate =
              editWillCallOrderData?.deliveryRequestTime;
            if (!deliveryRequestDate) {
              deliveryRequestDate = editWillCallOrderData?.delRequestTime;
            }
            const param1 = {
              shipmentGUID: editWillCallOrderData?.shipmentGUID,
              pickupReadyDate: formatDate(formattedDate),
              pickupReadyNow: false,
              deliverWhen: editWillCallOrderData?.deliverWhen,
              deliveryRequestDate: formatDate(deliveryRequestDate),
              deliveryRequestAsap: editWillCallOrderData?.delRequestAsap,
            };

            const response1 = await axiosInstance.post(
              `${API_BASE_URL}/api/Orders/WillCAllOrder/Activate`,
              param1
            );

            if (response1.status === 200) {
              navigate("/active-orders");
            }
          }
        } catch {}
      }
    }
  };
  const handleDialogClose = (isReload: boolean) => {
    setOpen(false);
    if (isReload) {
      //setIsLoading(true);
    }
  };
  const isAlert = false;

  return (
    <>
      {/* main content section */}
      <div className="order-form">
        <Box className="d-flex flex-column w-100 create-Order-form">
          <Box className="header-container" ref={errorRef}>
            <Typography
              variant="h4"
              color="primary"
              className="d-flex font48 main-header"
            >
              Will Call · #{editWillCallOrderData.trackingNumber ?? editWillCallOrderData.shipmentNumber}
            </Typography>
            {/* <Button variant="outlined" startIcon={<NotificationsIcon />}>
              Notify
            </Button> */}
          </Box>
          {successMessage && (
            <Box className="w-100">
              <Alert variant="filled" severity="success" id="txtSuccess">
                <div>{successMessage}</div>
              </Alert>
            </Box>
          )}
          {(Object.keys(errors).length > 0 ||
            pickupZipCodeError ||
            willCallSubmitError ||
            unError ||
            deliveryZipCodeError) && (
            <Box className="w-100 mt-24 mb-24">
              <Alert variant="filled" severity="error" id="txtErrorMessage">
                {Object.keys(errors).map((key) => (
                  <div key={key}>{errors[key]}</div>
                ))}
                {pickupZipCodeError && (
                  <div>
                    You have entered an invalid Pickup Zip Code. Please ensure
                    you have entered the proper Zip Code. If you continue to
                    experience issues, please contact MNX.
                  </div>
                )}
                {deliveryZipCodeError && (
                  <div>
                    You have entered an invalid Delivery Zip Code. Please ensure
                    you have entered the proper Zip Code. If you continue to
                    experience issues, please contact MNX.
                  </div>
                )}
                {willCallSubmitError && <div>{willCallSubmitError}</div>}
                {unError && <div>Invalid UN# specified.</div>}
              </Alert>
            </Box>
          )}
          {editWillCallOrderData.shipmentGUID && (
            <Box className="d-flex flex-column align-items-start">
              <Typography
                variant="h5"
                color="primary"
                className="pickup_section pb-16"
              >
                1. Pickup & Delivery
              </Typography>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ReadyTime
                    editWillCallOrderData={editWillCallOrderData}
                    handleInputChange={handleInputChange}
                    formErrors={errors}
                    onDeliveryDataChange={handleDeliveryDataChange}
                    readyTimeData={pickupReadyTimeData}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <DeliveryTime
                    editWillCallOrderData={editWillCallOrderData}
                    handleInputChange={handleInputChange}
                    deliveryData={editWillCallOrderData}
                    onDeliveryDataChange={handleDeliveryDataChange}
                    formErrors={errors}
                    setFormErrors={setFormErrors} // Pass setFormErrors as a prop
                    readyTimeData={readyTimeData}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12} className="mt-16">
                  <EditPickupAddress
                    onPickUpDataChange={handlePickUpDataChange}
                    formErrors={errors}
                    pickupZipCodeError={pickupZipCodeError}
                    editWillCallOrderData={editWillCallOrderData}
                    handleInputChange={handleInputChange}
                    onZipCodeDataChange={handlePickupZipCodeDataChange}
                    setFormErrors={setFormErrors} // Pass setFormErrors as a prop
                    onRequestTimeChange={
                      handlePickupDeliveryTimeChange ||
                      handleRequestDeliveryTimeChange
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} className="mt-16">
                  <EditDeliveryAddress
                    formErrors={errors}
                    editWillCallOrderData={editWillCallOrderData}
                    deliveryZipCodeError={deliveryZipCodeError}
                    handleInputChange={handleInputChange}
                    onZipCodeDataChange={handleDeliveryZipCodeDataChange}
                    setFormErrors={setFormErrors} // Pass setFormErrors as a prop
                    onDeliveryDataChange={handleDelDataChange}
                    onRequestTimeChange={handleRequestDeliveryTimeChange}
                    setErrors={setErrors}
                  />
                </Grid>
              </Grid>

              {/* shipment timing section */}
              <Box className="w-100 Divider mt-40 mb-40"></Box>

              <Typography
                variant="h5"
                color="primary"
                className="ml-8 mt-40 mb-24"
              >
                2. Billing & Service
              </Typography>

              <Grid container lg={12} spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <BillingAndRefer
                    editWillCallOrderData={editWillCallOrderData}
                    handleInputChange={handleInputChange}
                    formErrors={errors}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Box className="d-flex flex-column border p16 rounded">
                    <Typography variant="h6" className="Sub-header">
                      Select a Service
                    </Typography>

                    <Select
                      variant="outlined"
                      id="ddService"
                      name="serviceID"
                      value={editWillCallOrderData.serviceID}
                      error={!!errors.serviceID}
                      defaultValue={editWillCallOrderData.serviceID}
                      onChange={handleServiceChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        PaperProps: {
                          style: menuStyles,
                        },
                      }}
                    >
                      {serviceData.length > 0 &&
                        serviceData.map((service: any) => (
                          <MenuItem
                            key={service.serviceID}
                            value={service.serviceID}
                          >
                            {service.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </Box>
                </Grid>
              </Grid>
              <Box className="w-100 Divider mt-40 mb-40"></Box>

              <Typography variant="h5" color="primary" className="ml-8 mt-40 mb-24">
                3. Package Information
              </Typography>

              {/* package information section */}

              <PackageInfo
                shipmentGUID={editWillCallOrderData.shipmentGUID}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
                onContentChange={onContentChange}
                updateEditOrderData={editWillCallOrderData}
                isAlert={isAlert}
                handleInputChange={handleInputChange}
                setEditablePiecesData={setEditablePiecesData}
                setAllPiecesData={setAllPiecesData}
                selectedContent={selectedContent ? selectedContent : null}
                // onPakageInfoDataChange={handlePakageInfoDataChange}
                mySettingsData={mySettingsData}
                serviceID={editWillCallOrderData.serviceID}
              />

              {/* dangerous goods section */}

              <DangerousGoods
                onDangerousGoodDataChang={handleDangerousGoodDataChange}
                formErrors={errors}
                setFormErrors={setFormErrors} // Pass setFormErrors as a prop
                onUnNumberChange={handleUNNumberDataChange}
                handleInputChange={handleInputChange}
                editWillCallOrderData={editWillCallOrderData}
                setPiecesData={setAllPiecesData}
                dangerousGoodDataProps={dangerousGoodData}
                piecesData={piecesData}
              />

              <Attributes
                handleAttChange={handleAttChange}
                serviceID={editWillCallOrderData.serviceID}
                accountNumber={editWillCallOrderData.accountNumber}
                preSelectedItems={editWillCallOrderData?.attributeItems}
                setIsDisplayAttributes={setIsDisplayAttributes}
              />
              {/* <Divider className="mt-5 w-100" /> */}
              <Typography variant="h5" color="primary" className="ml-8 mt-40">
                {`${isDisplayAttributes ? "5." : "4."}`} Shipper Information
              </Typography>
              {/* {defaultDatas && ( */}
              <Shipper
                editWillCallOrderData={editWillCallOrderData}
                handleInputChange={handleInputChange}
                formErrors={errors}
              />

              <Typography variant="h5" color="primary" className=" ml-8 mt-40">
                {`${isDisplayAttributes ? "6." : "5."}`} Documents
              </Typography>
              <Documents
                editWillCallOrderData={editWillCallOrderData}
                onDocumentDataChange={handleDocumentDataChange}
                setAllFilesToRemove={setAllFilesToRemove}
              />

              <Typography variant="h5" color="primary" className=" ml-8 mt-40">
                {`${isDisplayAttributes ? "7." : "6."}`} Notifications
              </Typography>

              <Notifications
                editWillCallOrderData={editWillCallOrderData}
                handleInputChange={handleInputChange}
                formErrors={errors}
              />

              {/* order actions section */}
            </Box>
          )}
        </Box>
      </div>
      <div className="container-fluid">
        <Box className="d-flex justify-content-between gap-2 w-100 Divider-top pt-16 pb-16">
          <Button
            startIcon={<KeyboardBackspaceIcon />}
            onClick={() => {
              navigate("/will-call-orders");
            }}
            id="btnBackToWillCall"
          >
            Back to will call
          </Button>

          <Box className="d-flex">
            <Button
              variant="outlined"
              className="d-flex rounded me-2"
              onClick={() => {
                handleSubmit("createOrder");
              }}
              id="btnSaveOrder"
            >
              Save Order
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              loadingPosition="end"
              className="d-flex rounded me-2"
              endIcon={<img src={arrowicon} alt="icon right" />}
              onClick={() => {
                handleSubmit("activateOrder");
              }}
              id="btnActivateOrder"
            >
              ACTIVATE ORDER
            </LoadingButton>
          </Box>
        </Box>
      </div>

      <ActiveOrderDialog
        open={open}
        handleClose={handleDialogClose}
        shipmentGUID={editWillCallOrderData.shipmentGUID as any}
        //onRedirect={() => navigate('will-call-order')}
      />
    </>
  );
};

export default EditWillCallOrder;
