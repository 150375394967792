import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import { MySettingItemDto } from "../../../models/long-form-item-dto";
import CustomDatePicker from "../CustomDatePicker";
import { useLocation } from "react-router-dom";
interface DeliveryAddressProps {
  deliveryData: any;
  onDeliveryDataChange: (updatedData: any) => void;
  formErrors: any;
  setFormErrors: (errors: any) => void;
  DefaultData: MySettingItemDto;
  readyTimeData: any;
  setErrorCodeDeliveryTime: (errors: any) => void;
}

const DeliveryTime: React.FC<DeliveryAddressProps> = ({
  deliveryData,
  onDeliveryDataChange,
  formErrors,
  setFormErrors,
  DefaultData,
  readyTimeData,
  setErrorCodeDeliveryTime,
}) => {
  const location = useLocation();
  const [asap, setAsap] = useState(deliveryData.deliveryRequestAsap);
  const [holdForPickup, setHoldForPickup] = useState(
    deliveryData.holdForPickup
  );
  const [deliveryWhen, setDeliveryWhen] = useState(DefaultData?.deliverWhen);
  const [localError, setLocalError] = useState<string | null>(null);
  const [deliveryReadyTime, setDeliveryReadyTime] = useState<any>("");

  useEffect(() => {
    onDeliveryDataChange({ deliveryWhen: DefaultData?.deliverWhen });
  }, [DefaultData?.deliverWhen]);

  useEffect(() => {
    if (deliveryData.willCall == true) {
      onDeliveryDataChange({ ...deliveryData, deliveryRequestTime: null });
    }
  }, [deliveryData.willCall]);

  useEffect(() => {
    setHoldForPickup(deliveryData.holdForPickup || false);
  }, [deliveryData.holdForPickup]);

  useEffect(() => {
    if (deliveryData.deliveryRequestAsap !== undefined) {
      setAsap(deliveryData.deliveryRequestAsap);
    }
  }, [deliveryData.deliveryRequestAsap]);

  useEffect(() => {
    const rateEstimateData = location?.state?.rateEstimateData;
    let displayValue =
      deliveryData.willCall || deliveryData.deliveryRequestAsap
        ? null
        : (() => {
            if (DefaultData.deliveryRequestTime) {
              const adjustedPickupTime = adjustDateToToday(
                DefaultData.deliveryRequestTime
              );
              // Check if adjustedPickupTime is a valid Date object
              if (
                adjustedPickupTime instanceof Date &&
                !isNaN(adjustedPickupTime.getTime())
              ) {
                const isFutureDate = adjustedPickupTime >= new Date();
                return isFutureDate
                  ? getPickupReadyTime1(adjustedPickupTime)
                  : null;
              }
            }

            return null;
          })();
    //cary data from rate estimate
    if (rateEstimateData && rateEstimateData?.requestedDeliveryTime) {
      displayValue =
        new Date(rateEstimateData?.requestedDeliveryTime) >= new Date()
          ? rateEstimateData?.requestedDeliveryTime
          : null;
    }

    setDeliveryReadyTime(displayValue);
    onDeliveryDataChange({ deliveryRequestTime: displayValue });
  }, []);

  useEffect(() => {
    setErrorCodeDeliveryTime(localError);
  }, [localError]);

  useEffect(() => {
    if (readyTimeData) {
      if (
        readyTimeData.deliveryRequestTime &&
        readyTimeData.deliveryRequestDay &&
        readyTimeData.deliveryRequestTime != "1900-01-01T00:00:00"
      ) {
        const deliveryReadyTime = readyTimeData.deliveryRequestTime;
        const deliveryReadyDay = readyTimeData.deliveryRequestDay;
        const newDate = new Date();
        const [hours, minutes] = deliveryReadyTime
          .split("T")[1]
          .split(":")
          .map(Number);
        newDate.setHours(hours, minutes, 0, 0);
        const finalDate = addDays(newDate, deliveryReadyDay);
        const formattedFinalDate = formatDateToCustomString(finalDate);
        deliveryData.deliveryRequestAsap = false;
        setDeliveryReadyTime(formattedFinalDate);
        setAsap(false);

        onDeliveryDataChange({ deliveryRequestTime: formattedFinalDate });
      } else {
        if (!deliveryData.deliveryRequestAsap) {
          //This seems unwanted code: raise a bug 59238
          // setDeliveryReadyTime(null);
          // onDeliveryDataChange({
          //   ...deliveryData,
          //   deliveryRequestTime: deliveryReadyTime,
          // });
        }
      }
    }
  }, [readyTimeData]);

  useEffect(() => {
    if (deliveryReadyTime || deliveryData.willCall) {
      setLocalError(null);
    }
  }, [deliveryReadyTime, deliveryData.willCall]);

  useEffect(() => {
    if (deliveryReadyTime) {
      setFormErrors((prevErrors: any) => ({
        ...prevErrors,
        delRequestDate: "",
      }));
    }
  }, [deliveryReadyTime]);

  // Helper function to format date
  const formatDateToCustomString = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const selectedDateTime = new Date(value);
    const currentDateTime = new Date();
    if (!value) {
      setLocalError(null);
      setFormErrors((prevErrors: any) => ({
        ...prevErrors,
        delRequestDate: "Please specify a Requested Delivery Time.",
      }));
      setDeliveryReadyTime(null);
      onDeliveryDataChange({ [name]: null });
      return;
    }
    if (name === "deliveryRequestTime") {
      if (selectedDateTime < currentDateTime) {
        setFormErrors((prevErrors: any) => ({
          ...prevErrors,
          delRequestDate: "",
        }));
        setLocalError("The delivery time cannot be in the past.");
        setDeliveryReadyTime(null);
        onDeliveryDataChange({ [name]: "" });
      } else {
        setLocalError(null);
        setFormErrors((prevErrors: any) => ({
          ...prevErrors,
          delRequestDate: "",
        }));
        onDeliveryDataChange({ [name]: value });
      }
    }
  };

  const currentDate = new Date().toISOString().slice(0, 16);

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setDeliveryWhen(value);
    onDeliveryDataChange({ [name]: value });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    if (name === "deliveryRequestAsap") {
      setAsap(checked);
      setLocalError(null);
      if (checked) {
        onDeliveryDataChange({
          ...deliveryData,
          deliveryRequestTime: "",
          deliveryRequestAsap: true,
        });
        setFormErrors((prevErrors: any) => {
          const { delRequestDate, ...rest } = prevErrors;
          return rest;
        });
      } else {
        // Clear the delivery request time when ASAP is unchecked
        setAsap(null);
        deliveryData.deliveryRequestTime = null;
        onDeliveryDataChange({
          ...deliveryData,
          deliveryRequestTime: "",
          deliveryRequestAsap: false,
        });
      }
    } else if (name === "dropOff") {
      setHoldForPickup(checked);
    }
    onDeliveryDataChange({ ...deliveryData, [name]: checked });
  };

  // Add pickupReadyDay to pickupReadyTime if available
  const addDays = (date: Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  // const getPickupReadyTime = () => {
  //   if (deliveryData.deliveryRequestDay && deliveryData.deliveryRequestTime) {
  //     const readyDate = new Date(deliveryData.deliveryRequestTime);
  //     return addDays(readyDate, deliveryData.deliveryRequestDay)
  //       .toISOString()
  //       .slice(0, 16);
  //   }

  //   if (
  //     deliveryData.deliveryRequestTime &&
  //     deliveryData.deliveryRequestTime != "1900-01-01T00:00:00"
  //   ) {
  //     return deliveryData.deliveryRequestTime;
  //   }
  //   return "";
  // };

  const getPickupReadyTime1 = (adjustedPickupTime: Date) => {
    if (deliveryData.deliveryRequestDay && deliveryData.deliveryRequestTime) {
      const readyDate = new Date(deliveryData.deliveryRequestTime);
      return addDays(readyDate, deliveryData.deliveryRequestDay)
        .toISOString()
        .slice(0, 16);
    }
    const test = adjustedPickupTime;
    return test || "";
  };

  const adjustDateToToday = (dateStr: string): Date => {
    const originalDate = new Date(dateStr);
    // Get the current date and time
    const now = new Date();
    // Create a new Date object with today's date but the same time as the original date
    const adjustedDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      originalDate.getHours(),
      originalDate.getMinutes(),
      originalDate.getSeconds(),
      originalDate.getMilliseconds()
    );

    return adjustedDate;
  };

  return (
    <Box className="d-flex flex-column w-100 border rounded p16">
      <Typography
        variant="h6"
        className="Sub-header"
        sx={{ paddingBottom: "8px!important" }}
      >
        Requested Delivery Time
      </Typography>
      <Grid container spacing={2}>
        <Grid item lg={6} sm={12} md={6} xs={12}>
          <Select
            variant="outlined"
            id="ddDeliveryWhen"
            className="w-100"
            name="deliveryWhen"
            disabled={deliveryData.willCall || deliveryData.deliveryRequestAsap}
            value={deliveryWhen}
            onChange={handleSelectChange}
          >
            <MenuItem value="B">Before</MenuItem>
            <MenuItem value="A">After</MenuItem>
            <MenuItem value="T">At</MenuItem>
          </Select>
        </Grid>
        <Grid item lg={6} sm={12} md={6} xs={12}>
          <CustomDatePicker
            handleDateChange={handleChange}
            label="Requested Delivery Time"
            id="txtDeliveryDate"
            name="deliveryRequestTime"
            value={deliveryReadyTime}
            isClearable={false}
            helperText={localError}
            showError={!!formErrors.delRequestDate || localError}
            minDate={currentDate}
            showTimeSelect={true}
            isDisable={
              deliveryData.willCall || deliveryData.deliveryRequestAsap
            }
            isClearValue={
              deliveryData.willCall || deliveryData.deliveryRequestAsap
                ? true
                : false && deliveryReadyTime == null
            }
          />

          {/* <TextField
            id="datetime-local"
            label="Requested Delivery Time"
            type="datetime-local"
            className="w-100"
            name="deliveryRequestTime"
            InputLabelProps={{ shrink: true }}
            value={displayValue}
            onChange={handleChange}
            disabled={deliveryData.willCall || deliveryData.deliveryRequestAsap}
            error={!!localError || !!formErrors.delRequestDate} // Display error if either local or form error exists
            helperText={localError} // Display the local error or form error
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <img src={calendarIcon} alt="Calendar icon" style={{ maxWidth: "100%" }} />
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                color: (localError || formErrors.delRequestDate) ? '#d32f2f' : 'inherit',
              },
            }}
            FormHelperTextProps={{
              style: {
                color: '#d32f2f',
              },
            }}
            inputProps={{
              min: getMinDateTime(), // Set the min attribute to disable past dates and times based on deliveryData
            }}
          /> */}
        </Grid>
      </Grid>
      <Box className="d-flex gap-2"></Box>
      <Box className="d-flex gap-2 mt-2 h-42">
        <FormControlLabel
          className="pr-8 chk_bx_50"
          control={
            <Checkbox
              checked={deliveryData.deliveryRequestAsap}
              name="deliveryRequestAsap"
              id="cbAsap"
              disabled={deliveryData.willCall}
              onChange={handleCheckboxChange}
            />
          }
          label="ASAP"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={deliveryData.holdForPickup}
              name="holdForPickup"
              id="cbHoldForPu"
              disabled={deliveryData.willCall}
              onChange={handleCheckboxChange}
            />
          }
          label="Hold for PU"
          className="chk_bx_50"
        />
      </Box>
    </Box>
  );
};

export default DeliveryTime;
