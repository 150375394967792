import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AppLayout from "../container/layouts/AppLayout";
import Login from "../modules/auth/login";
import AuthLayout from "../container/layouts/AuthLayout";
import PrivacyStatement from "../modules/privacy-statement/privacy-statement";
import ActiveOrders from "../modules/active-orders/active-orders";
import TrackOrder from "../modules/track-order/track-order";

import TrackOrderWithoutLogin from "../modules/track-order/track-order.unauth";
import LongForm from "../modules/long-form-orders/long-form-order";
import TrackActiveOrder from "../modules/track-active-order/track-active-order";
import TutorialsScreen from "../modules/tutorials/tutorials";
import ContactListScreen from "../modules/contact-list/contact-list";
import SearchOrders from "../modules/search-orders/search-orders";
import BatchPrints from "../modules/batch-prints/batch-prints";
import MultiOrder from "../modules/multi-order/multi-form-order";
import SignUp from "../modules/sign-up/signup";
import AddressBooks from "../modules/address-book/address-book";
import WillCallOrders from "../modules/will-call-orders/will-call-orders";
import SupportSignIn from "../modules/support-sign-in/support-sign-in";
import RateEstimate from "../modules/rate-estimate/rate-estimate";
import ConfirmSignUp from "../modules/sign-up/confirm-signup";
import ForgotPassword from "../modules/forgot-password/forgot-password";
import ChangePassword from "../modules/change-password/change-password";
import ErrorMessage from "../modules/error-message/error-message";
import MySettings from "../modules/my-settings/my-settings";
import EditActiveOrder from "../modules/edit-active-order/edit-active-order";
import ExportData from "../modules/export-data/export-data";
import TermsAndConditions from "../modules/terms-and-conditions/terms-and-conditions";
import ImportOrder from "../modules/import-orders/import-order";
import Invoices from "../modules/invoices/invoices";
import OntimePerformance from "../modules/ontime-performance/ontime-performance";
import ReferenceGroup from "../modules/reference-group/reference-group";
import EditWillCallOrder from "../modules/edit-will-call-order/edit-will-call-order";
import ShipmentVerificationReport from "../modules/shipment-verification-report/shipment-verification-report";
import UnAuthorized from "../modules/UnAuthorized/unAuthorized";
import Healthcare from "../modules/healthcare-form/healthcare-form";
import HealthcareDonor from "../modules/healthcare-donor/healthcare-donor";

function Routing() {
  const userData: any = localStorage.getItem("userDetails");
  const parseUserData = JSON.parse(userData);
  let isAuthenticated;
  if (parseUserData && parseUserData.token) {
    isAuthenticated = parseUserData.token;
  }
  const routes = useRoutes([
    {
      path: "/",
      element: isAuthenticated ? <AppLayout /> : <AuthLayout />,
      children: [
        {
          path: "/",
          element: isAuthenticated ? (
            <Navigate to="/active-orders" />
          ) : (
            <Login />
          ),
        },
        // { path: "/route-preview", element: <RoutePreview /> },
        {
          path: "/shipment-verification-report",
          element: (
            <ShipmentVerificationReport
              title={"ShipmentVerificationReport"}
              orderType={"ShipmentVerificationReport"}
            />
          ),
        },
        { path: "/track-active-order", element: <TrackActiveOrder /> },
        { path: "/privacy-statement", element: <PrivacyStatement /> },
        { path: "/public/track-order", element: <TrackOrderWithoutLogin /> },
        { path: "public/healthcare/:id", element: <HealthcareDonor /> },
        // {path:'/longform-order',element:<LongForm/>},
        { path: "/sign-up", element: <SignUp /> },
        { path: "/confirm-sign-up", element: <ConfirmSignUp /> },
        { path: "/support-sign-in", element: <SupportSignIn /> },
        { path: "/forgot-password", element: <ForgotPassword /> },
        { path: `/change-password`, element: <ChangePassword /> },
        { path: "/error-page", element: <ErrorMessage /> },
        {
          path: "/import-order",
          element: <ImportOrder title="Order Import" />,
        },
        { path: "/healthcare-form", element: <Healthcare /> },
      ],
    },
    {
      path: "/",
      element: isAuthenticated ? <AppLayout /> : <Navigate to="/" />,
      children: [
        {
          path: "/active-orders",
          element: (
            <ActiveOrders title="Active Orders" orderType="ActiveOrders" />
          ),
        },
        {
          path: "/will-call-orders",
          element: <WillCallOrders />,
        },
        {
          path: "/recent-deliveries",
          element: (
            <ActiveOrders
              title="Recent Deliveries"
              orderType="RecentDeliveries"
            />
          ),
        },
        { path: "/track-order", element: <TrackOrder /> },
        { path: "/longform-order", element: <LongForm /> },
        { path: "/edit-active-order", element: <EditActiveOrder /> },
        { path: "/edit-will-call-order", element: <EditWillCallOrder /> },
        { path: "/multiple-order", element: <MultiOrder /> },
        { path: "/tutorials", element: <TutorialsScreen /> },
        { path: "/contact-list", element: <ContactListScreen /> },
        { path: "/privacy-statement", element: <PrivacyStatement /> },
        { path: "/my-settings", element: <MySettings /> },
        { path: "/reference-group", element: <ReferenceGroup /> },

        {
          path: "/ontime-performance",
          element: (
            <OntimePerformance ontimePerformanceType="OntimePerformance" />
          ),
        },

        {
          path: "/import-order",
          element: <ImportOrder title="Order Import" />,
        },
        {
          path: "/search-orders",
          element: (
            <SearchOrders title="Search Orders" orderType="ActiveOrders" />
          ),
        },
        {
          path: "/batch-prints",
          element: (
            <BatchPrints
              title="Batch Print - Ship Label"
              batchType="SHIPLABEL"
            />
          ),
        },
        {
          path: "/batch-prints-dgd",
          element: <BatchPrints title="Batch Print - DGD" batchType="DGD" />,
        },
        {
          path: "/address-book",
          element: (
            <AddressBooks title="Search Orders" orderType="ActiveOrders" />
          ),
        },
        {
          path: "/rate-estimate",
          element: (
            <RateEstimate title="Rate Estimate" orderType="ActiveOrders" />
          ),
        },
        {
          path: "/invoices",
          element: <Invoices />,
        },
        {
          path: "/export-data",
          element: <ExportData title="Export Data" />,
        },

        { path: "/error-page", element: <ErrorMessage /> },
        { path: "/unAuthorized", element: <UnAuthorized /> },
        { path: "/terms-and-conditions", element: <TermsAndConditions /> },
        { path: "*", element: <Navigate to="/" replace /> },
      ],
    },
  ]);
  return routes;
}
export default Routing;
