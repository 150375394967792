import moment from 'moment-timezone';
const timezoneNames: any = {
    "Africa/Abidjan": "Greenwich Mean Time",
    "Africa/Accra": "Greenwich Mean Time",
    "Africa/Algiers": "Central European Time",
    "Africa/Bissau": "Greenwich Mean Time",
    "Africa/Cairo": "Eastern European Time",
    "Africa/Casablanca": "Western European Time",
    "Africa/Ceuta": "Central European Time",
    "Africa/El_Aaiun": "Western European Time",
    "Africa/Johannesburg": "South Africa Standard Time",
    "Africa/Juba": "Central Africa Time",
    "Africa/Khartoum": "Central Africa Time",
    "Africa/Lagos": "West Africa Time",
    "Africa/Maputo": "Central Africa Time",
    "Africa/Monrovia": "Greenwich Mean Time",
    "Africa/Nairobi": "East Africa Time",
    "Africa/Ndjamena": "West Africa Time",
    "Africa/Sao_Tome": "Greenwich Mean Time",
    "Africa/Tripoli": "Eastern European Time",
    "Africa/Tunis": "Central European Time",
    "Africa/Windhoek": "Central Africa Time",
    "America/Adak": "Hawaii-Aleutian Standard Time",
    "America/Anchorage": "Alaska Standard Time",
    "America/Araguaina": "Brasília Time",
    "America/Argentina/Buenos_Aires": "Argentina Time",
    "America/Argentina/Catamarca": "Argentina Time",
    "America/Argentina/Cordoba": "Argentina Time",
    "America/Argentina/Jujuy": "Argentina Time",
    "America/Argentina/La_Rioja": "Argentina Time",
    "America/Argentina/Mendoza": "Argentina Time",
    "America/Argentina/Rio_Gallegos": "Argentina Time",
    "America/Argentina/Salta": "Argentina Time",
    "America/Argentina/San_Juan": "Argentina Time",
    "America/Argentina/San_Luis": "Argentina Time",
    "America/Argentina/Tucuman": "Argentina Time",
    "America/Argentina/Ushuaia": "Argentina Time",
    "America/Asuncion": "Paraguay Time",
    "America/Atikokan": "Eastern Standard Time",
    "America/Bahia": "Brasília Time",
    "America/Bahia_Banderas": "Central Standard Time",
    "America/Barbados": "Atlantic Standard Time",
    "America/Belem": "Brasília Time",
    "America/Belize": "Central Standard Time",
    "America/Blanc-Sablon": "Atlantic Standard Time",
    "America/Boa_Vista": "Amazon Time",
    "America/Bogota": "Colombia Time",
    "America/Boise": "Mountain Standard Time",
    "America/Cambridge_Bay": "Mountain Standard Time",
    "America/Campo_Grande": "Amazon Time",
    "America/Cancun": "Eastern Standard Time",
    "America/Caracas": "Venezuelan Standard Time",
    "America/Cayenne": "French Guiana Time",
    "America/Chicago": "Central Standard Time",
    "America/Chihuahua": "Mountain Standard Time",
    "America/Costa_Rica": "Central Standard Time",
    "America/Creston": "Mountain Standard Time",
    "America/Cuiaba": "Amazon Time",
    "America/Curacao": "Atlantic Standard Time",
    "America/Danmarkshavn": "Greenwich Mean Time",
    "America/Dawson": "Yukon Time",
    "America/Dawson_Creek": "Mountain Standard Time",
    "America/Denver": "Mountain Standard Time",
    "America/Detroit": "Eastern Standard Time",
    "America/Edmonton": "Mountain Standard Time",
    "America/Eirunepe": "Amazon Time",
    "America/El_Salvador": "Central Standard Time",
    "America/Fort_Nelson": "Mountain Standard Time",
    "America/Fortaleza": "Brasília Time",
    "America/Glace_Bay": "Atlantic Standard Time",
    "America/Goose_Bay": "Atlantic Standard Time",
    "America/Grand_Turk": "Eastern Standard Time",
    "America/Guatemala": "Central Standard Time",
    "America/Guayaquil": "Ecuador Time",
    "America/Guyana": "Guyana Time",
    "America/Halifax": "Atlantic Standard Time",
    "America/Havana": "Cuba Standard Time",
    "America/Hermosillo": "Mountain Standard Time",
    "America/Indiana/Indianapolis": "Eastern Standard Time",
    "America/Indiana/Knox": "Central Standard Time",
    "America/Indiana/Marengo": "Eastern Standard Time",
    "America/Indiana/Petersburg": "Eastern Standard Time",
    "America/Indiana/Tell_City": "Central Standard Time",
    "America/Indiana/Vevay": "Eastern Standard Time",
    "America/Indiana/Vincennes": "Eastern Standard Time",
    "America/Indiana/Winamac": "Eastern Standard Time",
    "America/Inuvik": "Mountain Standard Time",
    "America/Iqaluit": "Eastern Standard Time",
    "America/Jamaica": "Eastern Standard Time",
    "America/Juneau": "Alaska Standard Time",
    "America/Kentucky/Louisville": "Eastern Standard Time",
    "America/Kentucky/Monticello": "Eastern Standard Time",
    "America/La_Paz": "Bolivia Time",
    "America/Lima": "Peru Time",
    "America/Los_Angeles": "Pacific Standard Time",
    "America/Maceio": "Brasília Time",
    "America/Managua": "Central Standard Time",
    "America/Manaus": "Amazon Time",
    "America/Martinique": "Atlantic Standard Time",
    "America/Matamoros": "Central Standard Time",
    "America/Mazatlan": "Mountain Standard Time",
    "America/Menominee": "Central Standard Time",
    "America/Merida": "Central Standard Time",
    "America/Metlakatla": "Alaska Standard Time",
    "America/Mexico_City": "Central Standard Time",
    "America/Miquelon": "Pierre & Miquelon Standard Time",
    "America/Moncton": "Atlantic Standard Time",
    "America/Monterrey": "Central Standard Time",
    "America/Montevideo": "Uruguay Standard Time",
    "America/Nassau": "Eastern Standard Time",
    "America/New_York": "Eastern Standard Time",
    "America/Nipigon": "Eastern Standard Time",
    "America/Nome": "Alaska Standard Time",
    "America/Noronha": "Fernando de Noronha Time",
    "America/North_Dakota/Beulah": "Central Standard Time",
    "America/North_Dakota/Center": "Central Standard Time",
    "America/North_Dakota/New_Salem": "Central Standard Time",
    "America/Nuuk": "Greenland Time",
    "America/Ojinaga": "Mountain Standard Time",
    "America/Panama": "Eastern Standard Time",
    "America/Pangnirtung": "Eastern Standard Time",
    "America/Paramaribo": "Suriname Time",
    "America/Phoenix": "Mountain Standard Time",
    "America/Port-au-Prince": "Eastern Standard Time",
    "America/Port_of_Spain": "Atlantic Standard Time",
    "America/Porto_Velho": "Amazon Time",
    "America/Puerto_Rico": "Atlantic Standard Time",
    "America/Punta_Arenas": "Chile Time",
    "America/Rainy_River": "Central Standard Time",
    "America/Rankin_Inlet": "Central Standard Time",
    "America/Recife": "Brasília Time",
    "America/Regina": "Central Standard Time",
    "America/Resolute": "Central Standard Time",
    "America/Rio_Branco": "Amazon Time",
    "America/Santarem": "Brasília Time",
    "America/Santiago": "Chile Time",
    "America/Santo_Domingo": "Atlantic Standard Time",
    "America/Sao_Paulo": "Brasília Time",
    "America/Scoresbysund": "Greenland Time",
    "America/Sitka": "Alaska Standard Time",
    "America/St_Johns": "Newfoundland Standard Time",
    "America/Swift_Current": "Central Standard Time",
    "America/Tegucigalpa": "Central Standard Time",
    "America/Thule": "Eastern Standard Time",
    "America/Thunder_Bay": "Eastern Standard Time",
    "America/Tijuana": "Pacific Standard Time",
    "America/Toronto": "Eastern Standard Time",
    "America/Vancouver": "Pacific Standard Time",
    "America/Whitehorse": "Yukon Time",
    "America/Winnipeg": "Central Standard Time",
    "America/Yakutat": "Alaska Standard Time",
    "America/Yellowknife": "Mountain Standard Time",
    "Antarctica/Casey": "Casey Time",
    "Antarctica/Davis": "Davis Time",
    "Antarctica/DumontDUrville": "Dumont d'Urville Time",
    "Antarctica/Macquarie": "Macquarie Island Time",
    "Antarctica/Mawson": "Mawson Time",
    "Antarctica/Palmer": "Chile Time",
    "Antarctica/Rothera": "Rothera Time",
    "Antarctica/Syowa": "Syowa Time",
    "Antarctica/Troll": "Troll Time",
    "Antarctica/Vostok": "Vostok Time",
    "Asia/Almaty": "Alma-Ata Time",
    "Asia/Amman": "Eastern European Time",
    "Asia/Anadyr": "Anadyr Time",
    "Asia/Aqtau": "Aqtau Time",
    "Asia/Aqtobe": "Aqtobe Time",
    "Asia/Ashgabat": "Turkmenistan Time",
    "Asia/Atyrau": "Atyrau Time",
    "Asia/Baghdad": "Arabian Time",
    "Asia/Baku": "Azerbaijan Time",
    "Asia/Bangkok": "Indochina Time",
    "Asia/Barnaul": "Barnaul Time",
    "Asia/Beirut": "Eastern European Time",
    "Asia/Bishkek": "Kyrgyzstan Time",
    "Asia/Brunei": "Brunei Darussalam Time",
    "Asia/Chita": "Yakutsk Time",
    "Asia/Choibalsan": "Choibalsan Time",
    "Asia/Colombo": "India Standard Time",
    "Asia/Damascus": "Eastern European Time",
    "Asia/Dhaka": "Bangladesh Time",
    "Asia/Dili": "East Timor Time",
    "Asia/Dubai": "Gulf Standard Time",
    "Asia/Dushanbe": "Tajikistan Time",
    "Asia/Famagusta": "Eastern European Time",
    "Asia/Gaza": "Eastern European Time",
    "Asia/Hebron": "Eastern European Time",
    "Asia/Ho_Chi_Minh": "Indochina Time",
    "Asia/Hong_Kong": "Hong Kong Time",
    "Asia/Hovd": "Hovd Time",
    "Asia/Irkutsk": "Irkutsk Time",
    "Asia/Jakarta": "Western Indonesia Time",
    "Asia/Jayapura": "Eastern Indonesia Time",
    "Asia/Jerusalem": "Israel Standard Time",
    "Asia/Kabul": "Afghanistan Time",
    "Asia/Kamchatka": "Kamchatka Time",
    "Asia/Karachi": "Pakistan Time",
    "Asia/Kathmandu": "Nepal Time",
    "Asia/Khandyga": "Yakutsk Time",
    "Asia/Kolkata": "Indian Standard Time",
    "Asia/Calcutta": "Indian Standard Time",
    "Asia/Krasnoyarsk": "Krasnoyarsk Time",
    "Asia/Kuala_Lumpur": "Malaysia Time",
    "Asia/Kuching": "Malaysia Time",
    "Asia/Macau": "China Standard Time",
    "Asia/Magadan": "Magadan Time",
    "Asia/Makassar": "Central Indonesia Time",
    "Asia/Manila": "Philippine Time",
    "Asia/Nicosia": "Eastern European Time",
    "Asia/Novokuznetsk": "Novokuznetsk Time",
    "Asia/Novosibirsk": "Novosibirsk Time",
    "Asia/Omsk": "Omsk Time",
    "Asia/Oral": "Oral Time",
    "Asia/Pontianak": "Western Indonesia Time",
    "Asia/Pyongyang": "Pyongyang Time",
    "Asia/Qatar": "Arabian Time",
    "Asia/Qostanay": "Qostanay Time",
    "Asia/Qyzylorda": "Qyzylorda Time",
    "Asia/Riyadh": "Arabian Time",
    "Asia/Sakhalin": "Sakhalin Time",
    "Asia/Samarkand": "Uzbekistan Time",
    "Asia/Seoul": "Korea Standard Time",
    "Asia/Shanghai": "China Standard Time",
    "Asia/Singapore": "Singapore Time",
    "Asia/Srednekolymsk": "Srednekolymsk Time",
    "Asia/Taipei": "China Standard Time",
    "Asia/Tashkent": "Uzbekistan Time",
    "Asia/Tbilisi": "Georgia Time",
    "Asia/Tehran": "Iran Standard Time",
    "Asia/Thimphu": "Bhutan Time",
    "Asia/Tokyo": "Japan Standard Time",
    "Asia/Tomsk": "Tomsk Time",
    "Asia/Ulaanbaatar": "Ulaanbaatar Time",
    "Asia/Urumqi": "Urumqi Time",
    "Asia/Ust-Nera": "Vladivostok Time",
    "Asia/Vladivostok": "Vladivostok Time",
    "Asia/Yakutsk": "Yakutsk Time",
    "Asia/Yangon": "Myanmar Time",
    "Asia/Yekaterinburg": "Yekaterinburg Time",
    "Asia/Yerevan": "Armenia Time",
    "Atlantic/Azores": "Azores Time",
    "Atlantic/Bermuda": "Atlantic Standard Time",
    "Atlantic/Canary": "Western European Time",
    "Atlantic/Cape_Verde": "Cape Verde Time",
    "Atlantic/Faroe": "Western European Time",
    "Atlantic/Madeira": "Western European Time",
    "Atlantic/Reykjavik": "Greenwich Mean Time",
    "Atlantic/South_Georgia": "South Georgia Time",
    "Atlantic/Stanley": "Falkland Islands Time",
    "Australia/Adelaide": "Australian Central Standard Time",
    "Australia/Brisbane": "Australian Eastern Standard Time",
    "Australia/Broken_Hill": "Australian Central Standard Time",
    "Australia/Currie": "Australian Eastern Standard Time",
    "Australia/Darwin": "Australian Central Standard Time",
    "Australia/Eucla": "Australian Central Western Standard Time",
    "Australia/Hobart": "Australian Eastern Standard Time",
    "Australia/Lindeman": "Australian Eastern Standard Time",
    "Australia/Lord_Howe": "Lord Howe Standard Time",
    "Australia/Melbourne": "Australian Eastern Standard Time",
    "Australia/Perth": "Australian Western Standard Time",
    "Australia/Sydney": "Australian Eastern Standard Time",
    "CET": "Central European Time",
    "CST6CDT": "Central Standard Time",
    "EET": "Eastern European Time",
    "EST": "Eastern Standard Time",
    "EST5EDT": "Eastern Standard Time",
    "Etc/GMT": "Greenwich Mean Time",
    "Etc/GMT+1": "GMT+1",
    "Etc/GMT+10": "GMT+10",
    "Etc/GMT+11": "GMT+11",
    "Etc/GMT+12": "GMT+12",
    "Etc/GMT+2": "GMT+2",
    "Etc/GMT+3": "GMT+3",
    "Etc/GMT+4": "GMT+4",
    "Etc/GMT+5": "GMT+5",
    "Etc/GMT+6": "GMT+6",
    "Etc/GMT+7": "GMT+7",
    "Etc/GMT+8": "GMT+8",
    "Etc/GMT+9": "GMT+9",
    "Etc/GMT-1": "GMT-1",
    "Etc/GMT-10": "GMT-10",
    "Etc/GMT-11": "GMT-11",
    "Etc/GMT-12": "GMT-12",
    "Etc/GMT-13": "GMT-13",
    "Etc/GMT-14": "GMT-14",
    "Etc/GMT-2": "GMT-2",
    "Etc/GMT-3": "GMT-3",
    "Etc/GMT-4": "GMT-4",
    "Etc/GMT-5": "GMT-5",
    "Etc/GMT-6": "GMT-6",
    "Etc/GMT-7": "GMT-7",
    "Etc/GMT-8": "GMT-8",
    "Etc/GMT-9": "GMT-9",
    "Etc/UTC": "Coordinated Universal Time",
    "Europe/Amsterdam": "Central European Time",
    "Europe/Andorra": "Central European Time",
    "Europe/Astrakhan": "Moscow Standard Time",
    "Europe/Athens": "Eastern European Time",
    "Europe/Belgrade": "Central European Time",
    "Europe/Berlin": "Central European Time",
    "Europe/Brussels": "Central European Time",
    "Europe/Bucharest": "Eastern European Time",
    "Europe/Budapest": "Central European Time",
    "Europe/Chisinau": "Eastern European Time",
    "Europe/Copenhagen": "Central European Time",
    "Europe/Dublin": "Greenwich Mean Time",
    "Europe/Gibraltar": "Central European Time",
    "Europe/Helsinki": "Eastern European Time",
    "Europe/Istanbul": "Turkey Time",
    "Europe/Kaliningrad": "Eastern European Time",
    "Europe/Kiev": "Eastern European Time",
    "Europe/Kirov": "Moscow Standard Time",
    "Europe/Lisbon": "Western European Time",
    "Europe/London": "Greenwich Mean Time",
    "Europe/Luxembourg": "Central European Time",
    "Europe/Madrid": "Central European Time",
    "Europe/Malta": "Central European Time",
    "Europe/Minsk": "Moscow Standard Time",
    "Europe/Monaco": "Central European Time",
    "Europe/Moscow": "Moscow Standard Time",
    "Europe/Oslo": "Central European Time",
    "Europe/Paris": "Central European Time",
    "Europe/Prague": "Central European Time",
    "Europe/Riga": "Eastern European Time",
    "Europe/Rome": "Central European Time",
    "Europe/Samara": "Samara Time",
    "Europe/Saratov": "Saratov Time",
    "Europe/Simferopol": "Moscow Standard Time",
    "Europe/Sofia": "Eastern European Time",
    "Europe/Stockholm": "Central European Time",
    "Europe/Tallinn": "Eastern European Time",
    "Europe/Tirane": "Central European Time",
    "Europe/Ulyanovsk": "Ulyanovsk Time",
    "Europe/Uzhgorod": "Eastern European Time",
    "Europe/Vienna": "Central European Time",
    "Europe/Vilnius": "Eastern European Time",
    "Europe/Volgograd": "Volgograd Time",
    "Europe/Warsaw": "Central European Time",
    "Europe/Zaporozhye": "Eastern European Time",
    "Europe/Zurich": "Central European Time",
    "HST": "Hawaii-Aleutian Standard Time",
    "Indian/Chagos": "Indian Ocean Time",
    "Indian/Christmas": "Christmas Island Time",
    "Indian/Cocos": "Cocos Islands Time",
    "Indian/Kerguelen": "French Southern and Antarctic Time",
    "Indian/Mahe": "Seychelles Time",
    "Indian/Maldives": "Maldives Time",
    "Indian/Mauritius": "Mauritius Time",
    "Indian/Reunion": "Reunion Time",
    "MET": "Middle European Time",
    "MST": "Mountain Standard Time",
    "MST7MDT": "Mountain Standard Time",
    "PST8PDT": "Pacific Standard Time",
    "Pacific/Apia": "Samoa Standard Time",
    "Pacific/Auckland": "New Zealand Standard Time",
    "Pacific/Bougainville": "Bougainville Standard Time",
    "Pacific/Chatham": "Chatham Standard Time",
    "Pacific/Chuuk": "Chuuk Time",
    "Pacific/Easter": "Easter Island Standard Time",
    "Pacific/Efate": "Vanuatu Time",
    "Pacific/Enderbury": "Phoenix Islands Time",
    "Pacific/Fakaofo": "Tokelau Time",
    "Pacific/Fiji": "Fiji Standard Time",
    "Pacific/Funafuti": "Tuvalu Time",
    "Pacific/Galapagos": "Galapagos Time",
    "Pacific/Gambier": "Gambier Time",
    "Pacific/Guadalcanal": "Solomon Islands Time",
    "Pacific/Guam": "Chamorro Standard Time",
    "Pacific/Honolulu": "Hawaii-Aleutian Standard Time",
    "Pacific/Kiritimati": "Line Islands Time",
    "Pacific/Kosrae": "Kosrae Time",
    "Pacific/Kwajalein": "Marshall Islands Time",
    "Pacific/Majuro": "Marshall Islands Time",
    "Pacific/Marquesas": "Marquesas Time",
    "Pacific/Nauru": "Nauru Time",
    "Pacific/Niue": "Niue Time",
    "Pacific/Norfolk": "Norfolk Island Time",
    "Pacific/Noumea": "New Caledonia Time",
    "Pacific/Pago_Pago": "Samoa Standard Time",
    "Pacific/Palau": "Palau Time",
    "Pacific/Pitcairn": "Pitcairn Time",
    "Pacific/Pohnpei": "Pohnpei Standard Time",
    "Pacific/Port_Moresby": "Papua New Guinea Time",
    "Pacific/Rarotonga": "Cook Islands Time",
    "Pacific/Tahiti": "Tahiti Time",
    "Pacific/Tarawa": "Gilbert Islands Time",
    "Pacific/Tongatapu": "Tonga Time",
    "Pacific/Wake": "Wake Time",
    "Pacific/Wallis": "Wallis and Futuna Time",
    "WET": "Western European Time"
}



// JSON mapping of StandardAbbr to timezone names
const timeZoneMapping: any = {
  "AKDT": "America/Anchorage",
  "AST": "America/Halifax",
  "ACST": "Australia/Adelaide",
  "AEST": "Australia/Sydney",
  "AWST": "Australia/Perth",
  "BTS": "Asia/Dhaka",
  "CST": "America/Chicago", // Could also be Asia/Shanghai
  "BRT": "America/Sao_Paulo",
  "CET": "Europe/Berlin",
  "EET": "Europe/Athens",
  "EST": "America/New_York", // Could also be a different EST
  "COT": "America/Bogota",
  "ECT": "America/Guayaquil",
  "GMT": "Etc/Greenwich",
  "HST": "Pacific/Honolulu",
  "IST": "Asia/Kolkata",
  "ICT": "Asia/Bangkok",
  "Idt": "Asia/Jerusalem",
  "MVT": "Indian/Maldives",
  "MSK": "Europe/Moscow",
  "MST": "America/Denver",
  "NZST": "Pacific/Auckland",
  "PKT": "Asia/Karachi",
  "PYT": "America/Asuncion",
  "SGT": "Asia/Singapore",
  "SAST": "Africa/Johannesburg",
  "PST": "America/Los_Angeles",
  "USA-PT": "America/Los_Angeles",
  "USA-ET": "America/New_York",
  "USA-CT" :  "America/Chicago",
};

// Function to get the current date and time based on StandardAbbr
export const getCurrentDateTimeByAbbr = (abbr: any)=> {
  const timeZone = timeZoneMapping[abbr]; 

  if (timeZone) {
    // Get current date and time using moment-timezone
    return moment().tz(timeZone).format('YYYY-MM-DD HH:mm:ss');
  } else {
    return moment().format('YYYY-MM-DD HH:mm:ss');
  }
};

export const getCurrentDateByAbbr = (pickupReadyTime: any,abbr: any, pickupReadyDay: any)=> {
  const timeZone = timeZoneMapping[abbr];
  let time = moment().format("HH:mm:ss"); 
  
  
  if (timeZone) {
    time = moment().tz(timeZone).format("HH:mm:ss"); 
    if(pickupReadyTime) {
      time = moment(pickupReadyTime).format("HH:mm:ss");
   } 
    let currentDate = moment().tz(timeZone).format('YYYY-MM-DD')
    if(pickupReadyDay) {
      currentDate =  moment(currentDate).add(pickupReadyDay, 'days').format('YYYY-MM-DD');
    }
    // Combine the current date with the input time
    let combinedDateTime = moment(`${currentDate}T${time}`);
    combinedDateTime.format("YYYY-MM-DDTHH:mm:ss");
 
    return combinedDateTime;
  } else {
    if(pickupReadyTime) {
      time = moment(pickupReadyTime).format("HH:mm:ss");
   } 
    let currentDate = moment().format('YYYY-MM-DD')
    if(pickupReadyDay) {
      currentDate =  moment(currentDate).add(pickupReadyDay, 'days').format('YYYY-MM-DD');
    }
    // Combine the current date with the input time
    let combinedDateTime = moment(`${currentDate}T${time}`);
    combinedDateTime.format("YYYY-MM-DDTHH:mm:ss");

    
    return combinedDateTime;
    
  }
};



export default timezoneNames;