import React, { useMemo, useState } from "react";
import { Autocomplete, FormControl, useMediaQuery } from "@mui/material";

import { Box, Grid, TextField, Typography } from "@mui/material";
import CustomDatePicker from "../CustomDatePicker";
import ErrorInputAdornment from "../error-input-adornment";
import { AddressList } from "../../../models/health-care-dto";
import { getUniqueConcatenatedAddresses } from "../../util/common";
interface OrderInfoProps {
  formik: any;
  originAddressData: AddressList;
}
const OrderInfo = (props: OrderInfoProps) => {
  const { formik, originAddressData } = props;
  const isMobile = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = useState(false);
  // interface MenuStyles {
  //   maxHeight: string;
  // }

  // const [menuStyles, setMenuStyles] = useState<MenuStyles>({
  //   maxHeight: "200px", // Default max height for desktop
  // });

  const handleOriginAddressChange = (
    event: React.ChangeEvent<{}>,
    selectedAddressValue: any
  ) => {
    formik.setFieldValue("originAddress", selectedAddressValue);
  };

  const handleInputChange1 = (event: React.ChangeEvent<{}>, value: string) => {
    return value.trim();
  };

  // Custom filter options function to handle uniqueness
  const customFilterOptions = (options: any[], { inputValue }: any) => {
    const trimmedInputValue = inputValue.trim().toLowerCase();

    // Filter the options and ensure they are unique based on displayName
    const filteredOptions = options.filter((option) =>
      option.displayName.toLowerCase().includes(trimmedInputValue)
    );

    // Use a Set to ensure uniqueness
    const uniqueFilteredOptions = new Set(
      filteredOptions.map((option) => option.displayName)
    );

    // Convert the Set back to an array of options
    return filteredOptions.filter((option) =>
      uniqueFilteredOptions.has(option.displayName)
    );
  };

  const filteredOptions = useMemo(() => {
    return getUniqueConcatenatedAddresses(originAddressData).filter(
      (option) => option !== formik.values.originAddress
    );
  }, [originAddressData, formik.values.originAddress]);

  return (
    <>
      <Box className="mt-3 pb-40 Divider w-100 mb-24">
        <Grid container spacing={2}>
          <Grid item lg={7} md={6} sm={12} xs={12}>
            <Box className="rounded p16 border">
              <Typography variant="h6" className="Sub-header">
                Donor Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    id="hcDonorid"
                    label="Donor ID"
                    name="donorId"
                    variant="outlined"
                    className="w-100"
                    size="medium"
                    onChange={(e) => {
                      // Remove any character that isn't alphanumeric
                      const alphanumericValue = e.target.value.replace(
                        /[^a-zA-Z0-9]/g,
                        ""
                      );
                      formik.setFieldValue("donorId", alphanumericValue);
                    }}
                    value={formik.values.donorId}
                    error={
                      formik.touched.donorId && Boolean(formik.errors.donorId)
                    }
                    inputProps={{ maxLength: 20 }}
                    InputProps={{
                      endAdornment: formik.touched.donorId &&
                        Boolean(formik.errors.donorId) && (
                          <ErrorInputAdornment />
                        ),
                    }}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <FormControl size="medium" className="w-100">
                    <Autocomplete
                      id="hcOriginaddress"
                      className="hcAutoComplete"
                      options={filteredOptions}
                      onOpen={() => setOpen(true)}
                      onClose={() => setOpen(false)}
                      getOptionLabel={(option) => option.displayName || ""}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <span>
                            {option.agencyAcronym || ""} --{" "}
                            <strong>{option.companyName || ""}</strong>,{" "}
                            {option.addressLine1 || ""}, {option.city || ""},{" "}
                            {option.state || ""} {option.zipCode || ""}
                          </span>
                        </li>
                      )}
                      filterOptions={customFilterOptions}
                      onInputChange={handleInputChange1}
                      onChange={handleOriginAddressChange}
                      value={formik.values.originAddress}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Origin Address"
                          variant="outlined"
                          error={
                            formik.touched.originAddress &&
                            Boolean(formik.errors.originAddress)
                          }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: <>{params.InputProps.endAdornment}</>,
                          }}
                        />
                      )}
                      sx={{ width: isMobile ? "100%" : undefined }}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    id="hcAlternateOriginAddress"
                    label="Alternate Origin Address"
                    name="alternatOriginAddress"
                    variant="outlined"
                    className="w-100"
                    size="medium"
                    onChange={formik.handleChange}
                    value={formik.values.alternatOriginAddress}
                    inputProps={{ maxLength: 4000 }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={5} md={6} sm={12} xs={12}>
            <Box className="border rounded p16">
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="h6" className="Sub-header">
                    Time of Death
                  </Typography>

                  <CustomDatePicker
                    id="hcTimeOfDeath"
                    handleDateChange={formik.handleChange}
                    label="Date & Time"
                    name="timeOfDeath"
                    isClearable={false}
                    value={formik.values.timeOfDeath}
                    showTimeSelect={true}
                    showError={
                      formik.touched.timeOfDeath &&
                      Boolean(formik.errors.timeOfDeath)
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default OrderInfo;
