import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useEffect, useState } from "react";

//import useLocalDateTime from "../../hook/use-date-with-timezone";
import CustomDatePicker from "../CustomDatePicker";
import useLocalDateTime from "../../hook/use-date-with-timezone";
interface DeliveryAddressProps {
  deliveryData?: any;
  onDeliveryDataChange: (updatedData: any) => void;
  pickupData?: any;
  formErrors: any;
  setFormErrors?: (errors: any) => void;
  readyTimeData: any;
  handleInputChange: (updatedData: any) => void;
  editWillCallOrderData: any;
}

const ReadyTime: React.FC<DeliveryAddressProps> = ({
  onDeliveryDataChange,
  formErrors,
  readyTimeData,
  handleInputChange,
  editWillCallOrderData,
}) => {
  const [willCall, setWillCall] = useState(false);
  const [dropOff, setDropOff] = useState(
    editWillCallOrderData.dropOff || false
  );
  const [readyNow, setReadyNow] = useState(editWillCallOrderData.puReadyNow);
  const localDateTime = useLocalDateTime(
    editWillCallOrderData.pickupTimezoneID
  );
  const [pickupReadyTime, setPickupReadyTime] = useState<any>();

  // Helper function to format date
  const formatDateToCustomString = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  // Add pickupReadyDay to pickupReadyTime if available
  const addDays = (date: Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const getPickupReadyTime = () => {
    if (
      editWillCallOrderData.pickupReadyDay &&
      editWillCallOrderData.pickupReadyTime
    ) {
      const readyDate = new Date(editWillCallOrderData.pickupReadyTime);
      return addDays(readyDate, editWillCallOrderData.pickupReadyDay)
        .toISOString()
        .slice(0, 16);
    }
    if (
      editWillCallOrderData.pickupReadyTime &&
      editWillCallOrderData.pickupReadyTime != "1900-01-01T00:00:00"
    ) {
      return editWillCallOrderData.pickupReadyTime;
    }
    if (
      editWillCallOrderData.pickupReadyTime &&
      editWillCallOrderData.pickupReadyTime != "1900-01-01T00:00:00"
    ) {
      return editWillCallOrderData.pickupReadyTime;
    }
    return null;
  };

  const getPickupReadyTime1 = (adjustedPickupTime: Date) => {
    if (
      editWillCallOrderData.pickupReadyDay &&
      editWillCallOrderData.pickupReadyTime
    ) {
      const readyDate = new Date(editWillCallOrderData.pickupReadyTime);
      return addDays(readyDate, editWillCallOrderData.pickupReadyDay)
        .toISOString()
        .slice(0, 16);
    }
    const delTime = adjustedPickupTime;
    return delTime || null;
  };

  // Format deliveryData.pickupReadyTime to be used as min attribute
  const currentDate = new Date().toISOString().slice(0, 16); // Current date-time in the required format
  // const formattedMinDateTime = editWillCallOrderData.pickupReadyTime
  //   ? new Date(editWillCallOrderData.pickupReadyTime).toISOString().slice(0, 16)
  //   : currentDate;
  // Function to check if a date is in the past
  const isPastDate = (dateStr: string) => {
    const selectedDate = new Date(dateStr);
    const now = new Date();
    return selectedDate < now;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const selectedDateTime = new Date(value);
    const currentDateTime = new Date();

    if (name === "pickupReadyTime") {
      if (selectedDateTime < currentDateTime) {
        setPickupReadyTime(null);

        handleInputChange(e);

        onDeliveryDataChange({ ["puReadyTime"]: null });
      } else if (
        name !== "pickupReadyTime" ||
        selectedDateTime >= currentDateTime
      ) {
        onDeliveryDataChange({ [name]: value });
        onDeliveryDataChange({ ["puReadyTime"]: e?.target?.value });
        handleInputChange(e);
      }
    }
    handleInputChange(e);
  };

  useEffect(() => {
    onDeliveryDataChange({ ["puReadyNow"]: readyNow });
  }, [readyNow]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange(event);
  };

  const adjustDateToToday = (dateStr: string): Date => {
    const originalDate = new Date(dateStr);
    // Get the current date and time
    const now = new Date();
    // Create a new Date object with today's date but the same time as the original date
    const adjustedDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      originalDate.getHours(),
      originalDate.getMinutes(),
      originalDate.getSeconds(),
      originalDate.getMilliseconds()
    );

    return adjustedDate;
  };

  useEffect(() => {
    const displayValue = readyNow
      ? ""
      : (() => {
          const adjustedPickupTime = adjustDateToToday(getPickupReadyTime());
          const date =
            adjustedPickupTime < new Date()
              ? null
              : getPickupReadyTime1(adjustedPickupTime);
          return date;
        })();
    setPickupReadyTime(displayValue);
    handleChange({
      target: { name: "pickupReadyTime", value: displayValue },
    } as any);
  }, []);

  useEffect(() => {
    if (readyTimeData) {
      if (
        readyTimeData.pickupReadyTime &&
        readyTimeData.pickupReadyTime != "1900-01-01T00:00:00" &&
        readyTimeData.pickupReadyDay
      ) {
        setReadyNow(false);
        const pickupReadyTime = readyTimeData.pickupReadyTime;
        const pickupReadyDay = readyTimeData.pickupReadyDay;
        const newDate = new Date();
        const [hours, minutes] = pickupReadyTime
          .split("T")[1]
          .split(":")
          .map(Number);
        newDate.setHours(hours, minutes, 0, 0);
        const finalDate = addDays(newDate, pickupReadyDay);
        const formattedFinalDate = formatDateToCustomString(finalDate);
        setPickupReadyTime(formattedFinalDate);
      } else {
        if (!readyNow) {
          // const date: any = (() => {
          //   const adjustedPickupTime = adjustDateToToday(getPickupReadyTime());
          //   const date =
          //     adjustedPickupTime < new Date()
          //       ? null
          //       : getPickupReadyTime1(adjustedPickupTime);
          //   return date;
          // })();
          // if(date == null){
          //setPickupReadyTime(null);
          // }else{
          //   setPickupReadyTime(date);
          // }
        }
      }
    }
  }, [readyTimeData]);

  useEffect(() => {
    handleChange({
      target: { name: "pickupReadyTime", value: pickupReadyTime },
    } as any);
  }, [pickupReadyTime]);

  const updateDeliveryDataIfNeeded = () => {
    const pickupReadyTime = getPickupReadyTime();
    const isPast = isPastDate(pickupReadyTime);

    if (readyNow || isPast) {
      // If the date is past or 'readyNow' is true, set it to empty string
      //editWillCallOrderData.pickupReadyTime = null;
      handleChange({
        target: { name: "pickupReadyTime", value: null },
      } as any);
      setPickupReadyTime(null);
    } else {
      // Otherwise, update deliveryData with the calculated pickupReadyTime
      //editWillCallOrderData.pickupReadyTime = pickupReadyTime;
      handleChange({
        target: { name: "pickupReadyTime", value: pickupReadyTime },
      } as any);
      setPickupReadyTime(pickupReadyTime);
    }
  };

  useEffect(() => {
    updateDeliveryDataIfNeeded();
  }, [pickupReadyTime, readyNow]);

  useEffect(() => {
    setReadyNow(editWillCallOrderData.puReadyNow ? true : false);
    setWillCall(editWillCallOrderData.isWillCall ? true : false);
    setDropOff(editWillCallOrderData.dropOff ? true : false);
    const isPast = isPastDate(editWillCallOrderData.puReadyTime);

    if (editWillCallOrderData.puReadyNow || isPast) {
      setPickupReadyTime(null);
    } else {
      setPickupReadyTime(editWillCallOrderData.puReadyTime);
    }
  }, [editWillCallOrderData]);

  return (
    <Box className="d-flex flex-column w-100 border rounded p16">
      <Typography
        variant="h6"
        className="Sub-header"
        sx={{ paddingBottom: "8px!important" }}
      >
        Shipment Ready Time
      </Typography>

      <CustomDatePicker
        handleDateChange={handleChange}
        id="txtReadyDate"
        label="Ready Date"
        name="pickupReadyTime"
        value={pickupReadyTime}
        isClearable={false}
        isClearValue={readyNow}
        showError={!!formErrors.pickupReadyTime}
        showTimeSelect={true}
        isDisable={readyNow}
        minDate={currentDate}
      />

      {/* <TextField
        id="datetime-local"
        label="Ready Date"
        type="datetime-local"
        name="pickupReadyTime"
        InputLabelProps={{ shrink: true }}
        value={displayValue} // Set empty string if "Ready Now" is selected or the date is in the past
        onChange={handleChange}
        disabled={readyNow || willCall} // Disable if "Ready Now" or "Will Call" is selected
        error={!!localError || !!formErrors.puReadyTime} // Display error if either local or form error exists
        helperText={localError} // Display the local error or form error
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton>
                <img
                  src={calendarIcon}
                  alt="Calendar icon"
                  style={{ maxWidth: "100%" }}
                />
              </IconButton>
            </InputAdornment>
          ),
          style: {
            color: localError || formErrors.puReadyTime ? "#d32f2f" : "inherit",
          },
          inputProps: {
            min: formattedMinDateTime, // Use current date if pickupReadyTime is not available
          },
        }}
        FormHelperTextProps={{
          style: {
            color: "#d32f2f",
          },
        }}
      /> */}
      <Box className="d-flex gap-2 mt-2 order_chkbx">
        <div className="d-flex">
          <FormControlLabel
            className="chk_bx_50"
            control={
              <Checkbox
                // name="readyNow"
                name="puReadyNow"
                id="cbReadyNow"
                checked={readyNow}
                onChange={handleCheckboxChange}
              />
            }
            label="Ready Now"
          />
          <FormControlLabel
            className="chk_bx_50 pr-8"
            control={
              <Checkbox
                name="dropOff"
                id="cbDropOff"
                checked={dropOff}
                onChange={handleCheckboxChange}
              />
            }
            label="Drop Off"
          />
        </div>
        <div className="d-flex">
          <FormControlLabel
            className="chk_bx_50"
            control={
              <Checkbox
                // name="willCall"
                name="isWillCall"
                checked={willCall}
                id="cbWillCall"
                disabled={true}
                onChange={handleCheckboxChange}
              />
            }
            label="Will Call"
          />
        </div>
      </Box>
    </Box>
  );
};

export default ReadyTime;
