import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PrintIcon from "@mui/icons-material/Print";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import React, { useCallback, useEffect, useState } from "react";
import { visuallyHidden } from "@mui/utils";
import CommonPagination from "../../shared/components/CustomPagination";
import { useDecodeJWT } from "../../shared/hook/use-decodeJWT";
import Loader from "../../shared/util/loader";
import { useMediaQuery } from "@mui/material";
import { BatchPrintsDto } from "../../models/batch-prints-dto";
import useAccounntsLogic from "../../shared/lookup/accounts/accounts.logic";
import useBatchPrintsLogic from "./batch-prints.logic";
import useServiceLogic from "../../shared/lookup/service/service.logic";
import { downloadAndSavePDF } from "../../shared/util/download-pdf";
import { API_BASE_URL, MAX_COMPANY_ID } from "../../config/constants";
import axiosInstance from "../../config/axios-interceptor";
import { Link } from "react-router-dom";
import { formatDate } from "../../shared/util/date-utils";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CustomDatePicker from "../../shared/components/CustomDatePicker";
import moment from "moment";
type Order = "desc" | "asc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof BatchPrintsDto;
  label: string;
  numeric: boolean;
  minwidth: string;
}

const headCells: HeadCell[] = [
  {
    id: "shipmentNumber",
    numeric: true,
    disablePadding: false,
    label: "ORDER #",
    minwidth: "110",
  },
  {
    id: "orderByName",
    numeric: true,
    disablePadding: false,
    label: "ORDER BY",
    minwidth: "140",
  },
  {
    id: "readyDate",
    numeric: true,
    disablePadding: false,
    label: "READY DATE",
    minwidth: "150",
  },
  {
    id: "origin",
    numeric: true,
    disablePadding: false,
    label: "ORIG",
    minwidth: "60",
  },
  {
    id: "destination",
    numeric: true,
    disablePadding: false,
    label: "DEST",
    minwidth: "60",
  },
  {
    id: "deliverto",
    numeric: true,
    disablePadding: false,
    label: "DELIVERY TO",
    minwidth: "230",
  },
  {
    id: "svc",
    numeric: true,
    disablePadding: false,
    label: "SVC",
    minwidth: "60",
  },
  {
    id: "reference",
    numeric: true,
    disablePadding: false,
    label: "REFERENCE",
    minwidth: "110",
  },
  {
    id: "prn",
    numeric: true,
    disablePadding: false,
    label: "PRINTED?",
    minwidth: "70",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof BatchPrintsDto
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}
interface BatchPrintsProps {
  title: string;
  batchType: string;
}
const BatchPrints = ({ title, batchType }: BatchPrintsProps) => {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof BatchPrintsDto>("shipmentNumber");
  const [notData, setNotData] = React.useState<any>("");
  const { account } = useAccounntsLogic();
  const [isLoading, setIsLoading] = useState(false);
  const { batchPrints } = useBatchPrintsLogic();
  const { service } = useServiceLogic();

  const [accounts, setAccounts] = useState<any>([]);
  const [batchPrintData, setBatchPrintData] = useState<BatchPrintsDto[]>([]);
  const token = useDecodeJWT();

  // interface MenuStyles {
  //   maxHeight: string;
  // }

  // interface servicestyle {
  //   maxHeight: string;
  // }

  // const [menuStyles, setMenuStyles] = useState<MenuStyles>({
  //   maxHeight: "180px", // Default max height for desktop
  // });
  const menuStyles = {
    maxHeight: "180px", // Default max height for desktop
  };
  // const [servicestyle, setservicestyle] = useState<servicestyle>({
  //   maxHeight: "370px", // Default max height for desktop
  // });

  const servicestyle = {
    maxHeight: "370px", // Default max height for desktop
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRecord, setTotalRecord] = useState(0);
  const isMobile = useMediaQuery("(max-width:599px)");
  const [serviceData, setServiceData] = useState<any>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isShowFooter, setShowFooter] = useState(false);
  const [resetTrigger, setResetTrigger] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [searchFormState, setSearchFormState] = useState({
    accountNumber: "All",
    originAP: "",
    destinationAP: "",
    startDate: "",
    endDate: "",
    serviceID: "All",
    orderByName: "",
    printed: "All",
    reference: "",
  });

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof BatchPrintsDto
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  useEffect(() => {
    getBatchPrintsData();

    if ((selectedItems && selectedItems.length == 0) || !selectedItems) {
      setShowFooter(false);
    }
  }, [order, orderBy, page, rowsPerPage]);

  useEffect(() => {
    //Reset the search on batch type change
    setSearchFormState({
      accountNumber: "All",
      originAP: "",
      destinationAP: "",
      startDate: "",
      endDate: "",
      serviceID: "All",
      orderByName: "",
      printed: "All",
      reference: "",
    });

    setResetTrigger(true);
  }, [batchType]);

  useEffect(() => {
    if (resetTrigger) {
      getBatchPrintsData(1);
      setSelectedItems([]);
      setSelectAll(false);
      // Reset the trigger
      setResetTrigger(false);
    }
  }, [resetTrigger]);

  const getAccounts = useCallback(async () => {
    try {
      const param = {
        userId: token.UserId,
        companyID: 0,
        useIncludeAllItemrId: false,
      };

      const response = await account(param);
      if (response.data && response.data.data) {
        setAccounts(response.data.data);
      } else {
        setAccounts([]);
        setNotData("Data not found");
      }
    } catch {
      setAccounts([]);
      setNotData("Data not found");
    }
  }, [token.UserId]);

  useEffect(() => {
    getAccounts();
    setNotData("Data not found");
  }, [getAccounts]);

  useEffect(() => {
    getService();
  }, []);

  const getService = async () => {
    try {
      const param: any = {
        companyID: MAX_COMPANY_ID,
        userGUID: token.UserGUID,
        serviceAvailType: "All",
        baseDateTime: new Date(),
        origCountryID: "",
        origCityName: "",
        origStateProvID: "",
        origPostalCode: "",
        origAirportID: "",
        destCountryID: "",
        destCityName: "",
        destStateProvID: "",
        destPostalCode: "",
        destAirportID: "",
      };
      const response = await service(param);
      if (response.data && response.data.data) {
        setServiceData(response.data.data);
      } else {
        setServiceData([]);
      }
    } catch {
      setServiceData([]);
    }
  };

  const getBatchPrintsData = async (currentPageData: number = 0) => {
    try {
      setIsLoading(true);
      const {
        accountNumber,
        orderByName,
        originAP,
        destinationAP,
        startDate,
        endDate,
        serviceID,
        printed,
        reference,
      } = searchFormState;

      const param = {
        companyID: MAX_COMPANY_ID,
        userId: parseInt(token.UserId),

        accountNumber: accountNumber === "All" ? "" : accountNumber,
        originAP: originAP,
        destinationAP: destinationAP,
        startDate:
          startDate !== "" && startDate
            ? moment(startDate).format("yyyy-MM-DD")
            : null,
        endDate:
          endDate !== "" && endDate
            ? moment(endDate).format("yyyy-MM-DD")
            : null,

        serviceID: serviceID === "All" ? 0 : parseInt(serviceID),
        orderByName: orderByName,
        printed: printed === "All" ? "" : printed,
        reference: reference,
        orderBy: orderBy,
        sorting: order,
        pagesize: rowsPerPage === -1 ? totalRecord : rowsPerPage,
        pageNo: currentPageData ? currentPageData : page + 1,
        batchType: batchType,
        applicationID: batchType === "SHIPLABEL" ? "PLSHIP" : "PLSHIP",
      };
      const response = await batchPrints(param);
      if (response.data && response.data.data) {
        setBatchPrintData(response.data.data);
        setTotalRecord(response.data.totalCount);
      }
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      setBatchPrintData([]);
      setTotalRecord(0);
    }
  };

  const handleTextFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    const { endDate } = searchFormState;

    if (name == "startDate" && !endDate) {
      setSearchFormState({
        ...searchFormState,
        [name]: value,
        endDate: value,
      });
    } else {
      setSearchFormState({
        ...searchFormState,
        [name]: value,
      });
    }
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setSearchFormState({
      ...searchFormState,
      [name]: value,
    });
  };

  const handleSearch = () => {
    if (page > 0) {
      //just reset the page so api will call
      setPage(0);
    } else {
      getBatchPrintsData();
    }
  };

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (pageSize: number) => {
    if (pageSize === -1) {
      setRowsPerPage(-1);
    } else {
      setRowsPerPage(pageSize);
    }
    setPage(0);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (selectedItems.length <= 50) {
      if (checked && selectedItems.length == 50) {
        window.alert("You can not select more than 50 rows");
      } else {
        setSelectedItems((prev) => {
          const updatedItems = checked
            ? [...prev, name]
            : prev.filter((id) => id !== name);

          setSelectAll(updatedItems.length === batchPrintData.length);

          return updatedItems;
        });
      }
    }
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    setSelectAll(checked);

    const updatedItems = checked
      ? batchPrintData.map((item) => item.shipmentGUID as string)
      : [];
    if (updatedItems.length > 50) {
      setShowFooter(false);
      window.alert("You can not select more than 50 rows");
      setSelectAll(false);
    } else {
      setSelectedItems(updatedItems);
    }
  };

  useEffect(() => {
    if (selectedItems && selectedItems.length > 0) {
      if (selectedItems.length > 50) {
        setShowFooter(false);
        window.alert("You can not select more than 50 rows");
      } else {
        setShowFooter(true);
      }
    } else {
      setShowFooter(false);
    }
  }, [selectedItems]);

  const handlePrint = async () => {
    if (selectedItems.length === 0 || !selectedItems) {
      setShowFooter(false);
      window.alert("You must select documents to print.");
    } else {
      const shipmentGUIDs = selectedItems.map((guid) => ({
        shipmentGuid: guid,
        pickupGUID: "",
        manifestGUID: "",
      }));
      try {
        const param = {
          companyID: MAX_COMPANY_ID,
          userId: parseInt(token.UserId),
          batchType: batchType,
          accountNumber: "",
          shipmentGUIDs: shipmentGUIDs,
        };

        const response = await axiosInstance.post(
          `${API_BASE_URL}/api/Report/api/PrintBatch`,
          param,
          { responseType: "arraybuffer" }
        );

        if (response.status) {
          const pdfTitle =
            batchType === "SHIPLABEL"
              ? "batchshiplabel.pdf"
              : "batchdgdlabel.pdf";
          const result = await downloadAndSavePDF(response.data, pdfTitle);

          if (result.success) {
            getBatchPrintsData(page);
            setShowFooter(false);
            setSelectAll(false);
            setSelectedItems([]);
          }
        }
      } catch (error: any) {}
    }
  };

  const handleMarkUnprint = async (_item: BatchPrintsDto) => {
    const confirm = window.confirm(
      "Are you sure you want to mark this document not printed?"
    );
    if (confirm) {
      try {
        const param = {
          batchType: batchType,
          batchPrintShipGUID:
            batchType === "SHIPLABEL" ? _item?.batchPrintShipGUID : "",
          bacthPrintDGDGUID:
            batchType != "SHIPLABEL" ? _item?.bacthPrintDGDGUID : "",
        };
        const response = await axiosInstance.post(
          `${API_BASE_URL}/api/Orders/MarkNotPrinted`,
          param
        );
        if (response.status) {
          getBatchPrintsData(page);
          setShowFooter(false);
        }
      } catch (error: any) {}
    }
  };

  const formatedAddress = (row: any) => {
    const addressParts = [
      row?.delAddress1 || "",
      row?.delAddress2 || "",
      row?.delCityName || "",
      row?.delStateProvID || "",
    ];

    const formattedAddress = addressParts
      .filter((part) => part) // Filter out any empty or falsy parts
      .join(", "); // Join the remaining parts with ', '
    return formattedAddress;
  };

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof BatchPrintsDto) =>
      (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          <TableCell
            width={40}
            key={"selectAll"}
            align={"left"}
            padding={"normal"}
            sortDirection={false}
            style={{ minWidth: 40 }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  id="cbSelectAll"
                  className="chk_bx_batchprint"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                  name="selectAll"
                />
              }
              label=""
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              width={headCell.minwidth}
              key={headCell.id}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          {/* {orderType === "WillCallOrders" &&  <TableCell>EDIT</TableCell>} */}
        </TableRow>
      </TableHead>
    );
  }

  const checkPrinted = (row: BatchPrintsDto) => {
    if (batchType == "SHIPLABEL") {
      return row?.batchPrintShipGUID ? true : false;
    } else {
      return row?.bacthPrintDGDGUID ? true : false;
    }
  };

  return (
    <>
      <div
        className={
          isShowFooter
            ? "active-order Batch-print-section"
            : "active-order Batch-print-grid"
        }
      >
        <>
          <Box
            className="Header-main pt-16"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              className="Main-Header side-by-side-header"
              variant="h4"
              color={"primary"}
            >
              {title}
            </Typography>
            <Typography className="info-text" variant="h6">
              Select the documents that you want to print. When finished, select
              the “Print” button to generate a PDF containing all of the
              selected documennts. REFRESH button will show which documents have
              been printed (checkmark in Printed column.) To unmark a document
              as printed, click on the checkmark in the Printed column.
            </Typography>
          </Box>
          <Box className="d-flex flex-column w-100 border mt-2 p16 rounded mb-16">
            <Typography variant="h6" className="header-sub-title Search-lbl">
              Search Criteria
              {isMobile && (
                <>
                  <Button onClick={toggleExpand} style={{ float: "right" }}>
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </>
              )}
            </Typography>
            <Grid
              container
              spacing={2}
              className={isMobile && !expanded ? "hide-search-default" : ""}
              sx={{
                marginTop: {
                  xs: 1, // 8px margin-top for mobile
                  sm: 0, // 0 margin-top for larger screens
                },
              }}
            >
              <Grid item xs={12} sm={5} md={3} lg={3}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">
                    Select Account
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="ddBpAccountNumber"
                    value={searchFormState?.accountNumber}
                    label="Select Account"
                    name="accountNumber"
                    onChange={handleSelectChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },

                      PaperProps: {
                        style: servicestyle,
                      },
                    }}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {accounts.length > 0 &&
                      accounts.map((account: any) => (
                        <MenuItem
                          key={account.userAccountRowGUID}
                          value={account.accountNumber}
                        >
                          {account.displayName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={3} md={2} lg={2}>
                <TextField
                  size="small"
                  id="txtOriginAP"
                  variant="outlined"
                  value={searchFormState?.originAP}
                  onChange={handleTextFieldChange}
                  label="ORIG A/P"
                  name="originAP"
                  className="w-100"
                />
              </Grid>
              <Grid item xs={6} sm={3} md={2} lg={2}>
                <TextField
                  size="small"
                  variant="outlined"
                  id="txtDestinationAP"
                  label="DEST A/P"
                  value={searchFormState?.destinationAP}
                  name="destinationAP"
                  onChange={handleTextFieldChange}
                  className="w-100"
                />
              </Grid>

              <Grid item xs={6} sm={4} md={2} lg={2}>
                <div className="small-cal-view">
                  <CustomDatePicker
                    handleDateChange={handleTextFieldChange as any}
                    id="txtReadyStartDate"
                    label="Ready Date - Start"
                    name="startDate"
                    value={searchFormState?.startDate}
                    isClearable={true}
                    isClearValue={!searchFormState?.startDate ? true : false}
                  />
                </div>
              </Grid>
              <Grid item xs={6} sm={4} md={2} lg={2}>
                <div className="small-cal-view">
                  <CustomDatePicker
                    handleDateChange={handleTextFieldChange as any}
                    label="Ready Date - End"
                    id="txtReadyEndDate"
                    name="endDate"
                    value={searchFormState?.endDate}
                    isClearable={true}
                    isClearValue={!searchFormState?.endDate ? true : false}
                  />
                </div>
              </Grid>
              {/* <Grid item xs={6} sm={4} md={2} lg={2}>
                <TextField
                  className="w-100"
                  size="small"
                  id="datetime-start-local"
                  label="Ready Date - Start"
                  type="date"
                  name="startDate"
                  value={searchFormState?.startDate}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleTextFieldChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <img
                            src={calendarIcon}
                            alt="Calendar icon"
                            style={{ maxWidth: "100%" }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid> */}
              {/* <Grid item xs={6} sm={4} md={2} lg={2}>
                <TextField
                  className="w-100"
                  size="small"
                  id="datetime-end-local"
                  label="Ready Date - End"
                  type="date"
                  name="endDate"
                  value={searchFormState?.endDate}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleTextFieldChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <img
                            src={calendarIcon}
                            alt="Calendar icon"
                            style={{ maxWidth: "100%" }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid> */}
              <Grid item xs={6} sm={5} md={3} lg={3}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">
                    Service Type
                  </InputLabel>

                  <Select
                    id="ddBpServive"
                    variant="outlined"
                    defaultValue=""
                    label="Service Type"
                    value={searchFormState?.serviceID}
                    name="serviceID"
                    onChange={handleSelectChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },

                      PaperProps: {
                        style: menuStyles,
                      },
                    }}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {serviceData.length > 0 &&
                      serviceData.map((service: any) => (
                        <MenuItem
                          key={service.serviceID}
                          value={service.serviceID}
                        >
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={4} md={3} lg={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  label="Order By"
                  id="txtOrderByName"
                  className="w-100"
                  value={searchFormState?.orderByName}
                  name="orderByName"
                  onChange={handleTextFieldChange}
                />
              </Grid>

              <Grid item xs={6} sm={4} md={2} lg={2}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">Printed</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="ddBpType"
                    label="Select Type"
                    value={searchFormState?.printed}
                    name="printed"
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="P">Printed</MenuItem>
                    <MenuItem value="U">Unprinted</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid className="d-flex" item xs={6} sm={8} md={4} lg={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} md={8} lg={8}>
                    <TextField
                      size="small"
                      variant="outlined"
                      label="Reference"
                      id="txtReference"
                      value={searchFormState?.reference}
                      name="reference"
                      onChange={handleTextFieldChange}
                      className="w-100"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      id="btnSearch"
                      className="Search_small_btn w-100"
                      startIcon={<SearchIcon />}
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Paper className="ActiveOrder Batch-grid" sx={{ width: "100%" }}>
              <TableContainer className="Batchprint-grid">
                {" "}
                {/* Add class name for styling */}
                <Table aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={batchPrintData.length}
                  />

                  {isMobile && (
                    <>
                      <TableCell
                        width={40}
                        key={"selectAll"}
                        align={"left"}
                        padding={"normal"}
                        sortDirection={false}
                        style={{ minWidth: 40 }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="cbSelectAll"
                              className="chk_bx_batchprint"
                              checked={selectAll}
                              onChange={handleSelectAllChange}
                              name="selectAll"
                            />
                          }
                          label="SELECT ALL"
                        />
                      </TableCell>
                    </>
                  )}
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={12} align="center">
                          <Loader />
                        </TableCell>
                      </TableRow>
                    ) : batchPrintData.length === 0 ? (
                      <TableRow className="Nodata_found">
                        {/* <TableCell
              className="No-data-section"
              colSpan={12}
              align="center"
            >
              <NoDataFoundBatchPrint />
            </TableCell> */}

                        <TableCell className="" colSpan={12} align="center">
                          {notData}
                        </TableCell>
                      </TableRow>
                    ) : (
                      batchPrintData.map((row: BatchPrintsDto, index: any) => {
                        return isMobile ? (
                          <>
                            <MobileCardView
                              key={row.shipmentGUID}
                              row={row}
                              handleMarkUnprint={handleMarkUnprint}
                              checkPrinted={checkPrinted}
                              selectedItems={selectedItems}
                              handleChange={handleChange}
                              formatedAddress={formatedAddress}
                            />
                          </>
                        ) : (
                          <TableRow
                            hover
                            key={row.companyID}
                            className={
                              selectedItems.includes(row.shipmentGUID as string)
                                ? "row-highlight"
                                : ""
                            }
                          >
                            <TableCell style={{ minWidth: 40, minHeight: 40 }}>
                              <FormControlLabel
                                key={row.shipmentGUID}
                                control={
                                  <Checkbox
                                    checked={selectedItems.includes(
                                      row.shipmentGUID as string
                                    )}
                                    id="cbSelectedItems"
                                    onChange={handleChange}
                                    name={row.shipmentGUID}
                                    className="chk_bx_batchprint"
                                  />
                                }
                                label=""
                              />
                            </TableCell>
                            <TableCell style={{ minWidth: 100 }}>
                              <Link
                                to={`/track-order?ShipmentGUID=${row?.shipmentGUID}`}
                                className="primary"
                                id={`lbtnOrderId`}
                              >
                                {row?.shipmentNumber}
                              </Link>
                              {/* <a href="/track-order" className="primary">
                      {row?.shipmentNumber}
                    </a> */}
                            </TableCell>
                            <TableCell style={{ minWidth: 140 }}>
                              {row?.orderByName}
                            </TableCell>
                            <TableCell style={{ minWidth: 110 }}>
                              {formatDate(row.readyDateTime as string)}
                            </TableCell>
                            <TableCell style={{ minWidth: 60 }}>
                              {row?.puAirportID}
                            </TableCell>
                            <TableCell style={{ minWidth: 60 }}>
                              {row?.delAirportID}
                            </TableCell>
                            <TableCell style={{ minWidth: 200 }}>
                              <b className="Del_company">
                                {row?.delCompanyName}
                              </b>
                              <span className="del_company_detail">
                                {formatedAddress(row)}
                              </span>
                            </TableCell>
                            <TableCell style={{ minWidth: 60 }}>
                              {row?.serviceCode}
                            </TableCell>
                            <TableCell style={{ minWidth: 110 }}>
                              {row?.reference}
                            </TableCell>
                            <TableCell
                              style={{ minWidth: 110 }}
                              onClick={() =>
                                checkPrinted(row)
                                  ? handleMarkUnprint(row)
                                  : null
                              }
                            >
                              {checkPrinted(row) ? (
                                <span className="chkbxcolor">
                                  <CheckCircleIcon
                                    className="checkcircle_icon"
                                    sx={{ color: "green" }}
                                  />
                                </span>
                              ) : (
                                // <span className="chkbxcolor">
                                //   <CheckCircleIcon
                                //     className="checkcircle_icon"
                                //     sx={{ color: "green" }}
                                //   />
                                // </span>
                                ""
                              )}
                            </TableCell>

                            {/* <TableCell style={{ minWidth: 190 }}>
                        {row.updatedDeliveryDateTime ? (
                          <>
                            <Box className="weight-500">
                              {formatDate(row.updatedDeliveryDateTime)}
                            </Box>
                            <Box>
                              {formatDateTZ(row.updatedDeliveryDateTime)}
                            </Box>
                          </>
                        ) : (
                          <Box className="weight-500">
                            {row.estimatedDeliveryDateTime
                              ? formatDateTZ(
                                  row.estimatedDeliveryDateTime
                                )
                              : ""}
                          </Box>
                        )}
                      </TableCell> */}
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* {isShowFooter && (
                <>
                  <Box className="d-flex justify-content-end gap-2 mb-3 w-100">
                    <Button
                      variant="contained"
                      className="d-flex rounded me-2"
                      onClick={() => {
                        getBatchPrintsData();
                        setShowFooter(false);
                        setSelectAll(false);
                        setSelectedItems([]);
                      }}
                      startIcon={<RefreshIcon />}
                    >
                      REFRESH
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className=""
                      onClick={() => {
                        handlePrint();
                      }}
                      startIcon={<PrintIcon />}
                    >
                      PRINT SELECTED
                    </Button>
                  </Box>{" "}
                </>
              )} */}

              {!isShowFooter && (
                <>
                  <div className="Batch-grid-footer">
                    <CommonPagination
                      count={totalRecord}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </>
              )}

              {/* )} */}
            </Paper>
          </Box>
        </>
      </div>
      {isShowFooter && (
        <>
          <div className="container-fluid">
            <Box
              className="d-flex justify-content-end gap-2 w-100 Divider-top pt-16"
              sx={{
                pb: {
                  xs: 2, // 16px for mobile devices
                  sm: 0, // 0px for other devices
                },
              }}
            >
              <Button
                id="btnRefresh"
                onClick={() => {
                  getBatchPrintsData();
                  setShowFooter(false);
                  setSelectAll(false);
                  setSelectedItems([]);
                }}
                startIcon={<RefreshIcon />}
                variant="outlined"
                className="d-flex rounded me-2"
              >
                REFRESH
              </Button>
              <Button
                id="btnPrint"
                onClick={() => {
                  handlePrint();
                }}
                variant="contained"
                color="primary"
                className="d-flex rounded me-2"
                startIcon={<PrintIcon />}
              >
                PRINT SELECTED
              </Button>
            </Box>
          </div>
        </>
      )}
    </>
  );
};

// Mobile card view component
const MobileCardView: React.FC<{
  row: BatchPrintsDto;
  handleMarkUnprint: (row: BatchPrintsDto) => void;
  checkPrinted: (row: BatchPrintsDto) => boolean;
  selectedItems: any;
  handleChange: any;
  formatedAddress: (row: BatchPrintsDto) => any;
}> = ({
  row,
  handleMarkUnprint,
  checkPrinted,
  selectedItems,
  handleChange,
  formatedAddress,
}) => {
  return (
    <Paper
      elevation={3}
      sx={{ marginBottom: "16px", padding: "0" }}
      className={
        selectedItems.includes(row.shipmentGUID as string)
          ? "row-highlight Card_view"
          : "Card_view"
      }
    >
      <Typography variant="h6" className="Order-head Field_info">
        <span className="field_label" style={{ paddingBottom: "7px" }}>
          <FormControlLabel
            sx={{
              height: "30px",
              ml: {
                xs: 0,
                sm: 0, // 0px for other devices
              },
            }}
            className="mr-8"
            key={row.shipmentGUID}
            control={
              <Checkbox
                checked={selectedItems.includes(row.shipmentGUID as string)}
                id="cbBatchPrint"
                onChange={handleChange}
                name={row.shipmentGUID}
                className="chk_bx_batchprint"
              />
            }
            label=""
          />
          ORDER #
        </span>{" "}
        <span className="Order_id Field_value" style={{ paddingTop: "10px" }}>
          <Link
            to={`/track-order?ShipmentGUID=${row?.shipmentGUID}`}
            className="primary"
            id={`lbtnOrderId`}
          >
            {row?.shipmentNumber}
          </Link>
        </span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">ORDER BY</span>
        <span className="Field_value">
          {" "}
          <i className="status-info">{row.orderByName}</i>
        </span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">READY DATE</span>{" "}
        <span className="Field_value">
          {formatDate(row.readyDateTime as string)}
        </span>
      </Typography>

      <Typography variant="body1" className="Field_info">
        <span className="field_label">ORIG</span>{" "}
        <span className="Field_value">{row.puAirportID}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">DEST</span>{" "}
        <span className="Field_value">{row.delAirportID}</span>
      </Typography>

      <Typography variant="body1" className="Field_info">
        <span className="field_label">DELIVERY TO</span>
        <span className="Field_value">
          <b className="Del_company">{row?.delCompanyName}</b>

          <i className="del_company_detail">{formatedAddress(row)}</i>
        </span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">SERVICE CODE</span>{" "}
        <span className="Field_value">{row.serviceCode}</span>
      </Typography>

      <Typography variant="body1" className="Field_info">
        <span className="field_label">REFERENCE</span>{" "}
        <span className="Field_value">{row.reference}</span>
      </Typography>

      <Typography variant="body1" className="Field_info">
        <span className="field_label">PRINTED?</span>{" "}
        <span
          className="Field_value"
          onClick={() => (checkPrinted(row) ? handleMarkUnprint(row) : null)}
        >
          {checkPrinted(row) ? (
            <CheckCircleIcon sx={{ color: "#006939" }} />
          ) : (
            ""
          )}
        </span>
      </Typography>
    </Paper>
  );
};

export default BatchPrints;
