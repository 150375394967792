import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import { handleNumericValue } from "../../util/numeric-value";
import { EditWillCallRequestDTO } from "../../../models/edit-will-call-order-request-item-dto";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { red } from "@mui/material/colors";
interface NotificationProps {
  formErrors: any;
  editWillCallOrderData: EditWillCallRequestDTO;
  handleInputChange: (updatedData: any) => void;
}

const Notifications: React.FC<NotificationProps> = ({
  editWillCallOrderData,
  handleInputChange,
  formErrors,
}) => {
  return (
    <>
      <Box className="d-flex gap-4 mt-4 w-100">
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Box className="d-flex flex-column w-100 border p16 rounded">
              <Typography variant="h6" className="Sub-header">
                Shipment Email Notifications
              </Typography>
              <Typography
                variant="body1"
                className="weight-500 font14 textcolor"
              >
                Shipment Milestones
              </Typography>
              <Box className="d-flex flex-column w-100 p-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleInputChange}
                      checked={editWillCallOrderData.notifyShipperOrderAck}
                      name="notifyShipperOrderAck"
                      id="cbShipmentAcknowledged"
                    />
                  }
                  label="Acknowledged"
                />
                <Grid container alignItems="center">
                  <Grid item lg={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleInputChange}
                          checked={editWillCallOrderData.notifyShipperOrigQdt}
                          name="notifyShipperOrigQdt"
                          id="cbShipmentEdtSet"
                        />
                      }
                      label="EDT Set"
                    />
                  </Grid>
                  <Grid item>
                    <p
                      className="addr_lbl1"
                      style={{
                        margin: 0,
                        paddingLeft: "32px",
                        marginTop: "-15px",
                      }}
                    >
                      Routing has been determined & estimated delivery time
                      (EDT) has been set.
                    </p>
                  </Grid>
                </Grid>

                <Grid container alignItems="center">
                  <Grid item lg={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleInputChange}
                          checked={editWillCallOrderData.notifyShipperChangeQdt}
                          name="notifyShipperChangeQdt"
                          id="cbShipmentEdtChanged"
                        />
                      }
                      label="EDT Changed"
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <p
                      className="addr_lbl1"
                      style={{
                        margin: 0,
                        paddingLeft: "32px",
                        marginTop: "-15px",
                      }}
                    >
                      The estimated delivery time (EDT) has been changed.
                    </p>
                  </Grid>
                </Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleInputChange}
                      checked={editWillCallOrderData.notifyShipperDispatched}
                      name="notifyShipperDispatched"
                      id="cbShipmentDispatched"
                    />
                  }
                  label="Dispatched"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleInputChange}
                      id="cbShipmentPickedup"
                      checked={editWillCallOrderData.notifyShipperPickedUp}
                      name="notifyShipperPickedUp"
                    />
                  }
                  label="Picked-Up"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      id="cbShipmentDropped"
                      onChange={handleInputChange}
                      checked={editWillCallOrderData.notifyShipperDropped}
                      name="notifyShipperDropped"
                    />
                  }
                  label="Dropped-Off"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      id="cbShipmentDeparted"
                      onChange={handleInputChange}
                      checked={editWillCallOrderData.notifyShipperDeparted}
                      name="notifyShipperDeparted"
                    />
                  }
                  label="Departed"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      id="cbShipmentArrived"
                      onChange={handleInputChange}
                      checked={editWillCallOrderData.notifyShipperArrived}
                      name="notifyShipperArrived"
                    />
                  }
                  label="Arrived"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleInputChange}
                      id="cbShipmentRecovered"
                      checked={editWillCallOrderData.notifyShipperRecovered}
                      name="notifyShipperRecovered"
                    />
                  }
                  label="Recovered"
                />
                <Grid container alignItems="center">
                  <Grid item lg={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleInputChange}
                          id="cbShipmentOfd"
                          checked={
                            editWillCallOrderData.notifyShipperOutForDelivery
                          }
                          name="notifyShipperOutForDelivery"
                        />
                      }
                      label="OFD"
                    />
                  </Grid>

                  <Grid item lg={12} xs={12}>
                    <p
                      className="addr_lbl1"
                      style={{
                        margin: 0,
                        paddingLeft: "32px",
                        marginTop: "-15px",
                      }}
                    >
                      Shipment was marked out for delivery
                    </p>
                  </Grid>
                </Grid>

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleInputChange}
                      checked={editWillCallOrderData.notifyShipperDelivered}
                      name="notifyShipperDelivered"
                      id="cbShipmentDelivered"
                    />
                  }
                  label="Delivered"
                />
              </Box>
            </Box>
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Box className="d-flex flex-column w-100 border p16 rounded">
              <Typography variant="h6" className="Sub-header">
                Consignee Email Notifications
              </Typography>
              <Typography
                variant="body1"
                className="weight-500 font14 textcolor"
              >
                Shipment Milestones
              </Typography>
              <Box className="d-flex flex-column w-100 p-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleInputChange}
                      checked={editWillCallOrderData.notifyConseeOrderAck}
                      name="notifyConseeOrderAck"
                      id="cbConsigneeAcknowledged"
                    />
                  }
                  label="Acknowledged"
                />
                <Grid container alignItems="center">
                  <Grid item lg={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleInputChange}
                          checked={editWillCallOrderData.notifyConseeOrigQdt}
                          name="notifyConseeOrigQdt"
                          id="cbConsigneeEdtSet"
                        />
                      }
                      label="EDT Set"
                    />
                  </Grid>
                  <Grid item lg={12}>
                    <p
                      className="addr_lbl1"
                      style={{
                        margin: 0,
                        paddingLeft: "32px",
                        marginTop: "-15px",
                      }}
                    >
                      Routing has been determined & estimated delivery time
                      (EDT) has been set.
                    </p>
                  </Grid>
                </Grid>

                <Grid container alignItems="center">
                  <Grid item lg={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleInputChange}
                          checked={editWillCallOrderData.notifyConseeChangeQdt}
                          name="notifyConseeChangeQdt"
                          id="cbConsigneeEdtChanged"
                        />
                      }
                      label="EDT Changed"
                    />
                  </Grid>

                  <Grid item lg={12} xs={12}>
                    <p
                      className="addr_lbl1"
                      style={{
                        margin: 0,
                        paddingLeft: "32px",
                        marginTop: "-15px",
                      }}
                    >
                      The estimated delivery time (EDT) has been changed.
                    </p>
                  </Grid>
                </Grid>

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleInputChange}
                      checked={editWillCallOrderData.notifyConseeDispatched}
                      name="notifyConseeDispatched"
                      id="cbConsigneeDispatched"
                    />
                  }
                  label="Dispatched"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleInputChange}
                      checked={editWillCallOrderData.notifyConseePickedUp}
                      name="notifyConseePickedUp"
                      id="cbConsigneePickedUp"
                    />
                  }
                  label="Picked-Up"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleInputChange}
                      checked={editWillCallOrderData.notifyConseeDropped}
                      name="notifyConseeDropped"
                      id="cbConsigneeDropped"
                    />
                  }
                  label="Dropped-Off"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleInputChange}
                      checked={editWillCallOrderData.notifyConseeDeparted}
                      name="notifyConseeDeparted"
                      id="cbConsigneeDeparted"
                    />
                  }
                  label="Departed"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleInputChange}
                      checked={editWillCallOrderData.notifyConseeArrived}
                      name="notifyConseeArrived"
                      id="cbConsigneeArrived"
                    />
                  }
                  label="Arrived"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleInputChange}
                      checked={editWillCallOrderData.notifyConseeRecovered}
                      name="notifyConseeRecovered"
                      id="cbConsigneeRecovered"
                    />
                  }
                  label="Recovered"
                />

                <Grid container>
                  <Grid item lg={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleInputChange}
                          checked={
                            editWillCallOrderData.notifyConseeOutForDelivery
                          }
                          name="notifyConseeOutForDelivery"
                          id="cbConsigneeOfd"
                        />
                      }
                      label="OFD"
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <p
                      className="addr_lbl1"
                      style={{
                        margin: 0,
                        paddingLeft: "32px",
                        marginTop: "-15px",
                      }}
                    >
                      Shipment was marked out for delivery
                    </p>
                  </Grid>
                </Grid>

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleInputChange}
                      checked={editWillCallOrderData.notifyConseeDelivered}
                      name="notifyConseeDelivered"
                      id="cbConsigneeDelivered"
                    />
                  }
                  label="Delivered"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="d-flex gap-4 mt-4 w-100">
        <Box className="d-flex flex-column w-100 border p16 rounded">
          <Typography variant="h6" className="Sub-header">
            Text Notifications
          </Typography>

          <Grid container spacing={2} className="pt-0">
            <Grid item lg={4} md={4} sm={6} xs={12} className="pt-0">
              <TextField
                name="textPhoneNumber"
                label="Phone Number"
                variant="outlined"
                id="txtPhoneNumber"
                fullWidth
                margin="normal"
                value={editWillCallOrderData.textPhoneNumber}
                onChange={handleInputChange}
                onKeyDown={handleNumericValue}
                error={!!formErrors.textPhoneNumber}
                inputProps={{
                  maxLength: 25,
                }}
                InputProps={{
                  endAdornment: !!formErrors.textPhoneNumber && (
                    <InputAdornment position="end" disablePointerEvents={true}>
                      <InfoRoundedIcon
                        style={{ color: red[500] }}
                        fontSize="small"
                      ></InfoRoundedIcon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              className="pt-8"
              item
              lg={5}
              md={5}
              sm={8}
              xs={12}
              sx={{ Height: "56px", alignItems: "center", display: "flex" }}
            >
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12} className="gap-8">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="textNotifyPickup"
                        checked={editWillCallOrderData.textNotifyPickup}
                        onChange={handleInputChange}
                        id="cbNotifyPickup"
                      />
                    }
                    label="Notify on Pickup"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="textNotifyDelivery"
                        checked={editWillCallOrderData.textNotifyDelivery}
                        onChange={handleInputChange}
                        id="cbNotifyDelivery"
                      />
                    }
                    label="Notify on Delivery"
                  />
                </Grid>
                <Grid item lg={6} xs={12}></Grid>
              </Grid>
            </Grid>
            <Grid
              item
              lg={3}
              xs={12}
              sx={{ Height: "56px", alignItems: "center", display: "flex" }}
            ></Grid>
          </Grid>
          <Box className="d-flex gap-4 w-100"></Box>
        </Box>
      </Box>
    </>
  );
};
export default Notifications;
