import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getYear, getMonth } from "date-fns";
import { InputAdornment, TextField, IconButton } from "@mui/material";
import calendarIcon from "../../assets/images/logo/calendaricon.svg";
import ErrorInputAdornment from "./error-input-adornment";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useMediaQuery } from "@mui/material";

// Custom range function to generate an array of numbers
const range = (start: number, end: number, step: number): number[] => {
  const result: number[] = [];
  for (let i = start; i <= end; i += step) {
    result.push(i);
  }
  return result;
};

interface datePickerProps {
  id?: any;
  handleDateChange: any;
  label: string;
  name: string;
  value: any;
  minDate?: any;
  maxDate?: any;
  helperText?: any;
  isClearable?: boolean;
  showError?: any;
  showTimeSelect?: any;
  isDisable?: boolean;
  isClearValue?: boolean;
  isPortal?: boolean;
  errorColor?: string;
  size?: any;
  isMultiOrderScreen?: boolean;
}

const CustomDatePicker = (props: datePickerProps) => {
  const {
    id,
    handleDateChange,
    label,
    name,
    value,
    minDate,
    maxDate,
    isClearable,
    showError,
    helperText,
    showTimeSelect,
    isDisable,
    isClearValue,
    isPortal,
    errorColor = "#d32f2f",
    size,
    isMultiOrderScreen,
  } = props;
  const isMobile = useMediaQuery("(max-width:599px)");
  const isTablet = useMediaQuery("(max-width:905px)");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const currentYear = getYear(new Date());
  const startYear = 1955;
  const endYear = currentYear + 50;
  const years = range(startYear, endYear, 1);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const minYear = minDate ? getYear(minDate) : null;
  const minMonth = minDate ? getMonth(minDate) : null;
  const maxYear = maxDate ? getYear(maxDate) : null;
  const maxMonth = maxDate ? getMonth(maxDate) : null;

  const onDateChange = (date: any) => {
    setStartDate(date);
    const target = {
      target: {
        name: name,
        value: date,
      },
    };
    handleDateChange(target);
  };

  useEffect(() => {
    if (value) {
      setStartDate(new Date(value));
    }
  }, [value]);

  useEffect(() => {
    if (isClearValue) {
      setStartDate(null);
    }
  }, [isClearValue]);

  const disbaleYear = (option: any) => {
    if ((minYear && option < minYear) || (maxYear && option > maxYear)) {
      return true;
    } else {
      return false;
    }
  };

  const disabledMonth = (year: any, index: any) => {
    const selectedYear = year;
    if (
      (minMonth && selectedYear === minYear && index < minMonth) ||
      (maxMonth && selectedYear === maxYear && index > maxMonth)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      style={{ position: "relative", display: "inline-block", width: "100%" }}
    >
      <DatePicker
        showTimeSelect={showTimeSelect ? true : false}
        dateFormat={`${showTimeSelect ? "MM/dd/yyyy hh:mm aa" : "MM/dd/yyyy"}`}
        placeholderText={`${
          showTimeSelect ? "mm/dd/yyyy hh:mm AM/PM" : "mm/dd/yyyy"
        }`}
        isClearable={false}
        id={id}
        todayButton="Today"
        selected={isDisable && isMultiOrderScreen ? null : startDate}
        onChange={(date: Date | null) => onDateChange(date)}
        minDate={minDate}
        maxDate={maxDate || null}
        disabled={isDisable}
        popperPlacement="bottom-start" // More precise placement
        withPortal={isPortal && isTablet && !isMobile ? true : false}
        portalId={`${isPortal ? "root" : ""}`}
        customInput={
          <TextField
            className="w-100 datetext smallcal"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: showError ? errorColor : undefined,
                },
                "&:hover fieldset": {
                  borderColor: showError ? errorColor : undefined,
                },
                "&.Mui-focused fieldset": {
                  borderColor: showError ? errorColor : undefined,
                },
              },
              "& .MuiInputLabel-root": {
                color: showError ? errorColor : undefined,
              },
              "& .MuiInputBase-input": {
                color: showError ? errorColor : "inherit",
              },
            }}
            fullWidth
            label={label}
            id={id}
            name={name}
            helperText={helperText}
            size={size}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <img
                      src={calendarIcon}
                      alt="Calendar icon"
                      style={{ maxWidth: "100%" }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: showError && <ErrorInputAdornment />,
            }}
            error={showError}
          />
        }
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }: {
          date: Date;
          changeYear: (year: number) => void;
          changeMonth: (month: number) => void;
          decreaseMonth: () => void;
          increaseMonth: () => void;
          prevMonthButtonDisabled: boolean;
          nextMonthButtonDisabled: boolean;
        }) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex" }}>
              <button
                className="dateiconview"
                style={{ marginRight: "5px" }}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                type="button"
              >
                <ArrowLeftIcon />
              </button>

              <select
                className="yearview"
                style={{ marginRight: "2px" }}
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(Number(value))}
              >
                {years.map((option) => (
                  <option
                    key={option}
                    value={option}
                    disabled={disbaleYear(option)}
                  >
                    {option}
                  </option>
                ))}
              </select>

              <select
                className="yearview"
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option, index) => (
                  <option
                    key={option}
                    value={option}
                    disabled={disabledMonth(getYear(date), index)}
                  >
                    {option}
                  </option>
                ))}
              </select>

              <button
                className="dateiconview"
                style={{ marginLeft: "5px" }}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                type="button"
              >
                <ArrowRightIcon />
              </button>
            </div>
          </div>
        )}
        wrapperClassName="custom-datepicker"
      />
    </div>
  );
};

export default CustomDatePicker;
