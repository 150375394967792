import moment from "moment";

export const formatDate = (date: string): string => {
  // Add your date formatting logic here
  return moment(date).local().format("DD-MMM-yyyy");
};

export function formatDateToMonthDay(dateString: string) {
  const date = new Date(dateString);
  const options: any = { month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

export const formatTime = (dateString: string, timeZone: string): string => {
  const time = moment(dateString).format("h:mma");
  return `${time} ${timeZone}`;
};

export const formatOnlyTime = (
  dateString: string,
  timeZone: string
): string => {
  const time = moment(dateString).format("h:mm");
  return `${time} ${timeZone}`;
};

export const formatDateAndTime = (
  dateString: string,
  timeZone: string
): string => {
  const date = moment(dateString); // No timezone adjustment
  // Check if the date is valid
  if (!date.isValid()) {
    return ""; // Return blank if the date is invalid
  }
  return `${date.format("MMMM D")}, ${date.format("h:mma")} ${timeZone}`;
};
export const formatDateAndTimeAndPlusMinute = (
  dateString: string,
  timeZone: string,
  minutes: number
): string => {
  const date = moment(dateString); // No timezone adjustment
  // Check if the date is valid
  if (!date.isValid()) {
    return ""; // Return blank if the date is invalid
  }
  date.add(minutes, "minutes");
  return `${date.format("MMMM D")}, ${date.format("h:mma")} ${timeZone}`;
};

// export function calculateHoursDifference(
//   startTime: string,
//   endTime: string
// ): string {
//   const start = new Date(startTime);
//   const end = new Date(endTime);
//   const difference = end.getTime() - start.getTime(); // Difference in milliseconds
//   const hoursDifference = difference / (1000 * 60 * 60); // Convert to hours
//   // Round the result to 2 decimal places for format like "1.30 hr"
//   const roundedHours = (Math.round(hoursDifference * 100) / 100).toFixed(2);
//   return `${roundedHours} hr`;
// }

export function calculateHoursDifference(
  startTime: string,
  endTime: string
): string {
  const start = new Date(startTime);
  const end = new Date(endTime);
  const difference = end.getTime() - start.getTime(); // Difference in milliseconds

  const totalMinutes = difference / (1000 * 60); // Convert to minutes
  const hours = Math.floor(totalMinutes / 60); // Get whole hours
  const minutes = Math.floor(totalMinutes % 60); // Get remaining minutes

  // Format the result as "1hr 53 min"
  let formattedTime = "";
  if (hours > 0) {
    formattedTime += `${hours}hr `;
  }
  if (minutes > 0) {
    formattedTime += `${minutes} min`;
  } else if (hours === 0) {
    formattedTime = "0 min"; // If both hours and minutes are 0
  }

  return formattedTime.trim();
}

export const formatDateToLocal = (dateString: string): any => {
  if (dateString) {
    return moment(dateString).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  } else {
    return null;
  }
};

const addDays = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const formatDateToCustomString = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export const getDelDateFromAdrress = (selectedAddress: any): any => {
  if (
    selectedAddress.deliveryRequestTime &&
    selectedAddress.deliveryRequestDay &&
    selectedAddress.deliveryRequestTime != "1900-01-01T00:00:00"
  ) {
    const deliveryReadyTime = selectedAddress.deliveryRequestTime;
    const deliveryReadyDay = selectedAddress.deliveryRequestDay;
    const newDate = new Date();
    const [hours, minutes] = deliveryReadyTime
      .split("T")[1]
      .split(":")
      .map(Number);
    newDate.setHours(hours, minutes, 0, 0);
    const finalDate = addDays(newDate, deliveryReadyDay);
    const formattedFinalDate = formatDateToCustomString(finalDate);

    return formattedFinalDate;
  } else {
    return selectedAddress.deliveryRequestTime;
  }
};
